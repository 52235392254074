import {range} from "lodash"
import moment from "moment"
// let years = []
let years = range(1960, moment().year(), 1).reverse()

export default years.map(year => {
    return {
        key:year,
        value:year,
        text:year
    }
})
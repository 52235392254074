// Library imports
import * as React from "react"
import {Card, Divider, Form, DropdownProps} from "semantic-ui-react"
import {debounce} from "lodash"

// Local imports
import {ApplySubFormProps, error_message_types} from "."
import {FieldLabel, BrandedLink} from "../../components"
import { Query, ApolloConsumer } from "react-apollo";
import ApolloClient from "apollo-client";
import { NormalizedCache } from "apollo-cache-inmemory";

// GraphQL
import {User, education, APPLICATION_ERRORS} from "../../GraphQL/types"
import { getSchoolList } from "../../GraphQL/queries";
import {actions} from "../../GraphQL/local_writes"

// For dropdowns
const degree_options = [
    "Associate of Arts (A.A.)",
    "Associate of Science (A.S.)",
    "Associate of Applied Science (AAS)",
    "Bachelor of Arts (B.A.)",
    "Bachelor of Science (B.S.)",
    "Bachelor of Fine Arts (BFA)",
    "Bachelor of Applied Science (BAS)",
    "Master of Arts (M.A.)",
    "Master of Science (M.S.)",
    "Master of Business Administration (MBA)",
    "Master of Fine Arts (MFA)",
    "Doctor of Philosophy (Ph.D.)",
    "Juris Doctor (J.D.)",
    "Doctor of Medicine (M.D.)",
    "Doctor of Dental Surgery (DDS)",
]

const degree_dropdown_options = degree_options.map(option => {
    return {
        key:option,
        value:option,
        text:option
    }
})

const degree_status_options = [
    {
        key:"Completed",
        value:"Completed",
        text:"Completed"
    },
    {
        key:"Not Completed",
        value:"Not Completed",
        text:"Not Completed"
    },
    {
        key:"In Progress",
        value:"In Progress",
        text:"In Progress"
    }
]

interface EducationInputProps extends ApplySubFormProps {
    user:User
}


class EducationCard extends React.Component<EducationInputProps> {

    public render() {

        let {
            user,
            addEducation,
            removeEducation
        } = this.props

        return (
            <Card fluid className="education-input">
                <div className="form-card">
                    <p className="bold">EDUCATION</p>
                    <Divider/>

                    <ApolloConsumer>
                        {
                            (client) => {
                                return (
                                    <div>
                                        {
                                            user.education.map((education, index) => {
                                                return (
                                                    <div key={index} className="list-input-item">
                                                        <EducationItem client={client} education={education} index={index} {...this.props}/>
                                                    </div>
                                                    
                                                )
                                            })
                                        }
                                    </div>
                                )
                                
                            }
                        }
                    </ApolloConsumer>
                    
                            
                    

                    <BrandedLink {...this.props} content="ADD A DEGREE" onClick={addEducation}/>
                    
                </div>
            </Card>
        )
    }
}


interface EducationItemProps extends EducationInputProps {
    education:education,
    index:number,
    client:ApolloClient<NormalizedCache>
}

class EducationItem extends React.Component<EducationItemProps> {

    state = {
        universityOptions:[],
        education:this.props.education
    }

    queryUniversityList = (index:number, client:ApolloClient<NormalizedCache>) => async (e:React.SyntheticEvent<HTMLElement>, data:DropdownProps) => {
        
        let query = data.searchQuery
        
        let result = await client.query({
            query:getSchoolList,
            variables:{
                query:query
            }
        }) as any

        let schools = result.data.getSchoolList.map((school:any, s_index:number) => {
            return {
                key:s_index,
                value:school.name,
                text:school.name
            }
        })

        this.setState({universityOptions:schools})
        // this.props.setEducation("university",index)(null,{value:query})
        this.setEducation("university",index)(null,{value:query})
    }

    // Update education fields
    setEducation = (field:string, index:number) => (e:any, data?:any) => {
       
        let existing_education = this.state.education

        switch (field) {
            case "university":
                existing_education.university = data.value
                break
            case "degree":
                existing_education.degree = data.value
                break
            case "degree_status":
                existing_education.degree_status = data.value
                break
            case "major":
                existing_education.major = data.value
                break
            case "country":
                existing_education.country = data.value
                break
        }

        // educations.splice(index, 1, existing_education)

       
        this.setState({education:existing_education})
        this.setEducationDebounce(existing_education, index)

    }

    setEducationDebounce = debounce((education:any, index:number) => {
        let existing_educations = this.props.user.education
        existing_educations.splice(index, 1 , education)
        let new_user = {
            ...this.props.user,
            education:existing_educations
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, new_user)
    }, 1000)

    public render() {

        let {
            education,
            index,
            // setEducation,
            removeEducation,
            client,
            error_messages
        } = this.props

        let education_errors = error_messages.filter(error => {
            return error.type === error_message_types.EDUCATION && error.index === index
        })

        let university_error = false
        let degree_error = false
        let degreestatus_error = false
        let major_error = false
        let country_error = false

        education_errors.map(error => {
            switch (error.code) {
                case APPLICATION_ERRORS.NO_UNIVERSITY:
                    university_error = true
                    break
                case APPLICATION_ERRORS.NO_DEGREE:
                    degree_error = true
                    break
                case APPLICATION_ERRORS.NO_DEGREESTATUS:
                    degreestatus_error = true
                    break
                case APPLICATION_ERRORS.NO_MAJOR:
                    major_error = true
                    break
                case APPLICATION_ERRORS.NO_COUNTRY:
                    country_error = true
                    break
            }
        })

        return (

            <div>
                <Form.Dropdown label={<FieldLabel content="UNIVERSITY" {...this.props}/>}
                    fluid
                    selection
                    search
                    onSearchChange={this.queryUniversityList(index,client)}
                    onChange={this.setEducation("university", index)}
                    options={this.state.universityOptions}
                    value={education.university}
                    text={education.university}
                    selectOnBlur={false}
                    error={university_error}
                />
                <Form.Group widths="equal">
                    <Form.Dropdown label={<FieldLabel content="DEGREE" {...this.props}/>}
                        fluid
                        selection
                        onChange={this.setEducation("degree", index)}
                        options={degree_dropdown_options}
                        text={education.degree}
                        selectOnBlur={false}
                        error={degree_error}
                        allowAdditions
                        additionPosition="bottom"
                        search
                    />
                    <Form.Dropdown label={<FieldLabel content="DEGREE STATUS" {...this.props}/>}
                        fluid
                        selection
                        onChange={this.setEducation("degree_status", index)}
                        options={degree_status_options}
                        value={education.degree_status}
                        text={education.degree_status}
                        selectOnBlur={false}
                        error={degreestatus_error}
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Input label={<FieldLabel content="MAJOR" {...this.props}/>}
                        fluid
                        onChange={this.setEducation("major",index)}
                        value={education.major}
                        error={major_error}
                    />
                    <Form.Dropdown label={<FieldLabel content="COUNTRY" {...this.props}/>}
                        fluid
                        selection
                        search
                        onChange={this.setEducation("country", index)}
                        options={this.props.CountryOptions}
                        value={education.country}
                        text={education.country}
                        selectOnBlur={false}
                        error={country_error}
                        autoComplete="off"
                    />
                </Form.Group>
                <BrandedLink onClick={removeEducation(index)} content="REMOVE THIS DEGREE" {...this.props}/>
                
            </div>
            
        )
    }
}

export default EducationCard
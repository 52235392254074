/**
 * Wrapper file for Google analytics objects
 * 
 * 
 */

 // library imports
import ReactGA from "react-ga"

/**
 * Exported object containing all the event objects
 */
export const AnalyticsItems = {
    REGISTER:{
        category:"Conversion Event",
        action:"Register",
        label:"Register on client2"
    } as ReactGA.EventArgs,
    APPLY: {
        category:"Conversion Event",
        action:"Apply",
        label:"Application submitted"
    } as ReactGA.EventArgs,
    APPLY_PAGEVIEW: {
        category:"Conversion Event",
        action:"Apply Page Viewed",
        label:"Apply Page Viewed"
    } as ReactGA.EventArgs
}

export default AnalyticsItems
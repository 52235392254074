// library imports
import * as React from 'react'

// local imports
import { WithPageState } from '..';

interface BrandedDividerProps extends WithPageState {
    spaced?:boolean // Whether to add margin on the top and bottom
}

class BrandedDivider extends React.Component<BrandedDividerProps> {
    public render() {

        let divider_style = {
            width:'100%',
            maxWidth:'460px',
            borderBottom:`1px solid ${this.props.PageState.recruiter.brand.color}`
        } as React.CSSProperties

        // Have to explicitly say that it is NOT spaced
        if (this.props.spaced !== false) {
            divider_style.margin = '20px 0'
        }

        return (
            <div className="branded-divider" style={divider_style}/>
        )
    }
}

export default BrandedDivider
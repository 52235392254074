import * as React from 'react'
import TablesLogo from "../../media/img/tables_logo.png"
import './style.scss'
import {Image} from "semantic-ui-react"
// NOT USING CURRENTLY 
interface FooterProps {
    className?:string
}

class Footer extends React.Component<FooterProps> {
    public render() {

        return (
            <div className="ui app-footer footer">
    
                {/* <div className="footer-text">
                    <p>Powered by </p>
                    <a href="https://tables.work" target="_blank">
                        <Image size="small" src={TablesLogo} alt="Tables logo"/>
                    </a>
                </div> */}
            </div>
        )
    }
}

export default Footer
import * as React from 'react'
import {Container, Form, Breadcrumb, Radio, Header, DropdownProps, Modal, Message, Button} from "semantic-ui-react"
import {NavLink, Switch, Route} from "react-router-dom"
import moment from "moment"
import {Redirect} from "react-router-dom"
// @ts-ignore
import {Fade} from "react-reveal"
import {debounce,omit} from "lodash"
// @ts-ignore
import ordinal from "ordinal-js"
import ReactGA from "react-ga"
import { Query,MutationFn,Mutation } from 'react-apollo'
import {ApolloClient,ApolloError } from "apollo-client"

// Local imports
import { WithRouterProps } from '../../interfaces';
// import {AnalyticsItems} from ""
import {AuthModal, FieldLabel, BrandedHeader, BrandedDivider, BrandedText, BrandedButton, FullPageLoader, CountryOptions, FullPageError,SuccessAnimation } from '../../components'
import TableInput from "./TableInput"
import UserInput from "./Personal_Input"
import EducationInput from "./EducationInput"
import WorkInput from "./WorkInput"
import SkillsInput from "./SkillsInput"
import UploadInput from "./UploadInput"
import ApplyReview from "./Review"
import "./style.scss"
import AnalyticsItems from '../../analytics';
import SuccessPage from './Success'
import ProgressBar from './ProgressBar'

import ADAInput from './AdaInput'


// GraphQL
import {singleUpload,updateUser,applyToTables} from "../../GraphQL/mutations"
import {applicationQuery, tableQuery} from "../../GraphQL/queries"
import {PageState, User, Table, Seat, education, local_application, APPLICATION_ERRORS,_UserType} from "../../GraphQL/types"
import { actions } from '../../GraphQL/local_writes';
import { NormalizedCache } from 'apollo-cache-inmemory';
import { formatPath, formatUrl } from '../../utils';


export interface ApplyProps extends WithRouterProps {
    PageState:PageState
}

export interface ApplyPageProps extends ApplyProps {
    client:ApolloClient<NormalizedCache>,
    table_id:string,
    application: local_application,
    CountryOptions:any,
    seat_id:string,

}

export interface ApplyReviewProps extends ApplyPageProps {
    user:User,
    setPersonal:(field:string) => (e:any) => void,
  
}

export interface ApplyFormProps extends ApplyPageProps {
    application:local_application,
    user:User,
    handleTableSelect: (e:React.SyntheticEvent, data:DropdownProps) => void,
    handleSeatSelect: (e:React.SyntheticEvent, data:DropdownProps) => void,
    setPersonal:(field:string) => (e:any) => void,
    addEducation: () => void,
    // setEducation: (field:string, index:number) => (e:any, data?:any) => void,
    removeEducation: (index:number) => () => void,
    addWork:() => void,
    // setWork: (field:string, index:number) => (e:any, data?:any) => void,
    removeWork:(index:number) => void,
    setUpload: (field:string, index?:number) => (data:any) => void,
}

export interface ApplySubFormProps extends ApplyFormProps {
    error_messages:error_message[]
}

// class TableQuery extends React.Component<ApplyProps> {
//     public render() {
//         let params = this.props.match.params as any
//         let recruiter_name = params.recruiter_name
//         let table_id = params.table_id
//         return (
//             <Query query={tableQuery}
//                 variables={{table_id:table_id}}
//                 notifyOnNetworkStatusChange={false}
//             >
//                 {
//                     ({error, loading, data}) => {

//                         if (loading) return <FullPageLoader/>
//                         if (error) return <FullPageError {...this.props}/>
                        
//                         if (!this.props.PageState.user) {
//                             return <FullPageError {...this.props}/>
//                         }

//                         // Check if there are no tables returned
//                         if (data.findPublishedTablesByEntity.length === 0) {
//                             return <FullPageError {...this.props}/>
//                         }

//                         // Check whether user has already applied
//                         let table = data.findPublishedTablesByEntity[0]
                        
//                         let has_applied = table.applicants.find((application:any) => {
//                             let user = this.props.PageState.user as any
//                             return application.user_id.id === user.id
//                         })
//                         if (has_applied) {
                            
//                             return <Redirect to={formatUrl(this.props.is_subdomain, recruiter_name, table_id)}/>
//                         }
//                         return (
//                             <ApplyQuery {...this.props}/>
//                         )
//                     }
//                 }
//             </Query>
//         )
//     }
// }

class ApplyQuery extends React.Component<ApplyProps> {
    
    state = {
        authenticated:true
    }

    componentDidMount() {

        // console.log("loadede")
        // let user = this.props.PageState.user
        // if (!user || user.id === "") {
        //     this.setState({authenticated:false})
        // }

        // ReactGA.event()
        ReactGA.event(AnalyticsItems.APPLY_PAGEVIEW)
    }
    
    public render() {
        let params = this.props.match.params as any
        console.log("Logging the Params",params);
        let recruiter_name = params.recruiter_name
        let table_id = params.table_id
        let seat_id  = params.seat_id;


        // if (!this.state.authenticated) {
        //     return <Redirect to={formatUrl(this.props.is_subdomain, recruiter_name)}/>
        // }
        return (
            <Query query={applicationQuery}
                variables={{
                    table_id:table_id
                }}
            >
                {
                    ({error,loading,data,client}) => {
                        if (loading) return <FullPageLoader/>
                        if (error) return <FullPageError {...this.props}/>
                        
                        return (
                            <ApplyPage application={data.application} 
                                CountryOptions={CountryOptions} 
                                table_id={table_id} 
                                seat_id= {seat_id}
                                client={client}
                                {...this.props}

                            />
                        )
                    }
                }
            </Query>
        )
    }
}


// Switch page between review and form
class ApplyPage extends React.Component<ApplyPageProps> {
    state = {
        user:this.props.PageState.user,
        application:this.props.application
    }

    // Add currently selected table to form
    componentDidMount() {
        let user = this.props.PageState.user
        console.log("existing User",user);
        if (this.props.application.selected_table !== "" || !user) {
            return
        }

        let selected_seat = ""

        let url_path_params = this.props.match.params as any
        let table_id = url_path_params.table_id
        let seat_id = url_path_params.seat_id
        
        // this.props.PageState.recruiter.tables.map(table => {
        //     if (table.id === table_id && table.publish_seats.length === 1) {
        //         selected_seat = table.publish_seats[0].id
        //     }
        // })

        // url params overrule
        // if (seat_id) {
        //     selected_seat = seat_id            
        // }

        let new_application = {
            ...this.props.application,
            selected_table: table_id,
            selected_seat: seat_id,
            stage:0,
            finished:false,
            disability:""
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application)

        if (user.education.length === 0) {
            this.addEducation()
        }

        if (user.experience.length === 0) {
            this.addWork()
        }

    }

    // Select table
    handleTableSelect = (e:React.SyntheticEvent, data:DropdownProps) => {
        
        let new_application = {
            ...this.props.application,
            selected_table:data.value
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application)
        
    }

    // Select seat
    handleSeatSelect = (e:React.SyntheticEvent, data:DropdownProps) => {
        
        let new_application = {
            ...this.props.application,
            selected_seat:data.value
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application)
        
    }

    // Update personal fields
    setPersonal = (field:string) => (value:any) => {
        
        let user = this.state.user as User
        switch(field) {
            case "fname":
                user.fname = value
                break
            case "lname":
                user.lname = value
                break
            case "email":
                user.email = value
                break
            case "phone":
                user.phone = value
                break
            case "website":
                user.website = value
                break
            case "address":
                user.personal_address = value
                break
            case "country":
                user.country = value
                break
            case "image":
                value.y_axis = 0
                user.profile_pic = value
                break
            
        }

        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, user)
        // this.setState({user:user})
    }

    // Add an education object
    addEducation = () => {
        if (!this.state.user) {
            return
        }

        let new_educations = this.state.user.education
        new_educations.push({
            university:"",
            degree:"",
            degree_status:"",
            major:"",
            country:"",
            __typename:"education"
        })

        let new_user = {
            ...this.state.user,
            education:new_educations
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, new_user)

    }

    // Remove and education object
    removeEducation = (index:number) => () => {
        if (!this.state.user) {
            return
        }
        let educations = this.state.user.education
        educations.splice(index,1)
        let new_user = {
            ...this.state.user,
            education:educations
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, new_user)
    }

    // Create a work object
    addWork = () => {
        if (!this.state.user) {
            return
        }
        let works = this.state.user.experience
        works.push({
            company:{
                key:"",
                value:"",
                text:"",
                image:"",
                __typename:"logoClearBit"
            },
            start_date:"",
            end_date:"",
            role:"",
            current:false,
            location:"",
            __typename:"experience"
        })
        let new_user = {
            ...this.state.user,
            experience:works
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, new_user)
    }

    

    removeWork = (index:number) => () => {
        if (!this.state.user) {
            return
        }
        let works = this.state.user.experience
        works.splice(index,1)
        let new_user = {
            ...this.state.user,
            experience:works
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, new_user)
    }

    setUpload = (field:string, index?:number) => (data:any) => {
        let application = this.props.application
        
        let has_coverletter = application.attachments.find(file => {
            return file.type === "cover_letter"
        })

        let has_resume = application.attachments.find(file => {
            return file.type === "resume"
        })

        switch (field) {
            // Handle RTF Input
            case "cover_letter_input":
                application.cover_letter_input = data
                
                break

            // Handle Cover letter upload
            case "cover_letter":
                
                if (has_coverletter) {
                    application.attachments.map(file => {
                        return file.type === "cover_letter" ? data : file
                    })
                } else {
                    application.attachments.push(data)
                }
                break

            // Handle Resume upload
            case "resume":
                if (has_resume) {
                    application.attachments.map(file => {
                        return file.type === "resume" ? data : file
                    })
                } else {
                    application.attachments.push(data)
                }
                break
            
            // Handle other attachments
            case "other":
                
                application.attachments.push(data)
                break

            // Handle removing attachments
            case "remove":
                application.attachments.splice(index as number,1)
                
        }
        

        // this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, application)
        this.setState({application:application})
    }

    public render() {
        let {
            match
        } = this.props

        // let seats = this.props.PageState.recruiter.tables.map(table => {
        //     return table.publish_seats.map(seat => {
        //         return 1
        //     })
        // }) as any[]

      //  let no_seats = [].concat(...seats).length === 0

        return (
            <div className="page-content apply-page">
                <Container>

                    {/* <NoSeats {...this.props} no_seats={no_seats}/> */}

                    <Switch >
                        <Route path={formatPath(this.props.is_subdomain, true,true, true, "review")} 
                            render={(props) => <ApplyReview {...this.props} 
                            {...props}
                            {...this.state}
                            user={this.state.user as User}
                            setPersonal={this.setPersonal}
                            />}
                        />
                        <Route path={formatPath(this.props.is_subdomain, true, true, true, "success")} render={(props) => <SuccessPage {...this.props}/>}/>
                        <Route path={formatPath(this.props.is_subdomain, true, true, true)} 
                            render={(props) => <ApplyForm // Table
                            handleTableSelect={this.handleTableSelect}
                            handleSeatSelect={this.handleSeatSelect}
                            setPersonal={this.setPersonal}
                            // Education
                            addEducation={this.addEducation}
                            // setEducation={this.setEducation}
                            removeEducation={this.removeEducation}

                            // Work
                            addWork={this.addWork}
                            // setWork={this.setWork}
                            removeWork={this.removeWork}
                            
                            // Files
                            setUpload={this.setUpload}

                            // State
                            {...this.state}
                            {...this.props}
                            {...props}
                            user={this.state.user as User} 

                            />}
                        />
                    </Switch>
                </Container>
            </div>
        )
    }
}

interface NoSeatsProps extends ApplyProps {
    no_seats:boolean,
}

class NoSeats extends React.Component<NoSeatsProps> {
    public render() {
        let url_path_params = this.props.match.params as any
        let recruiter_name = url_path_params.recruiter_name
        let table_id = url_path_params.table_id
        let bg_color = this.props.PageState.recruiter.brand.color
        return (
            <Modal className="modal-small"
                open={this.props.no_seats}
            >
                <Modal.Header style={{backgroundColor:bg_color}} >
                    <p className="bold center-content" style={{color:"white"}}>
                        SORRY
                    </p>
                </Modal.Header>
                <Modal.Content>
                    <div style={{textAlign:"center"}}>
                        <BrandedHeader as="h4" content="There's no seats available right now. Please check back later!" {...this.props}/>
                        <div className="center-content">
                            <NavLink to={formatUrl(this.props.is_subdomain, recruiter_name, table_id)}>
                                <BrandedText content="Go Back" colored {...this.props}/>
                            </NavLink>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}


export enum error_message_types {
    TABLE = "TABLE",
    PERSONAL = "PERSONAL",
    EDUCATION = "EDUCATION",
    EXPERIENCE = "EXPERIENCE",
    SKILLS = "SKILLS",
    UPLOAD = "UPLOAD"
}

interface error_message {
    message:string,
    code:APPLICATION_ERRORS,
    type: error_message_types,
    index?:number
}


class ApplyForm extends React.Component<ApplyFormProps> {

    state = {
        error_messages:[] as error_message[],
        auth_modal_open:false,
        error:false
    }

    // Catch error inputs
    /**
     * @remarks
     * Codes:
     * 0 - No error
     * 1 - Table not selected
     * 2 - Seat not selected
     * 3 - User fname not filled
     * 4 - User lname not filled
     * 5 - User phone not filled
     * 6 - User country not filled
     * 7 - User address not filled
     * 8 - Education university not filled
     * 9 - Education degree not filled
     * 10 - Education degree status not filled
     * 11 - Education major not filled
     * 12 - Education country not filled
     * 13 - Experience company name not filled 
     * 14 - Experience role not filled
     * 15 - Experience start date not filled
     * 16 - Experience end date and current not filled
     * 17 - Experience country not filled
     * 18 - Upload resume not filled
     * 
     * Error_message_struct = {
     *  message,
     *  type,
     *  index?
     * }
     * 
     * @returns True if error
     */

     catchPeronalInformationErrors = () =>{
        let error_messages = []
        let user = this.props.PageState.user
        let application = this.props.application

        let resume = application.attachments.find(file => {
            return file.type === "resume"
        })

        // let cover_letter = this.props.application.attachments.find(file => {
        //     return file.type === "cover_letter"
        // })

        if (!user) {
            return
        }

        if (application.selected_table === "") {
            error_messages.push({
                message:"Please select a table to apply to",
                type:error_message_types.TABLE,
                code:APPLICATION_ERRORS.NO_TABLE
            })
        }

        if (this.props.application.selected_seat === "") {
            // this.setError(1, "Please select a seat to apply to")   
            error_messages.push({
                message:"Please select a seat to apply to",
                type:error_message_types.TABLE,
                code:APPLICATION_ERRORS.NO_SEAT
            })
        }

        if (user.fname === "") {
            error_messages.push({
                message:"Please input your first name",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_FNAME
            })
        }

        if (user.lname === "") {
            error_messages.push({
                message:"Please input your last name",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_LNAME
            })
        }

        if (user.phone === "") {
            error_messages.push({
                message:"Please input your phone number",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_PHONE
            })
        }

        if (user.country === "") {
            // this.setError(5, "Please input your country of residence")
            error_messages.push({
                message:"Please input your country of residence",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_COUNTRY
            })
        }

        if (user.personal_address === "") {
            error_messages.push({
                message:"Please select your address",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_ADDRESS
            })
        }


        this.setState({error_messages:error_messages})

        return error_messages.length > 0

     }   




    catchErrors = () => {
        let error_messages = []
        let user = this.props.PageState.user
        let application = this.props.application

        let resume = application.attachments.find(file => {
            return file.type === "resume"
        })

        // let cover_letter = this.props.application.attachments.find(file => {
        //     return file.type === "cover_letter"
        // })

        if (!user) {
            return
        }

        if (application.selected_table === "") {
            error_messages.push({
                message:"Please select a table to apply to",
                type:error_message_types.TABLE,
                code:APPLICATION_ERRORS.NO_TABLE
            })
        }

        if (this.props.application.selected_seat === "") {
            // this.setError(1, "Please select a seat to apply to")   
            error_messages.push({
                message:"Please select a seat to apply to",
                type:error_message_types.TABLE,
                code:APPLICATION_ERRORS.NO_SEAT
            })
        }

        if (user.fname === "") {
            error_messages.push({
                message:"Please input your first name",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_FNAME
            })
        }

        if (user.lname === "") {
            error_messages.push({
                message:"Please input your last name",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_LNAME
            })
        }

        if (user.phone === "") {
            error_messages.push({
                message:"Please input your phone number",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_PHONE
            })
        }

        if (user.country === "") {
            // this.setError(5, "Please input your country of residence")
            error_messages.push({
                message:"Please input your country of residence",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_COUNTRY
            })
        }

        if (user.personal_address === "") {
            error_messages.push({
                message:"Please select your address",
                type:error_message_types.PERSONAL,
                code:APPLICATION_ERRORS.NO_ADDRESS
            })
        }

        error_messages = error_messages.concat(this.checkEducation(user))

        error_messages = error_messages.concat(this.checkExperience(user))

        if (!resume) {
            error_messages.push({
                message:"Please upload a resume",
                type:error_message_types.UPLOAD,
                code:APPLICATION_ERRORS.NO_RESUME
            })
        }

        this.setState({error_messages:error_messages})

        return error_messages.length > 0

    }

    // Returns true if there is an error
    checkEducation = (user:User):error_message[] => {


        // Check education items for errors
        let results = user.education.map((education, index) => {

            let errors = []
            if (education.university === "") {
                errors.push({
                    message:"Please input a university",
                    type:error_message_types.EDUCATION,
                    index:index,
                    code:APPLICATION_ERRORS.NO_UNIVERSITY
                }) 
            }

            if (education.degree === "") {
                errors.push({
                    message:"Please select a degree",
                    type:error_message_types.EDUCATION,
                    index:index,
                    code:APPLICATION_ERRORS.NO_DEGREE
                })
            }

            if (education.degree_status === "") {
                errors.push({
                    message:"Please select a degree status",
                    type:error_message_types.EDUCATION,
                    index:index,
                    code:APPLICATION_ERRORS.NO_DEGREESTATUS
                })
            }

            if (education.major === "") {
                errors.push({
                    message:"Please enter a major",
                    type:error_message_types.EDUCATION,
                    index:index,
                    code:APPLICATION_ERRORS.NO_MAJOR
                })
            }

            if (education.country === "") {
                errors.push({
                    message:"Please enter a country",
                    type:error_message_types.EDUCATION,
                    index:index,
                    code:APPLICATION_ERRORS.NO_COUNTRY
                })
            }

            return errors

        }) as any

        results = [].concat(...results)

        return results
    }

    // Returns true if there is an error
    checkExperience = (user:User):any => {

        // Check experience items for errors
        let results = user.experience.map((experience, index) => {
            let errors = []
            if (experience.company.text === "") {
                errors.push({
                    message:"Please select a company",
                    type:error_message_types.EXPERIENCE,
                    index:index,
                    code:APPLICATION_ERRORS.NO_COMPANY
                })
            }

            if (experience.role === "") {
                errors.push({
                    message:"Please select a role",
                    type:error_message_types.EXPERIENCE,
                    index:index,
                    code:APPLICATION_ERRORS.NO_ROLE
                })
            }

            if (experience.start_date === "") {
                errors.push({
                    message:"Please select a start date for this item",
                    type:error_message_types.EXPERIENCE,
                    index:index,
                    code:APPLICATION_ERRORS.NO_STARTDATE,
                })
            }

            if (experience.end_date === "" && experience.current === false) {
                errors.push({
                    message:"Please select an end date or set this item as your current position",
                    type:error_message_types.EXPERIENCE,
                    index:index,
                    code:APPLICATION_ERRORS.NO_ENDDATE
                })
            }

            if (experience.location === "") {
                errors.push({
                    message:"Please select a country for this item",
                    type:error_message_types.EXPERIENCE,
                    index:index,
                    code:APPLICATION_ERRORS.NO_COUNTRY
                })
            }

            return errors
        }) as any

        results = [].concat(...results)

        return results
    }


    toggleAuthModalOpen = () => {
        this.setState({auth_modal_open:!this.state.auth_modal_open})
    }

    handleClickHomePage = () => {
        window.open(this.props.PageState.recruiter.url, "_blank")
    }

    handleClickAuth = () => {
        let user = this.props.PageState.user as any
        let authenticated = user.id !== ""
        authenticated ? this.handleSignout() : this.toggleAuthModalOpen()
    }

    handleSignout = async () => {
        
        localStorage.clear()
        await this.props.client.clearStore()
        await this.props.client.resetStore()
        window.location.reload()
    }


    handleSubmitPageOne = async () => {
        console.log("got called page One")
        let error = this.catchPeronalInformationErrors();
        if(this.props.application.stage == 0){
            let new_application = {
                ...this.props.application,
                stage:1
            } 
            console.log(new_application);
           // await this.updateUserMutation(updateUser);
            this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application);
        }else if(this.props.application.stage == 1){
            let new_application = {
                ...this.props.application,
                stage:2
            }    
            console.log(new_application);
           // await this.updateUserMutation(updateUser);
            this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application);
        }else if(this.props.application.stage == 2){

            console.log("here called 2");

            let new_application = {
                ...this.props.application,
                finished:true
            }    
            console.log(new_application);
           // await this.updateUserMutation(updateUser);
            this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application);


        }

      
    }


    handleApplicationError = (error:ApolloError) => {
        console.log("application error:",error)
        this.setState({error:true})
    }

    handleBack = async () => {
       // let error = this.catchPeronalInformationErrors();
       if(this.props.application.stage == 1){
        let new_application = {
            ...this.props.application,
            stage:0
        }
        console.log("update Stage to 0")
        this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application);
        }else{
        let new_application = {
            ...this.props.application,
            stage:1
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application);
        }
    }


    submitApp = async (mutationFn:MutationFn) => {

        // let error = this.catchErrors()
        // if (error) {
        //     return
        // }
        console.log("got called")

        let user = this.props.user as User

        let recruiter = this.props.PageState.recruiter

        let url = `https://www.tables.work/${recruiter.route_url}`

        let invalid_keys = ["education","experience","id","email_ok"]
        let user_inputs = Object.keys(user).filter(key => {
            return !invalid_keys.includes(key)
        })

        // let seat_mappings = FetchSeatMappings(recruiter, this.props.application.selected_seats, this.props.application.selected_tables)

        let seat_mappings = [{
            table_id:this.props.application.selected_table,
            seat_id:this.props.application.selected_seat
        }]

        // <startbullshit>
        // some bullshit for graphql to not barf, seems super excessive and unecessary
        // really dont like this, but not sure how to make it otherwise
        let user_object = JSON.parse(JSON.stringify(user))
        user_object.type = _UserType.WORKER
        delete user_object.id
        user_object.education = user_object.education.map((education:any) => {
            delete education.__typename
            return education
        })

        user_object.experience = user_object.experience.map((experience:any) => {
            delete experience.company.__typename
            delete experience.__typename
            return experience
        })

        //delete user_object.profile_pic.__typename
        delete user_object.__typename

        let attachments = this.props.application.attachments.map((attachment:any) => {
            delete attachment.__typename
            return attachment
        })

        // </endbullshit>

        user_object["disability"] = this.props.application.disability;    

        let variables = {
            // applyToTables
            submission_date:moment().toISOString(),
            user_id:this.props.user.id.toString(),
            seats_id:seat_mappings,
            recruiter_id:this.props.PageState.recruiter.id,
            why:this.props.application.cover_letter_input,
            attachments:attachments,
            archived:false,
            url:url,
            userObject:user_object

        }
        mutationFn({
            variables: variables
        })
    }

    handleRedirect = () => {
        let url_params = this.props.match.params as any
        let recruiter_name = url_params.recruiter_name
        let table_id = url_params.table_id
        let seat_id = url_params.seat_id
        this.props.PageState.refetchRecruiter()
        let new_url = formatUrl(this.props.is_subdomain, recruiter_name)
        this.props.history.push(new_url)

        let new_application = {
            ...this.props.application,
            selected_table: table_id,
            selected_seat: seat_id,
            stage:0,
            finished:false,
            disability:""
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application)
    }



    // update_User_Mutation
    updateUserMutation = async (mutationFn:MutationFn) => {
        console.log("!!! --got called-- !!!");
        // let error = this.catchPeronalInformationErrors();
        // if (error) {
        //     return
        // }
        let user = this.props.user as User; // user
        let recruiter = this.props.PageState.recruiter; //recruiter
        let url = `https://www.tables.work/${recruiter.route_url}`;
        let invalid_keys = ["education","experience","id","email_ok"];
        let user_inputs = Object.keys(user).filter(key => {
            return !invalid_keys.includes(key)
        })
        // let seat_mappings = FetchSeatMappings(recruiter, this.props.application.selected_seats, this.props.application.selected_tables)
        let seat_mappings = [{
            table_id:this.props.application.selected_table,
            seat_id:this.props.application.selected_seat
        }];
        let user_object = JSON.parse(JSON.stringify(user));
        user_object.type = _UserType.WORKER;
        delete user_object.id
        user_object.education = user_object.education.map((education:any) => {
            delete education.__typename
            return education
        })
        user_object.experience = user_object.experience.map((experience:any) => {
            delete experience.company.__typename
            delete experience.__typename
            return experience
        })
        //delete user_object.profile_pic.__typename
        delete user_object.__typename
        //  let attachments = this.props.application.attachments.map((attachment:any) => {
        //      delete attachment.__typename
        //      return attachment
        //  })
        // Attachments
        // let attachments = this.props.application.attachments.map((attachment:any) => {
        //     user_object["attachments"] = [attachment; // --user_object-- \\ 
        // });
        let attachments = JSON.parse(JSON.stringify(this.props.application.attachments)); // 
        user_object["attachments"]  = attachments ; // attachments
        const _user_object = user_object;
        // const data_user_object = omit(_user_object.attachments, ['__typename']);
        //    if(_user_object.attachments.length > 0){
        //     delete _user_object.attachments[0].__typename
        //    }
        
        //_user_object
        _user_object.attachments.map((attachment:any) => {
             delete attachment.__typename
              return attachment
          });
          
        console.log("userObject",  _user_object) ;     
        let variables = {
            // applyToTables
            user_id:this.props.user.id.toString(),
            userObject: _user_object
        }
        console.log(user_object); // !!!-user_object-!!! \\
        mutationFn({
            variables: variables
        })
    }

    handleSubmit = () => {
        let error = this.catchErrors()
        if (!error) {

            let url_path_params = this.props.match.params as any
            let recruiter_name = url_path_params.recruiter_name
            let table_id = url_path_params.table_id
            let seat_id = url_path_params.seat_id   
            this.props.history.push(formatUrl(this.props.is_subdomain, recruiter_name, table_id, seat_id, true, "review"))
        }
    }

    public render() {
        
        let {
            PageState,
            application
        } = this.props

        let user = PageState.user as any
        let recruiter_name = PageState.recruiter.route_url;
        let pageStage = application.stage;
        let finished  =  application.finished;
        console.log("finished",finished)
        console.log("pageStage",application);

        if (!user) {
            return <Redirect to={formatUrl(this.props.is_subdomain, PageState.recruiter.route_url)}/>
        }

        let table_options = PageState.recruiter.tables ? PageState.recruiter.tables.map(table => {
            return {
                key:table.id,
                value:table.id,
                text:table.name
            }
        }) : []

        let seat_options:any[] = []

        // let selected_tables = PageState.recruiter.tables.filter(table => {
        //     return this.props.application.selected_table === table.id
        // })
        // let table_seats = selected_tables.map(table => {
        //     return table.publish_seats.map(seat => {
        //         return {
        //             key:seat.id,
        //             value:seat.id,
        //             text:`${seat.title} (${table.name})`
        //         }
        //     })
        // }) as any[]
        // seat_options = [].concat(...table_seats)

        
        let fn  = updateUser; // can define as any
        //page
        switch(pageStage){
            case 0:
            fn =updateUser
            break;
            case 1:
            fn =updateUser
            break;
            case 2:
            fn  = applyToTables
            break;
        }

        return(
            <Mutation mutation={fn}
            onCompleted = {this.handleSubmitPageOne}
            >
         {
              (fn, {loading}) => {
                if (loading) return <FullPageLoader/>
        return (
            <div>
                <div className="breadcrumb">
                        <Breadcrumb>
                            
                            <Breadcrumb.Section>
                                <NavLink to={formatUrl(this.props.is_subdomain, recruiter_name)}>Projects</NavLink>
                            </Breadcrumb.Section>
                            
                            <Breadcrumb.Divider />

                            <Breadcrumb.Section>
                                <NavLink to={formatUrl(this.props.is_subdomain, recruiter_name, this.props.table_id)}>Project Profile</NavLink>
                            </Breadcrumb.Section>
                            
                            <Breadcrumb.Divider/>
                            
                            <Breadcrumb.Section style={{fontWeight:"bold"}}>Apply</Breadcrumb.Section>
                        </Breadcrumb>

                    
            <AuthModal PageState={PageState}
                        toggleOpen={this.toggleAuthModalOpen} 
                        open={this.state.auth_modal_open} 
                        apply={true}
                        {...this.props}
                    />
                       {
                        (user.id == "")&&
                        <Button floated='right' onClick={this.handleClickAuth}>Import/SignIn</Button>
                       }

                       {
                        (user.id !== "")&&
                        <Button floated='right' onClick={this.handleSignout}>Signout</Button>
                       }
                </div>
                
                <div className="page-header">
                    <div className="page-header-text">
                        <BrandedHeader as="h1" {...this.props} content="We’re so excited you want to join our team"/>
                        <ProgressBar {...this.state} {...this.props}/>
                    </div>
                </div>
                   {
                       <Fade left>   
                {(pageStage==0&&finished===false)&&       
                <Form name="application-form"
                    error={this.state.error_messages.length > 0} 
                    onSubmit={e => {
                        e.preventDefault();
                        this.updateUserMutation(fn)
                      }}
                >


                    {/* Update user personal fields */}
                    <UserInput {...this.state} {...this.props}/>
                    <Message error
                        header={"Please fix the following items before submitting"}
                        list={this.state.error_messages.map(item => {return item.message})}
                    > 
                        {/* {this.state.error_message} */}
                    </Message>
                    <div className="center-content review-button">
                        <BrandedButton  {...this.props}   type="submit">
                            <Header as="h3" content="Next" style={{color:"white"}}/>
                        </BrandedButton>
                    </div>
                </Form>}
                </Fade>  
                   }


                   {

                        (pageStage==1&&finished===false)&&       
                <Form  onSubmit={e => {
                    e.preventDefault();
                    this.updateUserMutation(fn)
                  }}
                  name="application-form"
                 error={this.state.error_messages.length > 0}
                >
                    
                    {/* Choose table and seat */}
                    {/* <TableInput table_options={table_options}
                        seat_options={seat_options}
                        handleSeatSelect={this.props.handleSeatSelect}
                        handleTableSelect={this.props.handleTableSelect}
                        {...this.state}
                        {...this.props}
                    /> */}

                 

                    {/* Update user Education fields */}
                    <EducationInput {...this.state} {...this.props}/>
                    
                    {/* Update user work fields */}
                    <WorkInput {...this.state} {...this.props}/>

                    {/* Update user skills fields */}
                    <SkillsInput {...this.state} {...this.props}/>
                    
                    {/* Update user files */}
                    <UploadInput {...this.state} {...this.props} setUpload={this.props.setUpload} />

                    <Message error
                        header={"Please fix the following items before submitting"}
                        list={this.state.error_messages.map(item => {return item.message})}
                    > 
                        {/* {this.state.error_message} */}
                    </Message>

                    <div className="center-content review-button">
                    <BrandedButton {...this.props} type="click" onClick={this.handleBack}>
                            <Header as="h3" content="Back" style={{color:"white"}}/>
                        </BrandedButton>
                        <BrandedButton {...this.props} type="submit">
                            <Header as="h3" content="Next" style={{color:"white"}}/>
                        </BrandedButton>
                       
                    </div>

                </Form>


                   }

                   

                   {
                               pageStage==2&&       
                <Form onSubmit={e => {
                    e.preventDefault();
                    this.submitApp(fn)
                  }}
                    name="application-form"
                    error={this.state.error_messages.length > 0}
                >
                    
                    {/* Choose table and seat */}
                    {/* <TableInput table_options={table_options}
                        seat_options={seat_options}
                        handleSeatSelect={this.props.handleSeatSelect}
                        handleTableSelect={this.props.handleTableSelect}
                        {...this.state}
                        {...this.props}
                    /> */}

                    {/* Update user personal fields */}
                    {<ADAInput {...this.state} {...this.props} />}

                    <Message error
                        header={"Please fix the following items before submitting"}
                        list={this.state.error_messages.map(item => {return item.message})}
                    > 
                        {/* {this.state.error_message} */}
                    </Message>

                    {
                        !this.props.application.finished &&
                    <div className="center-content review-button">
                        <BrandedButton {...this.props} type="click" onClick={this.handleBack}>
                            <Header as="h3" content="Back" style={{color:"white"}}/>
                        </BrandedButton>
                        <BrandedButton {...this.props} type="submit">
                            <Header as="h3" content="SUBMIT" style={{color:"white"}}/>
                        </BrandedButton>
                    </div>

                    }


                        {
                            this.props.application.finished &&
                            <div className="success">
                                <p className="success-text">Your application has been submitted!</p>
                                <BrandedButton onClick={this.handleRedirect} {...this.props} type="submit">
                                            <Header as="h3" content="RETURN TO TABLES" style={{color:"white"}}/>
                                        </BrandedButton>

                            </div>
                        }
                </Form>
                   }
            </div>
            
        )

    }

}


</Mutation>

)
    }
}





export default ApplyQuery
// Library imports
import * as React from "react"
import {Mutation, MutationFn} from "react-apollo"
import {Card, Divider, Message, Icon, Button} from "semantic-ui-react"

// Local imports
import { ApplySubFormProps} from "."
import {FieldLabel, DropFileInput, BrandedLink, RTFEditor, FullPageLoader} from "../../components"

// GraphQL
import {singleUpload} from "../../GraphQL/mutations"
import { ApolloError } from "apollo-client";

interface UploadProps extends ApplySubFormProps {
    setUpload:(field:string, index?:number) => (value:any) => void
}

class UploadCard extends React.Component<UploadProps> {

    state = {
        rtf_open:false,
        cover_letter_input:this.props.application.cover_letter_input,
        overflow_error:false
    }

    componentDidMount() {

    }

    handleCLUploadComplete = (result:any) => {

        let cover_letter = result.singleUpload
        cover_letter.type = "cover_letter"
        this.props.setUpload("cover_letter")(cover_letter)
    }

    handleUploadResume = (mutationFn:MutationFn) => async (e:React.SyntheticEvent<HTMLInputElement>) => {
        let files = e.currentTarget.files
        if (files && files.length > 0) {
            await mutationFn({
                variables:{
                    file:files[0]
                }
            })
        }
        
    }

    handleResumeUploadComplete = (result:any) => {
        
        let resume = result.singleUpload
        console.log(resume);
        resume.type = "resume"
        this.props.setUpload("resume")(resume)
    }

    handleResumeUploadError = (error:ApolloError) => {
        console.log(error);
        
    }

    handleUploadOther = (mutationFn:MutationFn) => async (e:React.SyntheticEvent<HTMLInputElement>) => {
        let files = e.currentTarget.files
        
        if (files && files.length > 0) {

            let total_size = 0
            for (let file of files) {
                total_size += file.size
            }

            let mbs = (total_size / 1024) / 1024
            if (mbs > 10) {
                this.setState({overflow_error:true})
            } else {
                await mutationFn({
                    variables: {
                        file:files[0]
                    }
                })
            }

            
        }
    }

    handleOtherUploadComplete = (result:any) => {
        let other_file = result.singleUpload
        other_file.type = "other"
        this.props.setUpload("other")(other_file)
    }

    toggleRTF = () => {
        this.setState({rtf_open:!this.state.rtf_open})
    }

    handleRTFChange = (data:any) => {
        // this.setState({cover_letter_input:data})

        this.props.setUpload("cover_letter_input")(data)
    }

    

    public render() {
        let toggle_content = this.state.rtf_open ? "Upload a Cover Letter" : "Write Your Own"

        let resume = this.props.application.attachments.find(file => {
            return file.type === "resume"
        })

        let cover_letter = this.props.application.attachments.find(file => {
            return file.type === "cover_letter"
        })

        let hide_toggle = (this.state.cover_letter_input !== "<p><br></p>" 
            && this.state.cover_letter_input.length > 0)
            || cover_letter

        return (
            <Card fluid className="upload-input">
                <div className="form-card">
                    <p className="bold">Documents</p>
                    <Divider/>
                    <FieldLabel content="Cover Letter" {...this.props}/>
                    <div className="center-content input-group">
                        <div className="rtf-input">

                            <RTFEditor client={this.props.client}
                                value={this.props.application.cover_letter_input}
                                handleChange={this.handleRTFChange}
                            />
                        </div>
                    </div>
                    
                    <FieldLabel content="Resume (1MB Max)" {...this.props}/>
                    <div className="input-group">
                        
                        {
                            !resume && 
                            <label htmlFor="resumeupload">
                                <a className="ui button">Upload .pdf or .docx file</a>
                                <Mutation mutation={singleUpload}
                                    onCompleted={this.handleResumeUploadComplete}
                                    onError={this.handleResumeUploadError}
                                >
                                    {
                                        (singleUpload,{loading, error}) => {
                                            console.log(error);
                                            if (loading) return <FullPageLoader/>
                                            return (
                                                <input name="resume" 
                                                    hidden 
                                                    type="file" 
                                                    id="resumeupload" 
                                                    onChange={this.handleUploadResume(singleUpload)}
                                                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    />
                                            )
                                        }
                                    }
                                </Mutation>
                            </label>
                        }
                    </div>

                    {
                        this.props.application.attachments.map((file:any, index) => {
                            let is_resume = file.type === "resume"
                            let is_coverletter = file.type === "cover_letter"
                            
                            let emphasis = ""
                            if (is_resume) {
                                emphasis = "(resume)"
                            }

                            if (is_coverletter) {
                                emphasis = "(cover letter)"
                            }

                            return (
                                <div key={index}>
                                    <div className="file-list-item">
                                        <p>{`${file.filename} ${emphasis} `}</p>
                                        <a onClick={this.props.setUpload("remove",index)}><Icon name="close"/></a>
                                        
                                    </div>
                                    <Divider style={{margin:"0 0 10px 0"}}/>
                                </div>
                            )
                        })
                    }

                    
                    <label htmlFor="attachments_upload">
                        <BrandedLink content="ADD ANOTHER FILE"  onClick={this.handleUploadOther(singleUpload)} {...this.props}/>
                        <Mutation mutation={singleUpload}
                            onCompleted={this.handleOtherUploadComplete}
                            
                        >
                            
                            {
                                (singleUpload, {loading}) => {
                                    if (loading) return <FullPageLoader/>
                                    return (
                                        
                                        <input type="file" id="attachments_upload" hidden onChange={this.handleUploadOther(singleUpload)}/> 
                                    )
                                }
                            }
                        </Mutation>
                    </label>

                    
                    {
                        this.state.overflow_error &&
                        <Message negative>
                            Files are over 10MB total!
                        </Message>  
                    }
                    
                </div>
            </Card>
        )
    }
}

export default UploadCard
import ApolloClient from 'apollo-client'
import {withClientState} from 'apollo-link-state';
import { InMemoryCache } from 'apollo-cache-inmemory';
import {resolvers, defaults} from './GraphQL'
import {setContext} from 'apollo-link-context'
import { onError } from "apollo-link-error";
import {ApolloLink} from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';


// GraphQL Endpoint
let graphql_uri = ""
if (window.location.hostname === "jobs.wearecapable.org" || window.location.hostname === "http://localhost:3000/chronicallycapable") {
    graphql_uri = process.env.REACT_APP_WEARECAPABLE_API_URL + "/graphql"
} else {
    graphql_uri = process.env.REACT_APP_WEARECAPABLE_API_URL  + "/graphql"
}

// console.log("Using API uri", graphql_uri)


// Export apollo client
export const createClient = () => {

    // For the cache to discern interface and union types from the server
    const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData
    });

    // This is the same cache you pass into new ApolloClient
    // const cache = new InMemoryCache({ fragmentMatcher })
    const cache = new InMemoryCache({fragmentMatcher})  

    // Connect cache to client
    const stateLink = withClientState({
        cache,
        resolvers: resolvers,
        defaults:defaults
    })


    // Handle Authentication
    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('token');
        // return the headers to the context so httpLink can read them
        // console.log("using token:",token)
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    })

    // Handle Errors
    const errorLink = onError(({ graphQLErrors, networkError , response}) => {
        console.log("error response:",response)
        if (graphQLErrors) {
            console.log("graphql errors:",graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) => {
                console.log(message,locations,path);
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,)
            }
            );
    
            
        }

        if (networkError) {
            console.log(`[Network error]: ${networkError}`)
        }
        
    });

    // HTTP Link
    const uploadLink = createUploadLink(
        { 
            uri: graphql_uri,
        }
    )

    // Instantiate apollo client
    const client = new ApolloClient({
        cache:cache,
        link: ApolloLink.from([errorLink, stateLink, authLink, uploadLink]),
        
    })

    return client

}

export default createClient


import * as React from 'react'
import {Dimmer} from "semantic-ui-react"
import {TablesAnimation} from "../index"
import "./style.scss"
// @ts-ignore
import {Fade} from "react-reveal"

class FullPageLoader extends React.Component {
    public render() {
        return (
            <Fade>
                <Dimmer page active className="full-page-loader" style={{backgroundColor:"#fff"}}>
                    <div className="loading-container">
                        <TablesAnimation/>
                    </div>
                </Dimmer>
            </Fade>
        )
    }
}

export default FullPageLoader
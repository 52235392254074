// External Imports
import * as React from 'react'
import {Card, Divider, Form} from "semantic-ui-react"
// @ts-ignore
import ReactPhoneInput from "react-phone-input-2"
import {debounce} from "lodash"

// Local Imports
import { ApplySubFormProps, error_message_types } from '.';
import {FieldLabel, PlacesAutoComplete} from "../../components"

// GraphQL
import {actions} from "../../GraphQL/local_writes"
import { User, APPLICATION_ERRORS } from '../../GraphQL/types';


interface PersonalInputProps extends ApplySubFormProps {
    user:User,
    setPersonal:(changeType:string) => (e:any, data?:any) => void
}

class PersonalInput extends React.Component<PersonalInputProps> {

    state = {
        user:this.props.user
    }

    setPersonalDebounce = debounce((user:any) => {
        
        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, user)
    }, 1000)

    setPersonalWrapper = (field:string) => (e:any, data?:any) => {

        this.setPersonal(field)(e,data)
    }

    setPersonal = (field:string) => (e:any, data?:any) => {
        let user = this.props.user as User
        // let value = null as any
        switch(field) {
            case "fname":
                user.fname = e.currentTarget.value
                break
            case "lname":
                user.lname = e.currentTarget.value
                break
            case "email":
                user.email = e.currentTarget.value
                break
            case "phone":
                user.phone = e
                break
            case "website":
                user.website = [e.currentTarget.value]
                break
            case "address":
                user.personal_address = e
                break
            case "country":
                user.country = data.value
                break
            case "image":
                e.y_axis = 0
                user.profile_pic = e
                break
            
        }

        this.setState({user:user})
        this.setPersonalDebounce(user)
    }

    public render() {
        let {
            // user,
            // setPersonal
            error_messages
        } = this.props

        let {
            user
        } = this.state


        let fname_error = false
        let lname_error = false
        let email_error = false
        let phone_error = false
        let website_error = false
        let country_error = false
        let address_error = false
        
        let personal_errors = error_messages.filter(error => {
            return error.type === error_message_types.PERSONAL
        })
        
        personal_errors.map(error => {

            switch (error.code) {
                case APPLICATION_ERRORS.NO_FNAME:
                    fname_error = true
                    break
                case APPLICATION_ERRORS.NO_LNAME:
                    lname_error = true
                    break
                case APPLICATION_ERRORS.NO_PHONE:
                    phone_error = true
                    break
                case APPLICATION_ERRORS.NO_COUNTRY:
                    country_error = true
                    break
                case APPLICATION_ERRORS.NO_ADDRESS:
                    address_error = true
                    break
            }
        })

        return (
            <Card fluid className="personal-input">
            <div className="form-card">
                <p className="bold">PERSONAL INFORMATION</p>
                <Divider/>
                <Form.Group widths="equal">
                    <Form.Input label={<FieldLabel content="FIRST NAME" {...this.props}/>}
                        fluid
                        required
                        type="text"
                        // value={user.fname}
                        value={user.fname}
                        onChange={this.setPersonalWrapper("fname")}
                        error={fname_error}
                    />
                    <Form.Input label={<FieldLabel content="LAST NAME" {...this.props}/>}
                        fluid
                        required
                        type="text"
                        value={user.lname}
                        onChange={this.setPersonalWrapper("lname")}
                        error={lname_error}
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Input label={<FieldLabel content="EMAIL ADDRESS" {...this.props}/>}
                        fluid
                        required
                        type="email"
                        value={user.email}
                        onChange={this.setPersonalWrapper("email")}
                        error={email_error}
                    />
                    <div className={`field ${phone_error ? "error":""}`}>
                        <FieldLabel content="PHONE" {...this.props}/>
                        <ReactPhoneInput
                            defaultCountry="us"
                            onChange={this.setPersonalWrapper("phone")}
                            value={user.phone}

                        />
                    </div>
                    
                </Form.Group>

                <Form.Group widths="equal">

                    <Form.Input label={<FieldLabel content="WEBSITE" {...this.props}/>}
                        fluid
                        type="text"
                        value={user.website.length > 0 ? user.website[0] : ""}
                        onChange={this.setPersonalWrapper("website")}
                        error={website_error}
                    />
                    <Form.Dropdown label={<FieldLabel content="COUNTRY" {...this.props}/>}
                        fluid
                        selection
                        search
                        options={this.props.CountryOptions}
                        onChange={this.setPersonalWrapper("country")}
                        value={user.country}
                        autoComplete="off"
                        error={country_error}
                    />
                    
                    
                </Form.Group>

                <div className={`field ${address_error ? "error":""}`}>
                    <FieldLabel content="ADDRESS" {...this.props}/>
                    <PlacesAutoComplete 
                        setSelection={this.setPersonalWrapper("address")}
                        selection={user.personal_address}
                    />
                </div>

                

            </div>
        </Card>
        )
    }
}

export default PersonalInput
import * as React from 'react'
import {WithRouterProps} from "../../interfaces"
import {Transition, Icon, Divider,} from "semantic-ui-react"
import onClickOutside from "react-onclickoutside"
import "./style.scss"
import {Link} from "react-scroll"

// Sidebar component
// Mobile SlideBar
class MobileSidebar extends React.Component<WithRouterProps> {

    state = {
        sidebar_open:false
    }

    toggleSidebarOpen = (evt:React.SyntheticEvent) => {
        console.log("toggle called!")
        evt.stopPropagation()
        this.setState({sidebar_open:!this.state.sidebar_open})
    }

    setSidebarClosed = () => {
        this.setState({sidebar_open:false})
    }

    public render() {

        let {
            sidebar_open
        } = this.state

        return (
            <div className="sidebar">
            
            
                <Transition visible={sidebar_open} animation="fade">
                    <div className="sidebar-underlay">

                    </div>
                </Transition>
                
                <div>
                    <SidebarWithOutsideClick {...this.props} 
                            toggleClose={this.setSidebarClosed}
                            toggleSidebar={this.toggleSidebarOpen}
                            open={sidebar_open}
                            stopPropagation={true}
                            excludeScrollbar={true}
                        />
                </div>
            </div>
        )
    }
}

interface SidebarContentProps extends WithRouterProps {
    open:boolean,
    toggleClose:() => void,
    toggleSidebar:(e:React.SyntheticEvent) => void,
}

class SidebarContent extends React.Component<SidebarContentProps> {

    handleClickOutside = (evt:any) => {
        if (evt.type === "mousedown") {
            this.props.toggleClose()
        }
        
    }

    public render() {

        let open_style = {
            left:'120px',
        }

        let closed_style = {
            left:'0px',
        }

        let icon_style = this.props.open ? open_style : closed_style
        return (
            <div>
                <div className="sidebar-icon" onClick={this.props.toggleSidebar} style={icon_style}>
                    <Icon name="bars"/>
                </div>
            
                <Transition visible={this.props.open} animation="fade right">

                    <div className="sidebar-content">
                        <div className="sidebar-innercontent">
                            <div className="sidebar-links">
                                <Link to="about"
                                    smooth
                                    duration={500}
                                    onClick={this.props.toggleClose}
                                    className="normal-text bold sidebar-button"
                                >
                                    ABOUT
                                </Link>
                                <Link to="features"
                                    smooth
                                    duration={500}
                                    onClick={this.props.toggleClose}
                                    className="normal-text bold sidebar-button"
                                >
                                    FEATURES
                                </Link>
                                
                                <Link to="contact"
                                    smooth
                                    duration={500}
                                    onClick={this.props.toggleClose}
                                    className="normal-text bold sidebar-button"
                                >
                                    CONTACT
                                </Link>
                            </div>

                            <div className="sidebar-bottom">
                                <a className="help-text">Privacy Policy</a>
                                <Divider/>
                                <a className="help-text">Terms of Use</a>
                                <Divider/>
                                <p className="help-text">@ 2018 Tables Inc.</p>
                            </div>
                        </div>
                    </div>
                
                </Transition>
            </div>
            
            
        )
    }
}

const SidebarWithOutsideClick = onClickOutside(SidebarContent)


export default MobileSidebar
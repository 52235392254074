/**
 * Component handling the forgot password flow
 * 
 */
// library imports
import * as React from 'react'
import {Form, Modal, Message} from "semantic-ui-react"

// local imports
import {AuthContentProps} from "."
import {FieldLabel, BrandedButton, BrandedLink} from "../../components"

// graphql
import {FORGOTPASS_FLOW_STAGES, AUTH_FLOW_STAGES} from "../../GraphQL/types"
import {sendSecurityCode, verifySecurityCode, updateUserPassword} from "../../GraphQL/queries"


/**
 * React Component
 */
class AuthForgotPass extends React.Component<AuthContentProps> {

    
    state = {
        forgotpass_flow:FORGOTPASS_FLOW_STAGES.EMAIL,                                   // Current flow stage
        email_input:this.props.PageState.user ? this.props.PageState.user.email : "",   // Email input variable, prefill with user email if user exists
        code_input:"",                                                                  // Code input variable
        newpass_input:"",                                                               // New Password input variable
        confirm_newpass_input:"",                                                       // Confirm Password input variable

        // Loading and error states
        loading:false,
        error:false,
        error_msg:""

    }

    // Toggle loading state
    toggleLoading = () => {
        this.setState({loading:!this.state.loading})
    }

    // Handle if user clicks cancel
    handleCancelClick = () => {

        // Move back to signin stage
        this.props.setFlow(AUTH_FLOW_STAGES.SIGNIN)()
    }

    // Handle updating local flow stage
    // Stages: Email -> Code Input -> Password update -> Success
    setForgotPassFlow = (flow_stage:FORGOTPASS_FLOW_STAGES) => {
        this.setState({forgotpass_flow:flow_stage})
    }

    // Update local state variables with user input
    handleInputChange = (field:string) => (e:React.SyntheticEvent<HTMLInputElement>) => {
        let input_value = e.currentTarget.value
        switch (field) {
            case "email":
                this.setState({email_input:input_value})
                break
            case "code":
                this.setState({code_input:input_value})
                // If they have inputted 4 characters for the code input, submit for them
                if (input_value.length === 4) {
                    this.handleSubmit()
                }
                break
            case "newpass":
                this.setState({newpass_input:input_value})
                break
            case "confirm_newpass":
                this.setState({confirm_newpass_input:input_value})
                break
        }
    }

    // Send email to user containing security code
    handleSendEmail = async () => {
        this.toggleLoading()    // Toggle loading to true
        // Catch network error
        try {
            // Send API Call to send email with security code
            let result = await this.props.client.query({
                query:sendSecurityCode,
                variables:{
                    email:this.state.email_input
                }
            }) as any
            
            // If response is an error, set the appropriate error
            if (result.data.securityCode === "error") {
                this.setState({
                    error:true,
                    error_msg:"Seems we don't have your email, try signing up!"
                })
                
            // Otherwise, set error to false, and move to the code input flow
            } else {
                this.setState({
                    error:false,
                    error_msg:""
                })
                this.setForgotPassFlow(FORGOTPASS_FLOW_STAGES.CODE)
            }
        
        // Handle network error
        } catch (err) {
            
            this.setState({
                error:true,
                error_msg:"Something went wrong, try reloading the page..."
            })
        }

        this.toggleLoading()    // Toggle loading to false
    }

    // Handle code verification
    handleVerifyCode = async () => {

        this.toggleLoading()    // Toggle loading to true

        // Catch network error
        try {
            
            // Send API Call to verify code
            let result = await this.props.client.query({
                query:verifySecurityCode,
                variables:{
                    email:this.state.email_input,
                    security_code:this.state.code_input
                }
            }) as any

            // If the code is correct, then reset errors and move to reset password flow
            if (result.data.verifySecurityCode === "valid") {

                this.setState({
                    error:false,
                    error_msg:""
                })

                this.setForgotPassFlow(FORGOTPASS_FLOW_STAGES.RESET)

            // Otherwise, set the appropriate error
            } else {
                this.setState({
                    error:true,
                    error_msg:"Code not valid, make sure its the code in the most recent email"
                })
            }

        // Exception handling (Assuming either network error or backend bug)
        } catch (err) {
            this.setState({
                error:true,
                error_msg:"There was an error verifying the code, try reloading..."
            })
        }

        this.toggleLoading()    // Toggle loading to false
    }

    // Handle resetting the user password
    handleResetPass = async () => {        
        // If their passwords don't match, show an error and exit
        if (this.state.newpass_input !== this.state.confirm_newpass_input) {
            this.setState({
                error:true,
                error_msg:"Please make sure your passwords match!"
            })
            return
        }
        this.toggleLoading()    // Toggle loading to true
        // Catch network error
        try {
            // Send API Call to update the user password
            let result = await this.props.client.query({
                query:updateUserPassword,
                variables:{
                    email:this.state.email_input,
                    password:this.state.newpass_input
                }
            })
            // There is no error code here, so we are going to assume that the response is successful
            this.setState({
                error:false,
                error_msg:""
            })
            this.setForgotPassFlow(FORGOTPASS_FLOW_STAGES.SUCCESS)

        // Exception handling assuming this is either a network error or backend bug
        } catch (err) {

            console.error("error updating password",err)
            this.setState({
                error:true,
                error_msg:"Something went wrong, try reloading the page..."
            })
        }

        this.toggleLoading()    // Toggle loading to false
    }

    // Handle submitting the form
    handleSubmit = () => {

        // Switch the behavior depending on the current flow stage
        switch (this.state.forgotpass_flow) {
            case FORGOTPASS_FLOW_STAGES.EMAIL:
                this.handleSendEmail()
                break
            case FORGOTPASS_FLOW_STAGES.CODE:
                this.handleVerifyCode()
                break
            case FORGOTPASS_FLOW_STAGES.RESET:
                this.handleResetPass()
                break
            case FORGOTPASS_FLOW_STAGES.SUCCESS:
                this.props.setFlow(AUTH_FLOW_STAGES.SIGNIN)()
                break
        }

    }

    // Render the component
    public render() {
        // Fetch the background color
        let bg_color = this.props.PageState.recruiter.brand.color

        // Get state variables for the component state
        let {
            forgotpass_flow,
            email_input,
            code_input,
            newpass_input,
            confirm_newpass_input,
            loading,
            error,
            error_msg
        } = this.state

        // Local variables for the button content and which form to use
        let submit_content = ""
        let form_component = null

        // Switch the content depending on the current flow
        switch (forgotpass_flow) {
            
            // If the user is inputting their email, give them a text input
            case FORGOTPASS_FLOW_STAGES.EMAIL:
                form_component = (
                    <Form.Input value={email_input}
                        onChange={this.handleInputChange("email")}
                        fluid
                        required
                        type="email"
                        label={<FieldLabel content="EMAIL" {...this.props}/>}
                    />
                )
                submit_content = "SEND CODE"
                break
                
            // If the user is inputting their code, give them a text input
            case FORGOTPASS_FLOW_STAGES.CODE:
                form_component = (
                    <Form.Input value={code_input}
                        onChange={this.handleInputChange("code")}
                        fluid
                        required
                        type="password"
                        label={<FieldLabel content="CODE" {...this.props}/>}
                    />
                )
                submit_content = "VERIFY CODE"
                break

            // If the user is resetting their password, give them 2 text inputs, one for confirming
            case FORGOTPASS_FLOW_STAGES.RESET:
                form_component = (
                    <div style={{marginBottom:"20px"}}>
                        <Form.Input value={newpass_input}
                            onChange={this.handleInputChange("newpass")}
                            fluid
                            required
                            type="password"
                            label={<FieldLabel content="NEW PASSWORD" {...this.props}/>}
                            pattern="[^\x22\x27]{8,}"
                        />
                        <Form.Input value={confirm_newpass_input}
                            onChange={this.handleInputChange("confirm_newpass")}
                            fluid
                            required
                            type="password"
                            label={<FieldLabel content="CONFIRM PASSWORD" {...this.props}/>}
                            pattern="[^\x22\x27]{8,}"
                        />
                    </div>
                )

                submit_content = "UPDATE PASSWORD"
                break

                case FORGOTPASS_FLOW_STAGES.SUCCESS:
                    form_component = (
                        <div className="success">
                            <p></p>
                        </div>
                    )
                    submit_content = "BACK"

                break
        }

        // Return the component, we are assuming that our component is wrapped in a semantic-ui <Modal> component
        return (
            <div>
                {/* Modal Header */}
                <Modal.Header style={{backgroundColor:bg_color}} >
                    <p className="bold" style={{color:"white"}}>
                        {"Reset Password".toUpperCase()}
                    </p>
                </Modal.Header>

                {/* Modal Content */}
                <Modal.Content>

                    <Form onSubmit={this.handleSubmit}
                        loading={loading}
                        error={error}
                        success={forgotpass_flow === FORGOTPASS_FLOW_STAGES.SUCCESS}
                    >
                        
                        {
                            form_component
                        }

                        <Message error>
                            {error_msg}
                        </Message>

                        <Message success>
                            <p>You have successfully updated your password.</p>
                        </Message>

                        <div className="center-content">
                            <BrandedButton  content={submit_content} {...this.props}/>
                        </div>

                    </Form>

                </Modal.Content>

                {/* Below the modal content, allow them to move back to Signin */}
                <div className="below-modal">
                    <p>
                        <BrandedLink PageState={this.props.PageState}
                            onClick={this.handleCancelClick}
                            content="Cancel"
                        />
                    </p>
                </div>
            </div>
        )
    }
}

export default AuthForgotPass
// External Imports
import * as React from 'react'
import {Card, Divider, Form,Header,Segment,Checkbox,Grid,List} from "semantic-ui-react"
// @ts-ignore
import ReactPhoneInput from "react-phone-input-2"
import {debounce} from "lodash"

// Local Imports
import { ApplySubFormProps, error_message_types } from '.';
import {FieldLabel, PlacesAutoComplete} from "../../components"

// GraphQL
import {actions} from "../../GraphQL/local_writes"
import { User, APPLICATION_ERRORS } from '../../GraphQL/types';


interface ADAInputProps extends ApplySubFormProps {
    user:User,
    setPersonal:(changeType:string) => (e:any, data?:any) => void
}

class ADAInput extends React.Component<ADAInputProps> {

    state = {
        user:this.props.user,
        value:""
    }
    setPersonalDebounce = debounce((user:any) => {
        
        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, user)
    }, 1000)

    setPersonalWrapper = (field:string) => (e:any, data?:any) => {

        this.setPersonal(field)(e,data)
    }

    // event wrapper around html ()
    handleChange = async (event: React.SyntheticEvent, data: any) =>{
      

        console.log("application_",this.props.application);    

        this.setState({
            value:data.value
        })

        // Also Props On The:  

        let new_application = {
            ...this.props.application,
            disability:data.value
        }    
        console.log(new_application);
       // await this.updateUserMutation(updateUser);
        this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, new_application);

    }
    
    setPersonal = (field:string) => (e:any, data?:any) => {
        let user = this.props.user as User
        // let value = null as any
        switch(field) {
            case "fname":
                user.fname = e.currentTarget.value
                break
            case "lname":
                user.lname = e.currentTarget.value
                break
            case "email":
                user.email = e.currentTarget.value
                break
            case "phone":
                user.phone = e
                break
            case "website":
                user.website = [e.currentTarget.value]
                break
            case "address":
                user.personal_address = e
                break
            case "country":
                user.country = data.value
                break
            case "image":
                e.y_axis = 0
                user.profile_pic = e
                break
            
        }

        this.setState({user:user})
        this.setPersonalDebounce(user)
    }

    public render() {
        let {
            // user,
            // setPersonal
            error_messages
        } = this.props

        let {
            user
        } = this.state


        let checkBoxValue = ['Blindness','Autism','Bipolar disorder','Post-traumatic stress disorder (PTSD)','Deafness','Cerebral palsy','Major depression',
        'Obsessive compulsive disorder','Cancer','HIV/AIDS','Multiple sclerosis (MS)','Impairments requiring the use of a wheelchair ','Diabetes','Epilepsy','Schizophrenia','Muscular dystrophy','Missing limbs or partially missing limbs','Intellectual disability (previously called mental retardation'];

        let checkedOptionValue = ["YES, I HAVE A DISABILITY (or previously had a disability)","NO, I DON’T HAVE A DISABILITY","I DON’T WISH TO ANSWER"];



        let fname_error = false
        let lname_error = false
        let email_error = false
        let phone_error = false
        let website_error = false
        let country_error = false
        let address_error = false
        
        let personal_errors = error_messages.filter(error => {
            return error.type === error_message_types.PERSONAL
        })
        
        personal_errors.map(error => {

            switch (error.code) {
                case APPLICATION_ERRORS.NO_FNAME:
                    fname_error = true
                    break
                case APPLICATION_ERRORS.NO_LNAME:
                    lname_error = true
                    break
                case APPLICATION_ERRORS.NO_PHONE:
                    phone_error = true
                    break
                case APPLICATION_ERRORS.NO_COUNTRY:
                    country_error = true
                    break
                case APPLICATION_ERRORS.NO_ADDRESS:
                    address_error = true
                    break
            }
        })

        return (
            <Card fluid className="personal-input">
            <div className="form-card">
                <p className="bold">Voluntary Self-Identification of Disability</p>

            
                <Divider/>


                <Header as='h3' attached='top'>
                Why are you being asked to complete this section?
            </Header>
            <Segment attached>
            Because we do business with the government, we must reach out to, hire, and provide equal opportunity to qualified people with disabilities.i To help us measure how well we are doing, we are asking you to tell us if youhave a disability or if you ever had a disability.  Completing this form is voluntary, but we hope that you will choose to fill it out.  If you are applying for a job, any answer you give will be kept private and will not be used against you in any way. If you already work for us, your answer will not be used against you in any way.  Because a person may become disabled at any time, we are required to ask all of our employees to update their information every fiveyears.  You may voluntarily self-identify as having a disability on this form without fear of any punishment because you did not identify as having a disability earlier.
            </Segment>
            <Divider/>

             <Header as='h3' attached='top' >
             How do I know if I have a disability?
            </Header>    
            <Segment attached>
            You are considered to have a disability if you have a physical or mental impairment or medical condition that substantially limits a major life activity, or if you have a history or record of such an impairment or medical condition.
            
            <div className="bold">
            Disabilities include, but are not limited to: 
             <Grid stackable columns={4} padded>
            {
              checkBoxValue.map((_value)=>{  
            return(
                <Grid.Column key={_value}>
          {/* <Checkbox
            label={_value}
            name='checkboxRadioGroup'
            value={_value}
            onChange={this.handleChange}
          /> */}

           <List bulleted><List.Item>{_value}</List.Item></List>
            </Grid.Column>
        
            )
          
        })
    }
      </Grid>
       <Header as='h4'>
       Please check one of the boxes below:
            </Header> 
            <Grid columns={1} padded>
            {
            checkedOptionValue.map((_value)=>{  
            return(
             <Grid.Column key={_value}>
                <Checkbox
                label={_value}
                name='checkboxDisability'
                value={_value}
                onChange={this.handleChange}
                checked={this.state.value == _value}
                />
             </Grid.Column>
                )
            })
        }
        </Grid>
            </div>
            </Segment>
        <Divider/>
        <Header as='h3' attached='top'>
        Reasonable Accommodation Notice
            </Header>
            <Segment attached>
            Federal law requires employers to provide reasonable accommodation to qualified individuals with disabilities.    Please tell us if you require a reasonable accommodation to apply for a job or to perform your job.  Examples of reasonable accommodation include making a change to the application process orwork procedures, providing documents in an alternate format, using a sign language interpreter, or using specialized equipment.
            <p className="bold"> Section 503 of the Rehabilitation Act of 1973, as amended.  For more information about this form or the equal employment obligations of Federal contractors, visit the U.S. Department of Labor’s Office ofFederal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp. PUBLIC BURDEN STATEMENT:  According to the Paperwork Reduction Act of 1995 no persons are required to respond to a collection of information unless such collection displays a valid OMB control number. This survey should take about 5 minutes to complete</p>
            </Segment>
            </div>
        </Card>
        )
    }
}

export default ADAInput
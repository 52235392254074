import * as React from 'react'
// import * as logo from './logo'
import './App.scss'
import {BrowserRouter as Router} from "react-router-dom"
import Routes from './routes'
import './semantic/dist/semantic.min.css'
import './App.scss'
import { ApolloProvider} from "react-apollo"
import createApolloClient from "./createApolloClient"
import ReactGA from "react-ga"
// @ts-ignore
import FullStory from 'react-fullstory';
// @ts-ignore
import parser from 'tld-extract'

// React Day Picker
import 'react-day-picker/lib/style.css'

// React Quill
import 'react-quill/dist/quill.snow.css';

// React Slick
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CookieConsent from "react-cookie-consent";

const client = createApolloClient()

class App extends React.Component {

    componentDidMount () {
        let tracking = "UA-126644494-1"; // chronically Capable Tracking Code
        
        if (this.isChronicallyCapable) {
            tracking = "UA-126644494-1"
        }
        
        // Analytics
        ReactGA.initialize(tracking, {
            debug:true,
            titleCase:false,
        })

        //@ts-ignore
        window.Intercom("boot", {
            app_id: "t2g7yl3a"
          });
    }

    isChronicallyCapable = ():boolean => {
        return window.location.hostname.includes("wearecapable.org")
    }

    checkSubdomain = ():boolean => {
        let all_domains = window.location.hostname
        return !(
            all_domains.includes("tables.work") 
            || all_domains.includes("localhost")
            )
    }

    render() {

        let is_subdomain = this.checkSubdomain()
        // Set default fullStory id
        let fullStoryOrg = "GRP1R"

        // If it's a subdomaina and its chronically capable
        if (is_subdomain) {
            if (this.isChronicallyCapable()) {
                fullStoryOrg = "K2JJ9"
            }
        }


       

        return (

            
            <ApolloProvider client={client}>
                <FullStory org={fullStoryOrg} />
                <Router>
                    <Routes is_subdomain={is_subdomain}/>
                </Router>
            </ApolloProvider>
        );
    }
}

export default App;

// Library imports
import * as React from 'react'
import { History } from 'history';

// Local imports
import "./style.scss"
import PuzzlePiece from "../../media/svg/puzzle-piece.svg"
import { Modal, Header, Image, Button } from 'semantic-ui-react';
import { BrandedButton, WithPageState } from '../Branded';
import {formatUrl} from "../../utils"

// GraphQL
import {PageState} from "../../GraphQL/types"
import { WithRouterProps } from '../../interfaces';

interface FullPageErrorProps extends WithRouterProps {
    PageState?:PageState
}


class FullPageError extends React.Component<FullPageErrorProps> {

    state = {
        open:true
    }

    toggleOpen = () => {
        this.setState({open:!this.state.open})
    }

    handleClick = () => {
        this.toggleOpen()
        let new_url = formatUrl(this.props.is_subdomain, this.props.PageState ? this.props.PageState.recruiter.route_url : "")
        this.props.history.push(new_url)
    }

    public render() {

        let brand_color = this.props.PageState ? this.props.PageState.recruiter.brand.color : ""
        return (
            <div className="full-page-error-container">
                <Modal open={this.state.open}
                    className="full-page-error"
                >

                    <div className="modal-content">
                        <div className="modal-content-inside">
                            <div className="center-content">
                                <Image src={PuzzlePiece} color={brand_color} alt="Puzzle piece error image"/>
                            </div>

                            <div className="content-text center-content">
                                <Header as="h1" content="Oops! Looks like this piece came loose…"/>
                                <Header as="h3" content="We’re working quickly to put it back in place."/>
                            </div>

                            <div className="center-content">
                                {
                                    this.props.PageState ? 
                                    <BrandedButton content="GO BACK" PageState={this.props.PageState} onClick={this.handleClick}/> : 
                                    <Button content="GO BACK" onClick={this.handleClick}/>
                                }
                                
                            </div>
                        </div>
                    </div>

                </Modal>
            </div>
        )
    }
}

export default FullPageError


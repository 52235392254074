// Library imports
import * as React from 'react'
import {Modal, Form, Message} from "semantic-ui-react"
// @ts-ignore
import {Fade} from "react-reveal"
import { Mutation, MutationFn} from "react-apollo"
import ReactGA from "react-ga"

// Local imports
import {AuthContentProps} from "."
import {FieldLabel, BrandedButton, BrandedLink} from "../../components"

// GraphQL
import {AUTH_FLOW_STAGES, _UserType} from "../../GraphQL/types"
import {addUser} from "../../GraphQL/mutations"
import { ApolloError } from 'apollo-client';
import { actions } from '../../GraphQL/local_writes';
import AnalyticsItems from '../../analytics';


class AuthSignup extends React.Component<AuthContentProps> {

    state = {
        email_input:"",     // Email input
        password_input:"",  // Password input
        error:false,        // Error
        error_message:""    // Error message
    }

    // Handle submit signup call
    handleSignup = (mutationFn:MutationFn) => async () => {

        // Initialize payload
        let vars = {
            email:this.state.email_input,
            password:this.state.password_input,
            type:[_UserType.WORKER]
        }

        // Send api call
        await mutationFn({
            variables:vars
        })
    }

    // Handle updating an input field
    handleSignupChange = (changeType:string) => (e:React.SyntheticEvent<HTMLInputElement>) => {

        let value = e.currentTarget.value   // Fetch user input

        // Update state based on which input changed
        switch (changeType) {
            case "email":
                this.setState({email_input:value})
                break
            case "password":
                this.setState({password_input:value})
                break
        }
    }

    // Handle Callback after api call completed
    handleSignupCompleted = async (data:any) => {
        
        // Fetch data
        let token = data.addUser.token
        let user = data.addUser.user

        // Set Analytics items
        ReactGA.set({userId:user.id})
        ReactGA.event(AnalyticsItems.REGISTER)
        
        console.log("userObject",data)

        // Set user data in local cache
        await this.props.PageState.CacheWriter.write_cache(actions.SET_TOKEN, token)
        await this.props.PageState.CacheWriter.write_cache(actions.SET_USER, user)

        // Handle redirecting to the apply page
        this.props.handleRedirect()
    }

    // Handle callback if error status
    handleSignupError = (error:ApolloError) => {

        // Default error message (network)
        let message = "There seems to have been a network error"

        // Catch user exists error
        if (error.message.includes("already exists")) {
            message = "That user already exists. Try signing in!"
        }

        // Update local state
        this.setState({
            error:true,
            error_message:message
        })
        
    }

    public render() {

        let bg_color = this.props.PageState.recruiter.brand.color         // Recruiter brand color

        return (
            <Fade>
                <div>
                    {/* Header */}
                    <Modal.Header style={{backgroundColor:bg_color}} >
                        <p className="bold" style={{color:"white"}}>{"Sign Up".toUpperCase()}</p>
                    </Modal.Header>

                    {/* Content */}
                    <Modal.Content>
                        
                        <Mutation mutation={addUser}
                            onCompleted={this.handleSignupCompleted}
                            onError={this.handleSignupError}
                        >
                            {
                                (addUser, {loading}) => {
                                    return (
                                        <Form onSubmit={this.handleSignup(addUser)}
                                            error={this.state.error}
                                            loading={loading}
                                        >
                                            <Form.Field required>
                                                <FieldLabel content="EMAIL" {...this.props}/>
                                                <input placeholder="john@doe.com"
                                                    type="email"
                                                    onChange={this.handleSignupChange("email")}
                                                    required
                                                />
                                            </Form.Field>
                                            <Form.Field required>
                                                <FieldLabel content="PASSWORD" {...this.props}/>
                                                <input placeholder="********"
                                                    type="password"
                                                    onChange={this.handleSignupChange("password")}
                                                    required
                                                    pattern={"[^\x22\x27]{8,}"}
                                                    title="Please enter a password with at least 8 characters!"
                                                    />
                                            </Form.Field>
                                            <p className="help-text">Must be at least 8 characters</p>

                                            <div className="center-content">
                                                <BrandedButton content="SIGN UP" 
                                                    PageState={this.props.PageState}
                                                    type="submit"
                                                />
                                            </div>

                                            <Message error>
                                                {this.state.error_message}
                                            </Message>
                                        </Form>
                                    )
                                }
                            }
                        </Mutation>

                    </Modal.Content>

                    <div className="below-modal">
                        Already have an account?
                        <BrandedLink PageState={this.props.PageState} 
                            onClick={this.props.setFlow(AUTH_FLOW_STAGES.SIGNIN)}
                            content="SIGN IN"
                            style={{marginLeft:"5px"}}
                        />
                    </div>
                </div>
            </Fade>
        )
    }
}

export default AuthSignup
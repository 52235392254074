// Library imports
import * as React from 'react'
import {Mutation, MutationFn} from "react-apollo"
import {Grid, Divider, Image, Icon} from "semantic-ui-react"
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img'

// Local imports
import "./style.scss"
import {BrandedText, BrandedHeader, RTFDisplay, FullPageLoader} from "../../components"
import CameraSVG from "../../media/svg/camera-png.svg"
import { ApplyReviewProps } from '../../pages/Apply';
import { formatUrl } from '../../utils';

// GraphQL
import {User, PageState} from "../../GraphQL/types"
import { ApolloError } from 'apollo-client';
import { singleUpload } from '../../GraphQL/mutations';

interface DisplayResumeProps extends ApplyReviewProps {
    PageState:PageState,    // Global pagestate
    user:User,              // Application user
    setUserImage:any        // Function to set the user image
}

class DisplayResume extends React.Component<DisplayResumeProps> {

    state = {
        user:this.props.user    // Initialize with user prop
    }

    // Wrapper 
    setUserImage = (image:any) => {
        this.props.setUserImage(image)
    }
    
    public render() {

        let {
            user,
            PageState,
            application,
            setPersonal
        } = this.props

        if (application.selected_seat === "" || application.selected_table === "") {
            let url_params = this.props.match.params as any
            let table_id = url_params.table_id
            let new_url = formatUrl(this.props.is_subdomain, PageState.recruiter.route_url, table_id, undefined, true)
            this.props.history.push(new_url)
        }

        let selected_seat_name = ""
        this.props.PageState.recruiter.tables.map(table => {
            table.publish_seats.filter(seat => {
                if (application.selected_seat === seat.id) {selected_seat_name=seat.title}
            })[0]
        })

        let resume = application.attachments.find(file => {
            return file.type === "resume"
        }) as any

        let other_attachments = application.attachments.filter(file => {
            return file.type !== "resume"
        })

        return (
            <div className="display-resume">
                
                <div className="resume-top">
                    <Grid stackable>
                        <Grid.Row columns={3}>
                            <Grid.Column width={3}>

                                <div className="user-image">
                                    <UserImage setUserImage={this.setUserImage}
                                        {...this.props}
                                        />
                                </div>

                            </Grid.Column>

                            <Grid.Column width={9}>
                                <div className="name">
                                    
                                    <div className="name-content">
                                        <BrandedHeader content={`${user.fname} ${user.lname}`} as="h1" {...this.props}/>
                                        <BrandedText colored content={selected_seat_name} {...this.props}/>
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column width={4}>
                                <div className="details">
                                    <div>
                                        <p>{user.personal_address}</p>
                                        <p>{user.phone}</p>
                                        <p>{user.email}</p>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                

                </div>
                {
                    application.cover_letter_input.length > 0 &&
                    <div className="section">
                        <p className="bold">Cover Letter</p>
                        <Divider/>
                        {
                            application.cover_letter_input &&
                            <RTFDisplay data={application.cover_letter_input}/>
                            
                        }
                    </div>
                
                }
                

                <div className="section">
                    <p className="bold">RESUME AND WORK SAMPLES</p>
                    <Divider/>
                    {
                        user.website.length > 0 &&
                        <PortfolioItem link={user.website[0]} 
                            icon_name="world" 
                            {...this.props}
                            />
                    }

                    {
                        resume &&
                        <PortfolioItem link={resume.filename} 
                            icon_name="linkify" 
                            {...this.props}
                            
                            />
                    }
                </div>

                {
                    user.education.length > 0 &&
                    <div className="section education">
                        <p className="bold">EDUCATION</p>
                        <Divider/>
                        {
                            user.education.map((education,index) => {
                                return (
                                    <ResumeItem key={index}
                                        header={education.university}
                                        sub_header={`${education.degree}, ${education.major}`}
                                        item_location={education.country}
                                        {...this.props}
                                    />
                                )
                            })
                        }
                    </div>
                }
            

                {
                    user.experience.length > 0 &&
                    <div className="section work">
                        <p className="bold">WORK EXPERIENCE</p>
                        <Divider/>
                        {
                            user.experience.map((experience,index) => {
                                return (
                                    <ResumeItem key={index}
                                        header={experience.company.text}
                                        sub_header={experience.role}
                                        item_location={experience.location}
                                        {...this.props}
                                    />
                                )
                            })
                        }
                    </div>
                }

                {
                    other_attachments.length > 0 &&
                    <div className="section attachments">
                        {
                            other_attachments.map((file:any, index:number) => {
                                return (
                                    <div className="attachment-items" key={index}>
                                        <p>{file.filename}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                }


            </div>
        )
    }
}

interface UserImageProps extends DisplayResumeProps {
    user:User,
    PageState:PageState,
    setUserImage:(image:any) => void
}

class UserImage extends React.Component<UserImageProps> {

    handleImageUpload = (mutationFn:MutationFn) => (e:any) => {
        let files = e.currentTarget.files
        
        if (files && files.length > 0) {
            let file = files[0]

            // loadImage
            // loadImage(
            //     e.target.files[0],
            //     function (img) {
            //         document.body.appendChild(img);
            //     },
            //     {maxWidth: 600} // Options
            // );
            mutationFn({
                variables:{
                    file:file
                }
            })
        }
    }

    handleUploadFinish = (data:any) => {
        
        this.props.setUserImage(data)
    }

    handleUploadError = (error:ApolloError) => {
        console.log("error uploading image:",error)
    }

    public render() {
        let bg_color = this.props.PageState.recruiter.brand.color
        let user_image = this.props.user.profile_pic as any

        return (
            <label htmlFor="user-img-input">
                <a>
                    {
                        !user_image || !user_image.filename ? 
                        <div style={{backgroundColor:bg_color, cursor:'pointer'}} className="user-image-input">
                            <Image src={CameraSVG} alt="Camera upload icon"/>
                            <BrandedHeader as="h2" content="Upload Photo" style={{color:"white"}} {...this.props}/>
                        </div>
                        :
                        <div className="user-img" style={{cursor:"pointer"}}>
                            <ExifOrientationImg src={user_image.path}
                                alt="user-image"
                            />
                            
                            {/* <Image src={user_image.path}/> */}
                        </div>
                    }
                </a>
                <Mutation mutation={singleUpload}
                    onCompleted={this.handleUploadFinish}
                    onError={this.handleUploadError}
                >
                    {
                        (singleUpload, {loading}) => {
                            if (loading) return <FullPageLoader/>
                            return (
                                <input hidden
                                    accept=".jpg,.jpeg,.png,.gif" 
                                    type="file" 
                                    id="user-img-input" 
                                    onChange={this.handleImageUpload(singleUpload)}/>
                            )
                        }
                    }
                </Mutation>
                
            </label>
        )

        
    }
}

interface ResumeItemProps extends DisplayResumeProps {
    header:string,
    sub_header:string,
    item_location:string
}

class ResumeItem extends React.Component<ResumeItemProps> {
    public render() {
        let {
            header,
            sub_header,
            item_location
        } = this.props
        return (
            <div className="resume-item">
                <div className="item-contents">
                    <BrandedText className="bold" content={header} PageState={this.props.PageState} font={false}/>
                    <BrandedText content={sub_header} PageState={this.props.PageState} font={false}/>
                </div>

                <div className="item-details">
                    <BrandedText content={item_location} PageState={this.props.PageState} font={false}/>
                </div>
            </div>
        )
    }
}

interface PortfolioItemProps extends DisplayResumeProps {
    icon_name:string,
    link:string,
    onClick?:any
}

class PortfolioItem extends React.Component<PortfolioItemProps> {
    public render() {
        return (
            <div className="portfolio-item">
                <BrandedText className="bold" PageState={this.props.PageState} font={false}/>
                <div className="item-content">
                    <Icon name={this.props.icon_name as any}/>
                    <BrandedText content={this.props.link} PageState={this.props.PageState} font={false}/>
                </div>
            </div>
        )
    }
}


export default DisplayResume
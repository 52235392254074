import * as React from 'react';
import {MutationFn,Mutation} from "react-apollo";
import {ViewTablesProps} from "..";
import {Modal,Header,Image,Divider,Form,Message} from "semantic-ui-react";
import {BrandedText,BrandedButton,SuccessAnimation } from "../../../components"
import {addToFollow} from "../../../GraphQL/mutations";


class NoTables extends React.Component<ViewTablesProps> {

    state = {
        user_name:this.props.PageState.user ? this.props.PageState.user.fname : "",
        user_email:this.props.PageState.user ? this.props.PageState.user.email : "",
        error:false,
        error_msg:"",
        success:false
    }

    handleNameChange = (e:React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({user_name:e.currentTarget.value})
    }

    handleEmailChange = (e:React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({user_email:e.currentTarget.value})
    }

    handleSubmit = (mutationFn:MutationFn) => () => {
        let vars = {
            name:this.state.user_name,
            email:this.state.user_email,
            recruiter_id:this.props.PageState.recruiter.id
        }
        console.log("submitting with vars:",)
        mutationFn({
            variables:vars
        })
    }


    handleSubmitCompleted = (data?:any) => {
        
        this.setState({
            error:false,
            success:true,
            error_msg:""
        })
    }

    handleSubmitError = (error:any) => {
        let msg = "Seems there was a network error, try resubmitting"
        if (error.message.includes("Follower already exists")) {
            msg = "You're already signed up!"
        }
        this.setState({
            success:false,
            error:true,
            error_msg:msg
        })
    }

    public render() {
        let recruiter = this.props.PageState.recruiter
        return (
            <div className="no-tables">
                <Modal open
                    className="no-tables-modal modal-small"
                >
                    <div className="no-tables-content header-content">
                        <Header as="h1" content={`Thank you for your interest in ${recruiter.name}`}/>

                        <Image src={recruiter.brand.logo} alt="Recruiter brand logo"/>
                    </div>

                    <Divider style={{margin:"0"}}/>

                    <div className="no-tables-content">

                        <div className="error-content">
                            <BrandedText content={"We don’t have any available opportunities at the moment. Sign up to be the first to know when we do!"} 
                                style={{marginBottom:"20px"}}
                                colored={false}
                                {...this.props}
                                font={false}
                            />

                            <Mutation mutation={addToFollow}
                                onCompleted={this.handleSubmitCompleted}
                                onError={this.handleSubmitError}
                            >
                                {
                                    (addToFollow, {loading}) => {
                                        return (
                                            <Form onSubmit={this.handleSubmit(addToFollow)}
                                                loading={loading}
                                                error={this.state.error}
                                                success={this.state.success}
                                            >
                                                
                                                <Form.Input fluid
                                                    label="NAME"
                                                    placeholder="John Doe"
                                                    onChange={this.handleNameChange}
                                                    type="text"
                                                />

                                                <Form.Input fluid
                                                    label="EMAIL"
                                                    placeholder="youremail@email.com"
                                                    type="email"
                                                    onChange={this.handleEmailChange}
                                                    required
                                                />

                                                <div className="center-content">
                                                    <BrandedButton type="submit" 
                                                        content="Sign Up" 
                                                        PageState={this.props.PageState}
                                                    />
                                                </div>

                                                <Message error>
                                                    {this.state.error_msg}
                                                </Message>

                                                <Message success>
                                                    <div className="success-content">
                                                        <p>You've been added to the list</p>
                                                        {
                                                            this.state.success &&
                                                            <div className="success-animation"><SuccessAnimation/></div>
                                                        }
                                                    </div>
                                                    

                                                </Message>
                                            </Form>
                                        )
                                    }
                                }
                            </Mutation>

                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default NoTables;
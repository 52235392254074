// library imports
import * as React from 'react'
import {Query} from "react-apollo"
import Slider from "react-slick"
import {Modal, Header, Icon} from "semantic-ui-react"

// local imports
import { WithRouterProps } from '../../interfaces'
import { FullPageLoader, FullPageError, BrandedHeader, BrandedButton, RTFDisplay, AuthModal } from '../../components'
import "./style.scss"

// graphql
import { Seat, PageState, User, Table } from '../../GraphQL/types';
import {actions} from "../../GraphQL/local_writes"
import ApolloClient from 'apollo-client';
import { NormalizedCache } from 'apollo-cache-inmemory';
import { formatUrl } from '../../utils';

// should input either a list of seats belonging to 1 table or a list of tables
interface SeatCarouselProps extends WithRouterProps {
    PageState:PageState,
    client:ApolloClient<NormalizedCache>
    seats?:Seat[],
    tables?:Table[],
    has_applied?:boolean,
    initial_seat?:string,
    toggleClosed:Function
}

class SeatCarousel extends React.Component<SeatCarouselProps> {

    sliderRef:any

    state = {
        current_seat_id:"",
        initial_index:undefined,
        auth_modal_open:false
    }

    toggleAuthModalOpen = () => {
        this.setState({auth_modal_open:!this.state.auth_modal_open})
    }

    // Set initial seat id
   async componentDidMount() {

        if (this.sliderRef && this.sliderRef.current) {
            this.sliderRef.current.innerSlider.list.setAttribute('tabindex', 0);
            this.sliderRef.current.innerSlider.list.focus();
        }

        let url_params = this.props.match.params as any
        let seat_id = "" as any
        let active_index = -1
        if (this.props.seats) {
            console.log("active_seat....",this.props.initial_seat)
            seat_id = this.props.initial_seat ? this.props.initial_seat : url_params.seat_id
            console.log("active_seat....",seat_id)

            console.log(this.props.seats);
            this.props.seats.map((seat,index) => {
                console.log("seat",seat)
                console.log("index",index);
                if (seat.id === seat_id) {
                    active_index = index
                }
            })           

        } else if (this.props.tables) {
            seat_id = this.props.initial_seat
            this.props.tables.map(table => {
                table.publish_seats.map ((seat,index) => {
                    if (seat.id === seat_id) {
                        active_index = index
                    }
                })
            })
        }
        
        console.log("seat_id",seat_id);

        let new_url = `${window.location.origin+window.location.pathname}?search_query=${""}&&view=positions&&id=${seat_id}`
            history.pushState({}, "", new_url)


        console.log("activeIndex",active_index);

        this.setState({
            current_seat_id:seat_id,
            initial_index:active_index
        });

        console.log("current_seat_id",this.state.current_seat_id);

    }

    handleClose = () => {
        this.props.toggleClosed()
        
    }

    public render() {

        let {
            match,
            seats,
            tables
        } = this.props


        console.log(seats);
        // url params
        let url_params = match.params as any
        let recruiter_name = url_params.recruiter_name
        let table_id = url_params.table_id
        let seat_id = url_params.seat_id

        if (!this.props.seats && !this.props.tables) {
            return <FullPageError {...this.props}/>
        }
        
        return (
            <div className="seat-carousel">

                <Modal open
                    onClose={this.handleClose}
                    className="seat-carousel-modal"
                >
                    <AuthModal open={this.state.auth_modal_open}
                        toggleOpen={this.toggleAuthModalOpen}
                        table_id={table_id}
                        seat_id={seat_id}
                        apply={true}
                        {...this.props}
                        />

                    <div className="carousel-content">
                        <Slider dots={false}
                            infinite
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            easing="ease-in-out"
                            accessibility={true}
                            initialSlide={this.state.initial_index}
                            ref={this.sliderRef}
                        >
                            {
                                seats && seats.map(seat => {
                                    return <SeatItem 
                                                key={seat.id} 
                                                seat={seat} 
                                                toggleAuthModalOpen={this.toggleAuthModalOpen}
                                                has_applied={this.props.has_applied}
                                                {...this.props}
                                            />
                                })
                            }

                            {
                                tables && tables.map(table => {
                                    let has_applied = table.applicants.find((application:any) => {
                                        let user = this.props.PageState.user as any
                                        return application.user_id.id === user.id
                                    }) !== undefined
                                    return table.publish_seats.map(seat => {
                                        return (
                                            <SeatItem key={seat.id}
                                                seat={seat}
                                                toggleAuthModalOpen={this.toggleAuthModalOpen}
                                                has_applied={has_applied}
                                                {...this.props}
                                                />
                                        )
                                    })
                                })
                            }
                        </Slider>
                    </div>
                </Modal>
            </div>
        )
    }
}

interface SeatItemProps extends SeatCarouselProps {
    seat:Seat,
    toggleAuthModalOpen:Function
    // has_applied:boolean
}

class SeatItem extends React.Component<SeatItemProps> {
    state = {
        auth_modal_open:false
    }

    // component_DidMount  
    componentDidMount() {

        //console.log(this.props.seat.id);


    }



    // handle Seat Apply Click
    handleApplyClick = () => {

        // Local references
        let user = this.props.PageState.user as User
        let location_params = this.props.match.params as any
        let table_id = this.props.seat.table_id.id
        let seat_id = this.props.seat.id

        // todo change here 
        // creating url
        //let new_url = formatUrl(this.props.is_subdomain, this.props.PageState.recruiter.route_url, table_id, seat_id, true)
                
        //this.props.history.push(new_url)


        if(this.props.seat.url !== "") {

            window.open(this.props.seat.url, "_blank")

         }


        // If the seat is crawled, and we don't want the applicants on tables, then send to external url
        // if (this.props.seat.url !== "") {

        //     window.open(this.props.seat.url, "_blank")

        // } else {
            
        //     if (user.id !== "") {
            
        //         this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION_TABLE, table_id)
        //         this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION_SEAT, seat_id)
                
        //         let new_url = formatUrl(this.props.is_subdomain, this.props.PageState.recruiter.route_url, table_id, undefined, true)
                
        //         this.props.history.push(new_url)

        //     } else {
            
        //         this.props.toggleAuthModalOpen()
            
        //     }
        // }
    }

    public render() {
        let {
            seat,
            PageState
        } = this.props
        return (
            <div className="seat-item">

                <div className="seat-header">
                    <div className="seat-title">
                        <BrandedHeader as="h2" content={seat.title} PageState={PageState}/>
                    </div>

                    <div className="action-content">
                        {
                            this.props.has_applied ?
                            <div className="applied">
                                <BrandedHeader as="h2" content="APPLIED" colored PageState={PageState}/>
                                <Icon name="check" style={{color:PageState.recruiter.brand.color}}/>
                            </div> :
                            <BrandedButton content="APPLY NOW" PageState={PageState} onClick={this.handleApplyClick}/>
                        }

                    </div>
                </div>

                <div className="seat-description">
                    <RTFDisplay data={seat.description}/>
                </div>

            </div>
        )
    }
}


export default SeatCarousel

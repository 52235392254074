// External Imports
import * as React from 'react'
import { NavLink } from 'react-router-dom';
import Truncate from 'react-truncate'
import {Card, Grid} from "semantic-ui-react"
import hexToRgba from "hex-to-rgba"
import moment from 'moment'

// Local Imports
import { WithRouterProps } from '../../interfaces';
import { PageState, Seat, Table } from '../../GraphQL/types';
import { BrandedText, BrandedHeader, BrandedButton } from '../Branded';

// Style
import "./style.scss"
import { formatUrl } from '../../utils';
// positing on the Landing , Seat Card 
interface LandingPositionProps extends WithRouterProps {
    PageState:PageState,
    seat:Seat,
    toggleShowSeatCarousel:Function,
}

class LandingPosition extends React.Component<LandingPositionProps> {

    state = {
        hovered:false
    }

    setHovered = (hovered:boolean) => () => {
        this.setState({hovered:hovered})
    }

    handleCardClick = () => {
        this.props.toggleShowSeatCarousel()
    }

    handleApplyClick = (e:React.SyntheticEvent) => {
        e.stopPropagation()
        let recruiter = this.props.PageState.recruiter
        
        if (this.props.seat.url !== "") {
            window.open(this.props.seat.url, "_blank")
        } else {
            let new_url = formatUrl(this.props.is_subdomain, recruiter.route_url, this.props.seat.table_id.id, undefined, true)
            
            this.props.history.push(new_url)
        }
    }

    public render() {

        let {
            PageState,
            seat,
        } = this.props
        

        let bg_color = hexToRgba(PageState.recruiter.brand.color, 0.8)
        let recruiter_color = hexToRgba(PageState.recruiter.brand.color, 1)

        let card_style = this.state.hovered ? { boxShadow:`0px 0px 0px 2px ${recruiter_color}`} : {
            boxShadow:"1px 1px 50px 0px rgba(0, 0, 0, 0.05)"
        }

        let seat_moment = seat.end_date && seat.end_date !== "" ? "APPLICATION DEADLINE: "+ moment(seat.end_date).format("MMM. D YYYY") : null

        return (

            <div className="position">
                <Card onClick={this.handleCardClick} 
                    style={card_style}
                    onMouseEnter={this.setHovered(true)}
                    onMouseLeave={this.setHovered(false)}
                    fluid
                >
                    <div className="position-content">

                        <Grid stackable columns={2} container>

                            <Grid.Column>
                                <div className="position-start">
                                    <BrandedHeader as="h4" colored content={seat.title} {...this.props}/>
                                    <BrandedText content={`${seat.table_id.name}`} {...this.props} style={{color:""}}/>
                                </div>
                            </Grid.Column>

                            <Grid.Column floated="right">
                                <div className="position-end">

                                    {
                                        seat_moment && 
                                        <BrandedText style={{marginBottom:"20px"}} colored content={seat_moment} {...this.props}/>
                                    }

                                    <BrandedButton content="APPLY NOW" onClick={this.handleApplyClick} {...this.props}/>
                                

                                </div>
                            </Grid.Column>

                        </Grid>
                    
                    </div>
                </Card>
            </div>
        )
    }
}

export default LandingPosition




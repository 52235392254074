// library imports
import * as React from 'react'
import {Grid, Input, Search, TableRow, Header, Pagination} from "semantic-ui-react"
import {debounce, chunk} from "lodash"
import hexToRgba from "hex-to-rgba"
import queryString from 'query-string';

// local imports
import {ViewTablesProps} from "../"
import {getNumColumns, splitIntoRows} from "../../../utils"
import {TablesAnimation} from "../../../components"
import TableCard from "../TableCard"
import "./style.scss"

// graphql
import {Table} from "../../../GraphQL/types"
import { searchTablesByRecruiter } from '../../../GraphQL/queries';
import {actions} from '../../../GraphQL/local_writes/actions'

function getRandomArbitrary(min:number, max:number) {
    return Math.random() * (max - min) + min;
}

interface SubViewProps extends ViewTablesProps {
    table_rows:Table[][]
}

class TableCards extends React.Component<ViewTablesProps> {

    max_row_size = 3

    state = {
        table_rows:[[]],
        searchQuery:this.props.PageState.searchInput.landing_search_query,
        placeholder_index:0,
        per_page:9,
        current_page:this.props.PageState.searchInput.skip,
        total_pages:0,
        loading:false,
        error:false,
        error_msg:"",
        searchable:false,
    }

    componentDidMount() {
        console.log(this.props);
        let location  =  this.props.location;
        const searchLocation = queryString.parse(location.search);
        console.log("Location Search",searchLocation);
       if(searchLocation.search_query!=null && searchLocation.search_query!=""){

        let data = {
            search_value:searchLocation.search_query,
            skip:this.state.current_page
            
        }
        this.setState({
            searchQuery:searchLocation.search_query
        });
        this.updateCacheValue(actions.SET_LANDING_SEARCH_QUERY,data);
        
        
    
        if(this.props.PageState.searchSeatInput.landing_search_seat_query!=""){
            let data = {
                search_value:this.props.PageState.searchSeatInput.landing_search_seat_query,
                skip:this.state.current_page                
            }
            this.setState({
                searchQuery:this.props.PageState.searchSeatInput.landing_search_seat_query
            });
            this.updateCacheValue(actions.SET_LANDING_SEARCH_QUERY,data);

            let new_url = `${window.location.origin+window.location.pathname}?search_query=${this.props.PageState.searchSeatInput.landing_search_seat_query}&&view=companies`
            history.pushState({}, "", new_url)

            this.fetchTables(this.props.PageState.searchSeatInput.landing_search_seat_query,this.state.current_page);

        }else{
            let new_url = `${window.location.origin+window.location.pathname}?search_query=${searchLocation.search_query}&&view=companies`
            history.pushState({}, "", new_url)

        // try to see we can use props.history
        this.fetchTables(searchLocation.search_query as any,this.state.current_page);

        }

       }else{ 

        console.log("landing search Query",this.props.PageState.searchInput.landing_search_query);

        if(this.props.PageState.searchSeatInput.landing_search_seat_query!=""){
            let data = {
                search_value:this.props.PageState.searchSeatInput.landing_search_seat_query,
                skip:this.state.current_page                
            }
            this.setState({
                searchQuery:this.props.PageState.searchSeatInput.landing_search_seat_query
            });
            this.updateCacheValue(actions.SET_LANDING_SEARCH_QUERY,data);
            let new_url = `${window.location.origin+window.location.pathname}?search_query=${this.props.PageState.searchSeatInput.landing_search_seat_query}&&view=companies`
            history.pushState({}, "", new_url)
            this.fetchTables(this.props.PageState.searchSeatInput.landing_search_seat_query,this.state.current_page);

        }else{
            console.log("here hi");

            if(this.props.PageState.searchInput.landing_search_query!=""){
                let new_url = `${window.location.origin+window.location.pathname}?search_query=${this.props.PageState.searchInput.landing_search_query}&&view=companies`
                history.pushState({}, "", new_url)
               
            }else{
                let new_url = `${window.location.origin+window.location.pathname}?search_query=${""}&&view=companies`
                history.pushState({}, "", new_url)

            }
            this.fetchTables()
           
        }

    }
 

        
    }

    toggleLoading = () => {
        this.setState({loading:!this.state.loading})
    }

    setSearchFocused = (focused:boolean) => () => {
        
    } 

    handleSearchChange = (e:React.SyntheticEvent<HTMLInputElement>) => {
        console.log(e);
        let search_value = e.currentTarget.value
        this.setState({
            error:false,
            searchQuery:search_value,
            current_page:0  // Reset page to 0 on search change
        })

        console.log("Current_Search_Value",search_value);    

        this.debounceFetchTables(search_value, 0)

        let data = {
            search_value:search_value,
            skip:0
            
        }
        this.updateCacheValue(actions.SET_LANDING_SEARCH_QUERY,data);
        
        
        // try to see we can use props.history
        let new_url = `${window.location.origin+window.location.pathname}?search_query=${search_value}&&view=companies`
        history.pushState({}, "", new_url)

    }

    updateCacheValue = async (actions:actions,value:any)=>{

        this.props.PageState.CacheWriter.write_searchInput(actions,value);

    }

    handlePageChange = (event: React.SyntheticEvent, data: any) => {

        console.log("ActivePage",data.activePage)
        this.setState({current_page:data.activePage})
        this.fetchTables(undefined, data.activePage)

        let _data = {
            search_value:this.state.searchQuery,
            skip:data.activePage   
        }
        console.log("got here....")
        this.updateCacheValue(actions.SET_LANDING_SEARCH_QUERY,_data);
    }

    // managing multiple API call as treat as one (waiting 300 milli second )
    debounceFetchTables = debounce((searchQuery?:string, next_page?:number) => {
        this.fetchTables(searchQuery, next_page)
    }, 300)

    fetchTables = async (searchQuery?:string, next_page?:number) => {
        this.toggleLoading()

        let vars = {
            searchQuery:searchQuery ? searchQuery : this.state.searchQuery, // string query to send
            recruiter_id:this.props.PageState.recruiter.id,                 // aggregator recruiter
            perPage:this.state.per_page,                                    // Number of items per page
            skip:next_page ? next_page : this.state.current_page            // Offset into items
        }

        console.log("fetching tables with vars:",vars)
        try {
            let response = await this.props.client.query({
                query:searchTablesByRecruiter,
                variables:vars
            }) as any
    
            console.log("response:",response)
            let tables = response.data.searchTablesByRecruiter.items.filter((table:Table) => {
                if (table.profile_pic === null) {
                    return false
                }
                if (table.archived === true) {
                    return false
                }
                
                return true
            })

            let pageInfo = response.data.searchTablesByRecruiter.pageInfo
            
            let table_rows = splitIntoRows(tables, this.max_row_size)

            this.setState({
                table_rows:table_rows,
                total_pages:(pageInfo.itemCount / this.state.per_page) < 5?pageInfo.itemCount / this.state.per_page:5
            })

        } catch (error) {
            console.log("error:",error)
            this.setState({
                error:true
            })
        }
        
        this.toggleLoading()
    }

    public render() {

        let {
            PageState,
        } = this.props

        let {
            table_rows,

        } = this.state

        let no_results = table_rows[0].length === 0

       // let new_url = `${window.location.origin+window.location.pathname}?search_query=${this.state.searchQuery}`
       // history.pushState({}, "", new_url)

        
        return (
            <div className="table-cards-container">

                <div className="search-input-container">
                    
                    {/* <Search /> */}
                    <Input fluid
                        className="search-input"
                        icon="search"
                        onChange={ this.handleSearchChange }
                        placeholder={"Search Companies, Positions..."}
                        value={this.state.searchQuery}
                    />

                </div>

                <div className="table-cards">
                    {
                        this.state.error &&
                        <ErrorContent/>
                    }

                    {
                        this.state.loading &&
                        <LoadingContent/>
                    }
                    {
                        (!this.state.loading && !this.state.error) &&
                        (no_results ? <NoResults {...this.state} {...this.props}/> : <TableRows {...this.state} {...this.props}/>)
                    }

                </div>

                {
                    this.state.total_pages > 1 &&
                    <div className="pagination-container">
                        <Pagination boundaryRange={0}
                            defaultActivePage={this.state.current_page+1} // skip + 1 (active Page)
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={1}
                            totalPages={this.state.total_pages}
                            onPageChange={this.handlePageChange}
                        />
                    </div>
                }
            </div>
        )
    }
}

class ErrorContent extends React.Component {
    public render() {
        return (
            <div className="error-content">
                <Header as="h3" content="Seems like a network error occurred, please try again..." style={{color:"#f36a6a"}}/>
            </div>
        )
    }
}

class LoadingContent extends React.Component {
    public render() {
        return (
            <div className="tables-loader">
                <TablesAnimation/>
            </div>
        )
    }
}

class NoResults extends React.Component<SubViewProps> {

    public render() {
        return (
            <div className="no-tables-result">
                <Header as="h3" content="Sorry! We didn't find any companies or positions that match your search"/>
            </div>
        )
    }

}

class TableRows extends React.Component<SubViewProps> {

    public render() {
        let {
            table_rows
        } = this.props

        // Use the same number of columns for all rows, 
        // based on how many are in the first row
        let num_columns = getNumColumns(table_rows[0])
        
        return (
            <Grid stackable>
                        
                {
                    table_rows.map((row, index) => {

                        return (
                            <Grid.Row columns={num_columns} key={index}>
                                {
                                    row.map((table:Table, index:number) => {
                                        return (
                                            <Grid.Column key={index}>
                                                <TableCard table={table} {...this.props} num_columns={num_columns}/>
                                            </Grid.Column>
                                        )
                                    })
                                }
                            </Grid.Row>
                        )
                        
                    })
                }
            </Grid>
        )
    }
}

export default TableCards
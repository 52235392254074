import * as React from 'react'
import ReactQuill, {Quill} from 'react-quill'
import "./style.scss"

interface QuillDisplayProps {
    data?:string
}

class QuillDisplay extends React.Component<QuillDisplayProps> {
    modules = {
        toolbar:false
    }

    public render() {
        return (
            <div className="quill-display">
                <ReactQuill value={this.props.data} 
                    readOnly
                    modules={this.modules}
                />
            </div>
        )
    }
}


export default QuillDisplay
import * as React from 'react'
import {WithPageState} from "../"

interface BrandedTextProps extends WithPageState {
    content?:string,
    className?:string,
    style?:React.CSSProperties,
    colored?:boolean,
    spaced?:boolean,
    font?:boolean
}

class BrandedText extends React.Component<BrandedTextProps> {
    public render() {

        let {
            children,
            PageState,
            style,
            className,
            content,
            colored,
            spaced,
            font
        } = this.props

        let font_obj = JSON.parse(PageState.recruiter.brand.font.value as string)

        let text_style = {
            
            fontFamily: font !== false ? font_obj.family : "",
            color: PageState.recruiter.brand.color && colored ? PageState.recruiter.brand.color : "",
            margin: spaced ? "" : "0",
            ...style,
        } as React.CSSProperties


        return (
            <p className={className} style={text_style}>
                {content?content:""}
                {children}
            </p>
        )
    }
}

export default BrandedText
import gql from "graphql-tag"
import ApolloClient from "apollo-client";
import {NormalizedCache} from "apollo-cache-inmemory"
import actions from "./actions"

export {default as actions} from "./actions"

class CacheWriter {

    /**
     * Instance of the local apollo cache for reference
     */
    protected client:ApolloClient<NormalizedCache>

    /**
     * Returns a new cache writer class
     * 
     * @remarks
     * This is essentially remaking the redux state management system using the local apollo cache
     * 
     * @param client - (optional) an apollo client to initialize the writer with
     * 
     * @returns a new CacheWriter object
     */
    constructor(client:ApolloClient<NormalizedCache>) {
        this.client = client
    }

    /**
     * Writes to the local apollo cache
     * Wraps multiple functions, each for writing a particular part of the cache
     * 
     * Parts:
     * 1: User Object
     * 2: Application (file uploads mostly)
     * 3: Recruiter Object (font upload)
     * 
     * Note: if writing a new fn, make sure to catch the default case and return
     * or it might write empty data to the cache!
     * 
     * @param action - Action to take
     * @param data - Data to write 
     * 
     * @returns void
     */
    public async write_cache(action:actions, data:any) {
        await this.write_user(action, data)
        this.write_application(action, data)
        this.write_recruiter(action, data)
    }

    /**
     * Handle writes to the user object, includes writes to localstorage for persistence
     * 
     * @param action - Action to perform on the cache
     * @param data - Data to write
     * 
     * @returns void
     */
    public async write_user(action:actions, data:any) {
        let new_data = {
            data: {} as any
        } as any
        switch (action) {
            case actions.SET_USER:
                new_data.data.user = data
                // this.client.writeData(new_data)
                localStorage.setItem("user",JSON.stringify(data))
                break
            case actions.SET_TOKEN:
                new_data.data.token = data
                localStorage.setItem("token",data)
                break
            default:
                return
        }
        this.client.writeData(new_data)
    }

    /**
     * @remarks
     * Handles writes to the application object, so that it can persist between changes in the url route
     * 
     * @param action - Action to perform on the cache
     * @param data - Data to write
     * 
     * @returns void
     */
    public async write_application(action:actions, data:any) {
        let new_data = {
            data:{
                
            } as any
        }
        switch (action) {
            case actions.SET_APPLICATION:
                new_data.data.application = data
                break
            case actions.SET_APPLICATION_SEAT:
                new_data.data.application = {} as any
                new_data.data.application.selected_seat = data
                break
            
            case actions.SET_APPLICATION_TABLE:
                new_data.data.application = {} as any
                new_data.data.application.selected_table = data
                break
            case actions.SET_APPLICATION_STAGE:
                new_data.data.application = {} as any
                new_data.data.application.stage = data
                break
            default:
                return
        }
        new_data.data.application.__typename = "application"
        this.client.writeData(new_data)
    }

    /**
     * @remarks
     * Handles writes to the recruiter object, but right now only handles font
     * 
     * @param action - Action to perform on the cache
     * @param data - Data to write
     * 
     * @returns void
     */
    public write_recruiter(action:actions, data:any) {
        let new_data = {
            data:{} as any
        }

        switch (action) {
            case actions.SET_FONT:
                new_data.data.font = data
                break
            default:
                return
        }
        this.client.writeData(new_data)
    }


    /**
     * @remarks
     * Handles writes to the recruiter object, but right now only handles font
     * 
     * @param action - Action to perform on the cache
     * @param data - Data to write
     * 
     * @returns void
     */
    public write_searchInput(action:actions,data:any){

        let new_data = {
            data:{
                searchInput:{
                    __typename:"searchInput"
                } as any,
                searchSeatInput:{
                    __typename:"searchSeatInput"
                }as any
            } as any
        }

        switch (action) {


            
            case actions.SET_LANDING_SEARCH_QUERY:
                new_data.data.searchInput.landing_search_query = data.search_value
                new_data.data.searchInput.skip = data.skip
                
                break
            case actions.SET_LANDING_SEARCH_SEAT_QUERY:
                new_data.data.searchSeatInput.landing_search_seat_query = data.search_value
                new_data.data.searchSeatInput.skip = data.skip
                
                break  
            case actions.SET_SEAT_ID:
                new_data.data.seat_id = data
                
                break       
            default:
                return
        }
        this.client.writeData(new_data)
    }


}

export default CacheWriter
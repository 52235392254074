import * as React from 'react'

import {WithRouterProps} from "../../interfaces"

// Parameters 
interface BrowsePageProps extends WithRouterProps {

}


// Page component for browsing tables
class BrowsePage extends React.Component<BrowsePageProps> {
    public render() {
        return (
            <div className="browse-page">

            </div>
        )
    }
}

export default BrowsePage
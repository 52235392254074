export enum APPLICATION_ERRORS {
    NO_TABLE="NO_TABLE",
    NO_SEAT="NO_SEAT",
    NO_FNAME="NO_FNAME",
    NO_LNAME="NO_LNAME",
    NO_PHONE="NO_PHONE",
    NO_COUNTRY="NO_COUNTRY",
    NO_ADDRESS="NO_ADDRESS",
    NO_UNIVERSITY="NO_UNIVERSITY",
    NO_DEGREESTATUS="NO_DEGREESTATUS",
    NO_DEGREE="NO_DEGREE",
    NO_MAJOR="NO_MAJOR",
    NO_COMPANY="NO_COMPANY",
    NO_ROLE="NO_ROLE",
    NO_STARTDATE="NO_STARTDATE",
    NO_ENDDATE="NO_ENDDATE",
    NO_RESUME="NO_RESUME"
}
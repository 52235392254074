// Library imports
import * as React from "react"
import {Mutation, MutationFn} from "react-apollo"
import {Breadcrumb, Header, Modal, Confirm, Divider, Message} from "semantic-ui-react"
import {NavLink} from "react-router-dom"
// @ts-ignore
// import { PDFViewer } from '@react-pdf/renderer';
import moment from "moment"
import ReactGA from "react-ga"

// Local imports
import { ApplyReviewProps } from ".";
import {PDFResume, BrandedHeader, BrandedDivider, DisplayResume, BrandedButton, BrandedLink, FullPageLoader, SuccessAnimation} from "../../components"

// GraphQL
import { applyToTables } from "../../GraphQL/mutations";
import { ApolloError } from "apollo-client";
import { User, Recruiter, _UserType } from "../../GraphQL/types";
import { actions } from "../../GraphQL/local_writes";
import { default_application_obj } from "../../GraphQL/resolvers/defaults";
import AnalyticsItems from "../../analytics";
import { formatUrl } from "../../utils";


const FetchSeatMappings = (recruiter:Recruiter, selected_seats:string[], selected_tables:string[]) => {
    let tables = recruiter.tables.filter(table => {
        return selected_tables.includes(table.id)
    })

    let seat_mappings = tables.map(table => {
        let seats = table.publish_seats.filter(seat => {
            return selected_seats.includes(seat.id)
        })

        return seats.map(seat => {
            return {
                table_id:table.id,
                seat_id:seat.id
            }
        })
    }) as any

    return [].concat(...seat_mappings)
    
}

class ApplicationReview extends React.Component<ApplyReviewProps> {

    state = {
        error:false,
        error_msg:"",
        finished:false
    }

    catchErrors = ():boolean => {
        if (!this.props.PageState.user) {
            return true
        }
        
        if (!this.props.PageState.user.profile_pic) {
            this.setState({
                error:true,
                error_msg:"Please upload a photo"
            })
            return true
        }
        
        return false
    }

    // two services to run
    /**
     * 1. applyToTable
     * $submission_date:String!,
        $table_id:String!,
        $admin_id:String!,
        $seats_id:String!,
        $cover_letter_input:String!,
        $attachments:[fileInput],
        $archived:Boolean!,
        $url:String!
     * 
     * 2. updateUser
     * 
     */
    submitApplication = (mutationFn:MutationFn) => () => {

        let error = this.catchErrors()
        if (error) {
            return
        }

        let user = this.props.user as User

        let recruiter = this.props.PageState.recruiter

        let url = `https://www.tables.work/${recruiter.route_url}`

        let invalid_keys = ["education","experience","id","email_ok"]
        let user_inputs = Object.keys(user).filter(key => {
            return !invalid_keys.includes(key)
        })

        // let seat_mappings = FetchSeatMappings(recruiter, this.props.application.selected_seats, this.props.application.selected_tables)

        let seat_mappings = [{
            table_id:this.props.application.selected_table,
            seat_id:this.props.application.selected_seat
        }]

        // <startbullshit>
        // some bullshit for graphql to not barf, seems super excessive and unecessary
        // really dont like this, but not sure how to make it otherwise
        let user_object = JSON.parse(JSON.stringify(user))
        user_object.type = _UserType.WORKER
        delete user_object.id
        user_object.education = user_object.education.map((education:any) => {
            delete education.__typename
            return education
        })

        user_object.experience = user_object.experience.map((experience:any) => {
            delete experience.company.__typename
            delete experience.__typename
            return experience
        })

        delete user_object.profile_pic.__typename
        delete user_object.__typename

        let attachments = this.props.application.attachments.map((attachment:any) => {
            delete attachment.__typename
            return attachment
        })

        // </endbullshit>

        let variables = {
            // applyToTables
            submission_date:moment().toISOString(),
            user_id:this.props.user.id.toString(),
            seats_id:seat_mappings,
            recruiter_id:this.props.PageState.recruiter.id,
            why:this.props.application.cover_letter_input,
            attachments:attachments,
            archived:false,
            url:url,
            userObject:user_object

        }

        mutationFn({
            variables: variables
        })
    }

    handleApplicationSubmitted = async (data:any) => {

        // Analytics
        ReactGA.event(AnalyticsItems.APPLY)

        let user = data.updateUserObject

        await this.props.PageState.CacheWriter.write_cache(actions.SET_USER, user)
        await this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION, default_application_obj)
        this.setState({
            finished:true
        })
    }

    handleApplicationError = (error:ApolloError) => {
        console.log("application error:",error)
        this.setState({error:true})
    }

    setUserImage = (data:any) => {
        this.props.setPersonal("image")(data.singleUpload)
        this.setState({
            error:false,
            error_msg:""
        })
    }

    handleRedirect = () => {
        let url_params = this.props.match.params as any
        let recruiter_name = url_params.recruiter_name
        let table_id = url_params.table_id
        this.props.PageState.refetchRecruiter()
        let new_url = formatUrl(this.props.is_subdomain, recruiter_name)
        this.props.history.push(new_url)
    }

    public render() {
        let {
            PageState,
            user,
            application
            
        } = this.props
        
        let {
            error
        } = this.state

        return (
            <Mutation mutation={applyToTables}
                onCompleted={this.handleApplicationSubmitted}
                onError={this.handleApplicationError}
            >
                {
                    (applyToTable, {loading}) => {
                        if (loading) return <FullPageLoader/>
                        return (
                            <div className="application-review">
                                
                                <ReviewHeader {...this.state} {...this.props}/>
                                
                                <div className="resume">

                                    <DisplayResume setUserImage={this.setUserImage} {...this.props}/>

                                    {
                                        this.state.error && 
                                        <Message negative>
                                            {
                                                this.state.error_msg === "" ? 
                                                "Something went wrong, please send us an email - help@tables.work"
                                                :
                                                this.state.error_msg
                                            }
                                        </Message>
                                    }
                                    
                                </div>

                                <div className="center-content review-button">
                                    {
                                        this.state.finished &&
                                        <div className="success">
                                            <p className="success-text">Your application has been submitted!</p>
                                            <div className="check-animation">
                                                <SuccessAnimation />
                                            </div>

                                        </div>
                                    }
                                    {
                                        !this.state.finished ? 
                                        <BrandedButton onClick={this.submitApplication(applyToTable)} {...this.props} type="submit">
                                            <Header as="h3" content="SUBMIT APPLICATION" style={{color:"white"}}/>
                                        </BrandedButton>
                                        :
                                        <BrandedButton onClick={this.handleRedirect} {...this.props} type="submit">
                                            <Header as="h3" content="RETURN TO TABLES" style={{color:"white"}}/>
                                        </BrandedButton>
                                    }

                                </div>

                            </div>
                        )
                    }
                }
            </Mutation>

        )
    }
}

interface ReviewHeaderProps extends ApplyReviewProps {
    finished:boolean
}

class ReviewHeader extends React.Component<ReviewHeaderProps> {
    public render() {
        let url_path_params = this.props.match.params as any
        let recruiter_name = url_path_params.recruiter_name
        let table_id = url_path_params.table_id
        return (
            <div>
                <div className="breadcrumb">
                <Breadcrumb>
                    <Breadcrumb.Section><NavLink to={formatUrl(this.props.is_subdomain, recruiter_name)}>Projects</NavLink></Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section><NavLink to={formatUrl(this.props.is_subdomain, recruiter_name, table_id)}>Project Profile</NavLink></Breadcrumb.Section>
                    <Breadcrumb.Divider/>
                    <Breadcrumb.Section><NavLink to={formatUrl(this.props.is_subdomain, recruiter_name, table_id, undefined, true)}> Apply </NavLink></Breadcrumb.Section>
                    <Breadcrumb.Divider/>
                    <Breadcrumb.Section style={{fontWeight:"bold"}}>Review </Breadcrumb.Section>
                </Breadcrumb>
                </div>

                <div className="page-header" style={{marginBottom:"0"}}>
                    <div className="page-header-text">

                        <BrandedHeader as="h1" {...this.props} content="Review your application information"/>
                        
                        <div className="divider">
                            <BrandedDivider {...this.props}/>
                        </div>

                        {
                            this.props.finished &&
                            <div className="success">
                                <p className="success-text">Your application has been submitted!</p>
                                <div className="check-animation">
                                    <SuccessAnimation />
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default ApplicationReview
// Library imports
import * as React from 'react'
import {Modal} from "semantic-ui-react"
import ApolloClient from 'apollo-client';
import { NormalizedCache } from 'apollo-cache-inmemory';

// Local imports
import { WithRouterProps } from '../../interfaces';
import AuthLogin from "./login"
import AuthLoginButtons from "./login_buttons"
import AuthSignup from "./signup"
import AuthForgotPass from "./forgotpass"
import "./style.scss"

// GraphQL
import { PageState, AUTH_FLOW_STAGES, FORGOTPASS_FLOW_STAGES, _UserType } from '../../GraphQL/types';
import { formatUrl } from '../../utils';

// Props for the Authmodal component
export interface AuthModalProps extends WithRouterProps {
    PageState:PageState,                    // PageState
    client:ApolloClient<NormalizedCache>,   // Apollo Client
    open:boolean,                           // Whether the modal is open
    apply:boolean,                          // Whether the modal should push to /apply after authenticating
    table_id?:string,                       // If the modal was opened from a table, table_id
    seat_id?:string,                        // If the modal was opened from a seat, seat_id AND ^ table_id
    toggleOpen:() => void,                  // Function to close/open the modal
    flow_stage?:AUTH_FLOW_STAGES            // Which flow stage the Auth Modal should open to
}

// SubComponent Props under Authmodal
export interface AuthContentProps extends AuthModalProps {
    setFlow:Function,
    handleRedirect:() => void
}

// React Component
class AuthModal extends React.Component<AuthModalProps> {

    state = {
        // Current flow stage for the Auth modal (see:AUTH_FLOW_STAGES)
        flow_stage: this.props.flow_stage ? this.props.flow_stage : AUTH_FLOW_STAGES.SIGNIN 
    }

    // Update current flow stage
    setFlow = (flow:AUTH_FLOW_STAGES) => () => {
        this.setState({flow_stage:flow})
    }

    // Handle where to go after authentication is complete
    handleRedirect = () => {
        // If the modal was clicked from "APPLY", push to apply page
        if (this.props.apply) {
            
            let new_url = formatUrl(this.props.is_subdomain, this.props.PageState.recruiter.route_url, this.props.table_id, this.props.seat_id, true)
            
            this.props.history.push(new_url)
        
            if(this.props.open){this.props.toggleOpen()}

             // Add this line
             this.props.PageState.refetchRecruiter()
        // Otherwise just close the modal
        } else {
            
            this.props.toggleOpen()
        }
    }

    // Render component
    public render() {

        // Fetch props
        let {
            open
        } = this.props
        
        // Initialize component
        let auth_component = null

        // Set auth_component depending on the current stage
        switch(this.state.flow_stage) {
            case AUTH_FLOW_STAGES.LOGIN:
                auth_component = <AuthLoginButtons setFlow={this.setFlow} handleRedirect={this.handleRedirect} {...this.props}/>
                break
            case AUTH_FLOW_STAGES.SIGNIN:
                auth_component = <AuthLogin setFlow={this.setFlow} handleRedirect={this.handleRedirect} {...this.props}/>
                break
            case AUTH_FLOW_STAGES.FORGOTPASSWORD:
                auth_component = <AuthForgotPass setFlow={this.setFlow} handleRedirect={this.handleRedirect} {...this.props}/>
                break
            case AUTH_FLOW_STAGES.SIGNUP:
                auth_component = <AuthSignup setFlow={this.setFlow} handleRedirect={this.handleRedirect} {...this.props}/>
                break
            case AUTH_FLOW_STAGES.SIGNOUT:
                auth_component = <AuthSignout setFlow={this.setFlow} handleRedirect={this.handleRedirect} {...this.props}/>
                break
        }

        // Return the modal wrapped around the current component
        return (
            <div>
                <Modal className="auth-modal"
                    open={open}
                    onClose={this.props.toggleOpen}
                    closeIcon
                >
                    {
                        auth_component
                    }
                    
                </Modal>
            </div>
        )

    }
}

// Never actually used, because we are reloading the window 
class AuthSignout extends React.Component<AuthContentProps> {
    public render() {
        return (
            <div>
                Successfully logged out
            </div>
        )
    }
}


export default AuthModal

// Library imports
import * as React from 'react'
import Dropzone from "react-dropzone"
import { Dimmer, Button, Header, Message, Table } from 'semantic-ui-react';
import {Mutation, MutationFn} from "react-apollo"

// Local imports

import {TablesAnimation, SuccessAnimation} from "../"
import "./style.scss"
import { singleUpload } from '../../GraphQL/mutations';
import { ApolloError } from 'apollo-client';



interface DropFileProps {
    accept:string,
    help_text?:string,
    onUpload:(file:any) => any,
    style?:React.CSSProperties,
    loading?:boolean
    finished:boolean
}

const default_styles = {
    position: "relative",
    width:"100%",
    minHeight:"20px"
} as React.CSSProperties

class DropFileZone extends React.Component<DropFileProps> {

    constructor(props:any) {
        super(props)
    }

    state = {
        dropzoneActive:false,
        error:false,
    }

    onDragLeave = () => {
        this.setState({dropzoneActive:false})
    }

    onDragEnter = () => {
        this.setState({dropzoneActive:true})
    }

    onDrop = (data:any, event:any) => {
        this.setState({dropzoneActive:false})
    }

    onDropAccepted = (mutationFn:MutationFn) => async (data:any) => {

        await mutationFn({
            variables: {
                file:data[0]
            }
        })

        this.setState({
            error:false,
            finished:true
        })
        
    }

    onDropRejected = (data:any) => {
        console.log("drop rejected",data)
        this.setState({error:true})
    }

    onChange = (data:any) => {
        
    }

    onUploadComplete = (data:any) => {
        this.props.onUpload(data)
    }

    onUploadError = (error:ApolloError) => {
        console.log("error",error)
    }

    public render() {
        let styles = default_styles

        if (this.props.style) {
            styles = {
                ...styles,
                ...this.props.style
            }
        }

        let finished = this.props.finished

        return (
            <div className="dropzone">

                
                {
                    !finished ?
                    <Mutation mutation={singleUpload}
                        onCompleted={this.onUploadComplete}
                        onError={this.onUploadError}
                    >
                        {
                            (singleUpload, {loading, error}) => {
                                if (loading) return <div className="dropzone-loading"><Dimmer active={true}><TablesAnimation light/></Dimmer></div>
                                return (
                                    <Dropzone
                                        disableClick
                                        accept={this.props.accept}
                                        onDrop={this.onDrop}
                                        onDragEnter={this.onDragEnter}
                                        onDragLeave={this.onDragLeave}
                                        onDropAccepted={this.onDropAccepted(singleUpload)}
                                        onDropRejected={this.onDropRejected}
                                    >
                                    
                                    {({getRootProps, getInputProps, isDragActive}) => {
                                            return (
                                                <div
                                                    {...getRootProps()}
                                                    className="dropzone-inside"
                                                >
                                                    
                                                    <DropZoneInside isDragActive={isDragActive}/>
                                                    <input id="dropzone-upload-input" {...getInputProps()} />
                                                    
                                                </div>
                                            )
                                    }}
                                    
                                    
                                    </Dropzone>
                                )
                                
                            }
                        }
                    </Mutation> 
                    
                    :
                    <div className="drop-finished">
                        <div className="success">
                            <p>Your File has been uploaded!</p>
                            <div className="checkmark">
                                <SuccessAnimation/>
                            </div>
                            
                        </div>
                        <div className="change">
                            <label htmlFor="change-input">
                                <a className="">Change</a>
                                <input type="file" id="change-input" hidden/> 
                            </label>
                        </div>

                    </div>
                }
                {
                    this.state.error && 
                    <Message negative>
                        <p>Only .pdf or .docx files are allowed, sorry!</p>
                    </Message>
                }



            </div>
        )
    }
}

interface DropZoneInsideProps {
    isDragActive:boolean
}

class DropZoneInside extends React.Component<DropZoneInsideProps> {


    public render() {
        let {
            isDragActive
        } = this.props

        if (isDragActive) {
            return (
                <div className="inside-content drag-active">
                    <Header className="bold">Drop that file right here!</Header>
                    <p className="help-text"></p>
                </div>
            )
        }
        else {
            return (
                <div className="inside-content drag-inactive">

                    <Header className="bold" content="Drag and drop to upload"/>
                    <p>Or <label htmlFor="dropzone-upload-input"><a>browse</a></label> to choose a file.</p>
                </div>
            )
        }
    }
}


export default DropFileZone
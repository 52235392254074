import { _UserType } from "../types";

// for (var i = 0; i < localStorage.length; i++){
//     // do something with localStorage.getItem(localStorage.key(i));
//     let item = localStorage.key(i)
//     if (item) {
//         console.log("local item:",localStorage.getItem(item))
//     }

// }
// localStorage.clear()
const default_user = {
    __typename:"User",
    id:"",
    fname:"",
    lname:"",
    url:"",
    email:"",
    country:"",
    eth_adr:"",
    email_ok:false,
    phone:"",
    type:[],
    esco_skills: [],
    languages: [],
    certificates: [],
    personal_address:"",
    profile_pic: {
        filename:"",
        mimetype:"",
        path:"",
        y_axis:"",
        __typename:"File"
    },
    website:[],
    education:[],
    experience:[]
}

export const default_application_obj = {
    attachments:[],
    cover_letter_input:"",
    selected_seat:"",
    selected_table:"",
    stage:0,
    disability:"",
    finished:false,
    __typename:"application"
}

const getDefaultUser = () => {
    let user_str = localStorage.getItem("user") as string
    // console.log("user str:",user_str)
    let user = JSON.parse(user_str)
    // console.log("user:",user)
    return user ? user : default_user
}

const getDefaultToken = () => {
    let token = localStorage.getItem("token") as string
    return token ? token : ""
}


const getDefultInput = ()=>{
    return {
        landing_search_query :"",
        skip:0,
        __typename:"searchInput",
       
    }
}


const getDefultSeatInput = ()=>{
    return {
        landing_search_seat_query :"",
        skip:0,
        __typename:"searchSeatInput",
       
    }
}


export const defaults = {
    user: getDefaultUser(),
    token:getDefaultToken(),
    application: default_application_obj,
    font:null,
    searchInput:getDefultInput(),
    searchSeatInput:getDefultSeatInput(),
    seat_id:""
}


export default defaults
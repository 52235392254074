// External Imports
import * as React from 'react'
import {FormProps, Modal, Form, Message } from "semantic-ui-react"
import {ApolloConsumer} from "react-apollo"
// @ts-ignore
import {Fade} from "react-reveal"
import ReactGA from "react-ga"

// Local Imports
import {AuthContentProps} from "."
import {FieldLabel, BrandedLink, BrandedButton} from "../../components"

// GraphQL
import {authenticate} from "../../GraphQL/queries"
import {actions} from "../../GraphQL/local_writes"
import {AUTH_FLOW_STAGES} from "../../GraphQL/types"

// Show regular login option
class AuthLogin extends React.Component<AuthContentProps> {

    state = {
        loading:false,      // Whether to show loader
        error:false,        // Whether to show error
        error_msg:"",   // What error message to show
        email_input:"",     // Email user input
        password_input:""   // Password user input
    }

    // Update state on user input
    handleChange = (type:string) => (e:React.SyntheticEvent<HTMLInputElement>) => {
        switch (type) {
            case "email":
                this.setState({email_input:e.currentTarget.value})
                break
            case "password":
                this.setState({password_input:e.currentTarget.value})
                break
        }
    }

    // Handle sending Login api
    handleLogin = (client:any) => async (event: React.FormEvent<HTMLFormElement>, data: FormProps) => {

        this.setState({loading:true})   // Toggle loading
        
        // Exception handling
        try {
    
            // Send login api call
            let result = await client.query({
                query:authenticate,
                variables:{
                    email:this.state.email_input,
                    password:this.state.password_input
                }
            })
    

            console.log(result);
            let user = result.data.authenticate.user

            // Analytics
            ReactGA.set({userId:user.id})
            
            // Update local user
            await this.props.PageState.CacheWriter.write_cache(actions.SET_USER, result.data.authenticate.user)

            // Redirect to next page
            this.props.handleRedirect()

        } catch (err) {
            // Default error message
            let error_msg = "Something went wrong, please try again or reload the page"
            
            // If backend sends message, use their message
            if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                error_msg = err.graphQLErrors[0].message
            }

            this.setState({
                error:true,
                error_msg:error_msg
            })
        }

        this.setState({loading:false})   // Toggle loading false
        

    }

    public render() {
        
        // Fetch brand color
        let bg_color = this.props.PageState.recruiter.brand.color

        return (
            <Fade>
                <div>
                    {/* Header content */}
                    <Modal.Header style={{backgroundColor:bg_color}} >
                        <p className="bold" style={{color:"white"}}>
                            {"Sign in".toUpperCase()}
                        </p>
                    </Modal.Header>
                    
                    {/* Main Content */}
                    <Modal.Content>
                        <ApolloConsumer>
                            {
                                (client) => {
                                    return (
                                        <Form loading={this.state.loading}
                                            error={this.state.error}
                                            onSubmit={this.handleLogin(client)}
                                        >
                                            <Form.Field required>
                                                <FieldLabel content="EMAIL" {...this.props}/>
                                                <input placeholder="john@doe.com" 
                                                    onChange={this.handleChange("email")}
                                                    type="email"
                                                    />
                                            </Form.Field>
                                            <Form.Field required>
                                                <FieldLabel content="PASSWORD" {...this.props}/>
                                                <input placeholder="********" 
                                                    onChange={this.handleChange("password")}
                                                    type="password"
                                                    pattern="[^\x22\x27]{8,}"
                                                    />
                                            </Form.Field>
                                            <p className="help-text">Forgot Password? 
                                                <BrandedLink PageState={this.props.PageState} 
                                                    onClick={this.props.setFlow(AUTH_FLOW_STAGES.FORGOTPASSWORD)}
                                                    content="RESET PASSWORD"
                                                    style={{marginLeft:"5px"}}
                                                />
                                            </p>
                                            <Message error>
                                                {this.state.error_msg}
                                            </Message>

                                            <div className="center-content">
                                                <BrandedButton content="SIGN IN" 
                                                    PageState={this.props.PageState}
                                                    type="submit"
                                                />
                                            </div>
                                        </Form>
                                    )
                            }}
                        </ApolloConsumer>
                    </Modal.Content>
                    <div className="below-modal">
                        <p>Don't have an account? 
                            <BrandedLink PageState={this.props.PageState}
                                onClick={this.props.setFlow(AUTH_FLOW_STAGES.SIGNUP)}
                                content="SIGN UP"
                                style={{marginLeft:"5px"}}
                            />
                        </p>

                        {/* Route to 3rd party oauth choices, NOT IMPLEMENTED (see login_buttons) */}
                        {/* <BrandedLink PageState={this.props.PageState}
                                onClick={this.props.setFlow(AUTH_FLOW_STAGES.LOGIN)}
                                content="USE A DIFFERENT METHOD"
                            /> */}
                    </div>
                    
                </div>
            </Fade>
        )
    }
}

export default AuthLogin
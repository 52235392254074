// library imports
import * as React from 'react'

// local imports
import { WithPageState } from '..';

interface FieldLabelProps extends WithPageState {
    content?:string // The content to display
}

class FieldLabel extends React.Component<FieldLabelProps> {
    public render() {
        
        let {
            PageState,
            content,
            children
        } = this.props

        let color = PageState.recruiter.brand.color

        return (
            <p style={{color:color}}>{content}{children}</p>
        )
    }
}

export default FieldLabel
import * as React from 'react'
import './style.scss'
import {Menu, Container, Image} from 'semantic-ui-react'
import {AuthModal,SignUpModel} from "../../components";

// import {app_styles} from "../../../styles";
import {home_svg} from "../../media/svg"
import GreyTablesLogo from "../../media/img/grey-tables-logo.png"
import {Recruiter, PageState} from "../../GraphQL/types";
import {WithRouterProps} from "../../interfaces"
import { BrandedLink, BrandedText } from '../Branded';
import { formatUrl } from '../../utils';
import queryString from 'query-string';
interface NavProps extends WithRouterProps  {
    PageState:PageState,
    client:any
}

class Nav extends React.Component <NavProps> {

    state = {
        auth_modal_open:false,
        singup_modal_open:false
    }

    toggleAuthModalOpen = () => {
        this.setState({auth_modal_open:!this.state.auth_modal_open});
    }


    toggleSingUpModalOpen = ()=>{
        this.setState({singup_modal_open:!this.state.singup_modal_open});
    }

    handleClickHomePage = () => {
        window.open(this.props.PageState.recruiter.url, "_self")
    }

    handleClickAuth = () => {
        let user = this.props.PageState.user as any
        let authenticated = user.id !== ""
        authenticated ? this.handleSignout() : this.toggleAuthModalOpen()
    }

    handleClickOpenSignUp = () => {
        //let user = this.props.PageState.user as any
        //let authenticated = user.id !== ""
        this.toggleSingUpModalOpen()
    }

    handleSignout = async () => {
        
        localStorage.clear()
        await this.props.client.clearStore()
        await this.props.client.resetStore()
        window.location.reload()
    }

    public render() {
        let {
            PageState,
            children,
            match
        } = this.props
        let user = PageState.user as any
        let params = match.params as any

        let recruiter_name = params.recruiter_name

        let authenticated = user.id !== ""

        //let authbutton_content = authenticated ? "SIGN OUT" : "SIGN IN"

        let authbutton_content = "SIGN UP";
        
        return (
            <div className="app-header">
                <AuthModal PageState={PageState}
                    toggleOpen={this.toggleAuthModalOpen} 
                    open={this.state.auth_modal_open} 
                    apply={false}
                    {...this.props}
                    />

                    <SignUpModel PageState={PageState}
                     toggleOpen={this.toggleSingUpModalOpen} 
                     open={this.state.singup_modal_open} 
                     apply={false}
                    {...this.props}
                    />
                
                {
                    PageState.is_admin &&
                    <Menu style={{marginTop:'0'}}
                        className="admin-navbar"
                        borderless
                    >
                        <Container>
                            <Menu.Item className="grey-logo">
                                <a href="https://app.tables.work/dashboard"
                                    target="_blank"
                                >
                                    <Image src={GreyTablesLogo} alt="tables-admin-logo"/>
                                </a>
                            </Menu.Item>

                            <Menu.Item className="dashboard-item"
                                position="right"
                                // onClick={}
                            >
                                <a href={"https://app.tables.work/dashboard"}
                                    target="_blank"
                                >
                                    <Image src={home_svg} alt="dashboard-home-icon"/>
                                    <p className="dashboard-link">GO TO DASHBOARD</p>
                                </a>
                                {/* <Icon name=""/> */}
                               
                            </Menu.Item>
                        </Container>
                    </Menu>
                }
                <Menu style={{marginTop:'0'}}>
                    <Container>
                        {/* Recruiter */}
                        <Menu.Item onClick={(e) => {
                            //let new_url = formatUrl(this.props.is_subdomain, recruiter_name)
                            // Refetch and Create Handler 
                            let location  =  window.location;
                            const searchLocation = queryString.parse(location.search);
                            console.log("Location Search",searchLocation);
                            let new_url = `${window.location.origin+window.location.pathname}?search_query=${""}&&view=${searchLocation.view}`
                            console.log("newurl called:",new_url)
                            //this.props.history.push(new_url)
                            //history.pushSthref "", new_url)
                            window.location.href = new_url;
                        }}
                            className="recruiter-logo"
                        >
                            <Image src={PageState.recruiter.brand.logo} 
                                style={{width:"auto", maxHeight:"44px"}}
                                alt="Recruiter logo"
                            />

                        </Menu.Item>

                        <Menu floated="right"
                            borderless
                            style={{
                                border:"0",
                                boxShadow:"none"
                            }}
                        >
                            {/* Go to Homepage */}
                            <Menu.Item position="right"
                                onClick={this.handleClickHomePage}
                                style={{
                                    borderLeft:"1px solid rgba(34,36,38,.1)",
                                    borderRight:"1px solid rgba(34,36,38,.1)",
                                    borderRadius:"0"
                                }}
                            >
                                <BrandedText {...this.props} className="bold" colored content="GO TO HOMEPAGE" font={false}/>
                            </Menu.Item>

                            {/* Sign in / Sign out */}
                            {/* <Menu.Item position="right"
                                        onClick={this.handleClickAuth}
                                        style={{borderRight: '1px solid rgba(34,36,38,.1)'}}
                            >
                                <BrandedText {...this.props} className="bold" colored content={authbutton_content}  font={false}/>
                            </Menu.Item> */}

                             {/* <Menu.Item position="right"
                                        onClick={this.handleClickOpenSignUp}
                                        style={{borderRight: '1px solid rgba(34,36,38,.1)'}}
                            >
                                <BrandedText {...this.props} className="bold" colored content={authbutton_content}  font={false}/>
                            </Menu.Item>  */}


                        </Menu>
                        
                    </Container>
                </Menu>

                {
                    children
                }
            </div>
        )
    }
}

export default Nav

export enum actions {
    
    // Recruiter Actions
    SET_FONT = "SET_FONT",

    // User Actions
    SET_USER = "SET_USER",
    SET_TOKEN = "SET_TOKEN",

    // Application actions
    SET_APPLICATION = "SET_APPLICATION",
    SET_APPLICATION_SEAT = "SET_APPLICATION_SEAT",
    SET_APPLICATION_TABLE = "SET_APPLICATION_TABLE",
    SET_APPLICATION_STAGE = "SET_APPLICATION_STAGE",


    // search Query actions
    SET_LANDING_SEARCH_QUERY = "SET_LANDING_SEARCH_QUERY",
    SET_LANDING_SEARCH_SEAT_QUERY = "SET_LANDING_SEARCH_SEAT_QUERY",

    SET_SEAT_ID= "SET_SEAT_ID"

    

}


export default actions

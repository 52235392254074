// External Imports
import * as React from "react"
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

// Local imports

// GraphQL imports
import {User} from "../../GraphQL/types"

interface PDFResumeProps {
    user:User
}

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
})

class PDFResume extends React.Component<PDFResumeProps> {
    public render() {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                
                    <View style={styles.section}>
                        <Text>Section #1</Text>
                    </View>

                    <View style={styles.section}>
                        <Text>Section #2</Text>
                    </View>
                    
                </Page>
            </Document>
        )
    }
}

export default PDFResume
// Library imports
import * as React from 'react'
import {Grid, Image, Button, Container, Statistic, Form, Message} from "semantic-ui-react"
import { Link, Element } from 'react-scroll';
import { Helmet } from 'react-helmet'
import axios from "axios"
// @ts-ignore
import {Fade} from "react-reveal"


// Local imports
import tables_logo_dark_words from "../../media/img/tables_logo.png"
import BetaSvg from "../../media/svg/beta.svg"
import Hero_Image from "../../media/svg/hero-image.svg"
import AgnesLogo from "../../media/img/agnes.png"
import ChronicallyCapableLogo from "../../media/img/chronically-capable.png"
import CreativeCryptoLogo from "../../media/img/creative-crypto.png"
import DeluneLogo from "../../media/img/de-lune.png"
import SetTable from "../../media/svg/set-your-table-image.svg"
import SetSeats from "../../media/svg/seats-image.svg"
import SetPeople from "../../media/svg/grow-image.svg"

    // Features
import PersonalizeImg from "../../media/img/personalize-image-feature.png"
import ShareImg from "../../media/img/share-image-feature.png"
import AnalyticsImg from "../../media/img/analytics-image-feature.png"

import "./style.scss"
import {WithRouterProps} from "../../interfaces" 
import {MobileSidebar} from "../../components"
import { ApolloConsumer } from 'react-apollo';
import { addContactFromClient2Table } from '../../GraphQL/mutations';
import { NavLink } from 'react-router-dom';


interface LandingPageProps extends WithRouterProps {

}

class LandingPage extends React.Component<LandingPageProps> {

    public render() {
        return (
            <div className="landing-page">
                <HelmetComponent/>
                <div className="page-top">
                    {/* Header */}
                    <Fade>

                        <div className="header">
                            <Header {...this.props}/>
                        </div>

                    </Fade>

                    <div className="hero-section">
                        <Grid container stackable >
                            
                            {/* Hero Section */}
                            <LandingSection1 {...this.props}/>

                        </Grid>
                    </div>

                    {/* Logos Section */}
                    <LogoSection/>
                </div>

                <Element name="about">
                    <AboutSection {...this.props}/>
                </Element>

                <Element name="features">
                   <Fade>
                    <FeatureSection {...this.props}/>
                   </Fade>
                </Element>

                <Fade>
                    <SignupSection {...this.props}/>
                </Fade>

                <Element name="contact">
                    <ContactForm {...this.props}/>
                </Element>

                <Fade>
                    <Footer {...this.props}/>
                </Fade>

            </div>
        )
    }
}

// SEO components
class HelmetComponent extends React.Component {
    public render() {
        return (
            <Helmet>
                
                <title>Tables</title>
                <meta name="description" content="Revolutionizing the way companies recruit. Join the only free tool designed specifically for project-based work."/>
                
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://www.tables.work"/>
                <meta property="og:title" content="Revolutionizing the way companies recruit"/>
                <meta property="og:description" content="Attract and onboard better applicants with less effort through one-click sharing tools, project 
                showcasing, and application management"/>
                <meta property="og:image" content=""/>

                <script type="application/ld+json">
                    {`
                        {
                            "@context": "http://schema.org",
                            "@type": "Organization",
                            "url": "https://www.tables.work",
                            "logo": "https://s3.amazonaws.com/agneseventimage/tables_logo_dark.png",
                            "name":"Tables",
                            
                        }
                    `}
                </script>
                <link rel="canonical" href="https://www.tables.work" />

            </Helmet>
        )
    }
}

// Cookie Accepting
class CookieAccept extends React.Component {
    public render() {
        return (
            <div className="cookie-container">
                <div className="cookie-content">
                    This site
                </div>

                <div className="accept-button">

                </div>
            </div>
        )
    }
}


// header component
class Header extends React.Component<LandingPageProps> {
    public render() {
        return (
            <Grid container>
                <Grid.Row columns="equal">

                    {/* Mobile Columns */}
                    <Grid.Column floated="left" only="tablet mobile">
                        {/* Sidebar Component */}
                        <MobileSidebar {...this.props}/>
                    </Grid.Column>

                    <Grid.Column floated="right" only="tablet mobile" className="nav-buttons">
                        <div className="tables-logo">
                            <Image src={tables_logo_dark_words} alt="tables logo dark with words"/>
                            <Image className="beta-img" src={BetaSvg} alt="beta-svg"/>
                        </div>
                    </Grid.Column>


                    {/* Desktop Columns */}
                    <Grid.Column floated="left" only="computer">

                        <div className="tables-logo-container">
                            <div className="tables-logo">
                                <Image className="tables-logo-img" src={tables_logo_dark_words} alt="tables logo dark with words"/>
                                <Image className="beta-img" src={BetaSvg} alt="beta-svg"/>
                            </div>
                        </div>
                        

                    </Grid.Column>

                    <Grid.Column floated="right" only="computer">

                        <div className="nav-buttons">
                            <Link to="about" 
                                className="nav-button"
                                smooth={true}
                                duration={500}
                            >
                                About
                            </Link>
                            <Link to="features" 
                                className="nav-button"
                                smooth={true}
                                duration={500}
                            >
                                Features
                            </Link>
                            <Link to="contact" 
                                className="nav-button"
                                smooth={true}
                                duration={500}
                            >
                                Contact
                            </Link>
                            <a href="https://app.tables.work/auth/login"
                                className="link-text"
                                
                            >
                                Login
                            </a>
                        </div>

                    </Grid.Column>
                
                </Grid.Row>
            </Grid>
        )
    }
}

// section with words and image
class LandingSection1 extends React.Component<LandingPageProps> {
    public render() {
        return (
            <Grid.Row columns={2} className="landing-section-1">
                <Grid.Column>
                    <Fade>
                        <div className="column-text">
                            <div className="left-align">
                                {/* <p className="header-1 bold">
                                    Revolutionizing the way companies recruit
                                </p> */}
                                <h1 style={{fontWeight:900}}>
                                    Revolutionizing the way startups recruit
                                </h1>
                                <p>
                                    Save your time with prefilled-templates, a custom careers page, and our built-in network
                                </p>
                                <a className="ui button app-button" href="https://app.tables.work">
                                    SIGN UP FREE
                                </a>
                            </div>
                        </div>
                    </Fade>
                </Grid.Column>
                <Grid.Column>
                    <Fade>
                        <div className="hero-image">
                            <Image src={Hero_Image} alt="Hero image"/>
                        </div>
                    </Fade>
                </Grid.Column>
            </Grid.Row>
        )
    }
}

class LogoSection extends React.Component {
    public render() {
        let logos = [
            {
                image:AgnesLogo,
                href:"https://agnes.io"
            },
            {
                image:ChronicallyCapableLogo,
                href:"https://wearecapable.org"
            },
            {
                image:CreativeCryptoLogo,
                href:"https://thecreativecrypto.com"
            },
            {
                image:DeluneLogo,
                href:"https://delune.co"
            }
        ]
        return (
            <div className="logo-section">
                <Container>
                    <Fade cascade>
                        <div className="logo-items">
                            {
                                logos.map((logo, index) => {
                                    return (
                                        <LogoItem {...logo} key={index}/> 
                                    )
                                })
                            }
                        </div>
                    </Fade>
                </Container>
            </div>
        )

    }
}

interface LogoItemProps {
    image?:string,
    href:string
}
class LogoItem extends React.Component<LogoItemProps> {
    public render() {
        let {
            image,
            href
        } = this.props
        return (
            <Fade>
                <div className="logo">
                    <a href={href}>
                        <Image src={image} alt="logo item img"/>
                    </a>
                </div>
            </Fade>
        )
    }
}

class AboutSection extends React.Component<LandingPageProps> {

    public render() {

        let statistics = [
            {
                value:"68%",
                label:"of millenials want to make a difference in the world"
            },
            {
                value:"81%",
                label:"said a successful business needs to have a genuine purpose"
            },
            {
                value:"78%",
                label:"said their employer’s values should match their own"
            }
        ]

        return (
            <div className="about-section">
                <Fade cascade>
                    <div className="section-padding">
                        <Grid stackable container>
                            <Grid.Row>
                                <Grid.Column textAlign="center">
                                    <div>
                                        <p className="header-2 bold">Mission Driven Recruitment</p>
                                        {/* <p>
                                        In recent years, study after study has shown how important it is to Millenials that the company they work for
                                        share their values. So why haven’t recruitment tools evolved to better showcase companies in this way? 
                                        Why do we still lead with dry job titles, burying the important work companies are doing in the description, 
                                        if it is even there at all? With Tables, you’re able to lead with your mission, and hire for specific projects - or “Tables” -, 
                                        without sacrificing any of the functionality of a traditional recruitment tool.
                                        </p> */}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            {/* Analytics */}
                            <Grid.Row columns="equal" divided style={{marginTop:"20px"}}>

                                {
                                    statistics.map((statistic, index) => {
                                        return (
                                            <Grid.Column key={index}>
                                                <AppStatistic {...statistic} divider={index !== statistics.length-1}/>
                                            </Grid.Column>
                                        )
                                    })
                                }

                            </Grid.Row>

                        </Grid>
                    </div>
                </Fade>

                <div className="section-padding">
                    <Grid stackable style={{margin:'0'}}>

                        <StepSection
                            reversed={false}
                            img_src={SetTable}
                            header_content="1. Set Your Table"
                            text_content="State the mission of your initiative and make it clear to 
                            applicants how it will change the world."
                        />
                        
                        <StepSection
                            reversed
                            img_src={SetSeats}
                            header_content="2. Add Seats to Your Table"
                            text_content="Use prefilled role templates to attract great talent."
                        />
                        

                        <StepSection
                            reversed={false}
                            img_src={SetPeople}
                            header_content="3. Grow Your Team"
                            text_content="Easily filter candidates, schedule interviews with your favorites,
                            and bring them aboard!"
                        />

                        
                    </Grid>
                </div>


                
            </div>
        )
    }
}

interface StepSectionProps {
    reversed:boolean,
    img_src:string,
    header_content:string,
    text_content:string
}

class StepSection extends React.Component<StepSectionProps> {
    public render() {

        let {
            reversed,
            img_src,
            header_content,
            text_content
        } = this.props
        return (
            <Grid.Row columns="equal">

                <Grid.Column verticalAlign="middle" 
                    className={reversed ? "text-section-col" : ""} 
                    only={reversed ? "computer tablet mobile" : "computer"}
                >
                    <div className="inner-content">
                        <Fade>
                            <StepContent section_type={reversed ? "text" : "image"} mobile={false} {...this.props}/>
                        </Fade>
                    </div>
                </Grid.Column>

                <Grid.Column style={{backgroundColor:"#fff"}}
                    className={reversed ? "" : "text-section-col"}
                >
                    <div className="inner-content">
                        <Fade>
                            <StepContent section_type={reversed ? "image" : "text"} mobile={true} {...this.props}/>
                        </Fade>
                    </div>
                </Grid.Column>
                
                {
                    !reversed && 
                    <Grid.Column verticalAlign="middle" 
                        className={reversed ? "text-section-col" : ""}
                        only="tablet mobile"
                    >
                        <div className="inner-content">
                            <Fade>
                                <StepContent section_type={reversed ? "text":"image"} mobile={true} {...this.props}/>
                            </Fade>
                        </div>
                </Grid.Column>
                }
            </Grid.Row>
        )
    }
}

interface StepContentProps extends StepSectionProps {
    section_type:string,
    mobile:boolean
}

class StepContent extends React.Component<StepContentProps> {
    public render() {
        let {
            section_type,
            header_content,
            text_content,
            img_src,
            reversed
        } = this.props

        let component = null

        if (section_type === "text") {
            return (
                <div className={reversed ? "text-section text-section-right" : "text-section"}>
                    <div>
                        <p className="header-2 bold">{header_content}</p>
                        <p className="wrap-text">{text_content}</p>
                    </div>
                </div>
            )
        } else if (section_type === "image") {
            return (
                <Image src={img_src} style={{margin:"auto"}} alt="step item image"/>
            )
        } 
        return component

    }
}


interface StatisticProps {
    value:String,
    label:String,
    divider:boolean
}

class AppStatistic extends React.Component<StatisticProps> {
    public render() {
        let {
            value,
            label,
            divider
        } = this.props
        return (
            <div className="app-statistic">
                <Statistic>
                    <Statistic.Value>
                        {value}
                    </Statistic.Value>
                    <p className="bold statistic-label">
                        {label}
                    </p>
                    {/* <Statistic.Label>
                        {label}
                    </Statistic.Label> */}
                    
                </Statistic>
            
            </div>
            
        )
    }
}


class FeatureSection extends React.Component<LandingPageProps> {
    public render() {
        let features = [
            {
                name:"Personalize",
                description:"Create a custom branded page that seamlessly integrates with your existing website",
                image:PersonalizeImg
            },
            {
                name:"Share",
                description:"Easily share your tables to your networks and to ours",
                image:ShareImg
            },
            {
                name:"Analyze",
                description:"See real-time table views and manage your applicants",
                image:AnalyticsImg
            }
        ]

        return (
            <div className="section-padding feature-section">
                <Grid container stackable>
                    <Grid.Row>
                        <Grid.Column textAlign="center" style={{backgroundColor:"#fff"}}>
                            <Fade cascade>
                                <div>
                                    <p className="header-2 bold">Powerful Features and Integrations</p>
                                    <p>Attract and onboard better applicants with less effort through one-click sharing tools, project
                                        showcasing, and application management
                                    </p>
                                </div>
                            </Fade>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="equal" className="features">
                        {
                            features.map((feature, index) => {
                                return <Feature {...feature} key={index}/> 
                            })
                        }
                    </Grid.Row>
                    
                </Grid>
            </div>
        )
    }
}

interface FeatureProps {
    name:String,
    description:String,
    image:String
}

class Feature extends React.Component<FeatureProps> {
    public render() {
        let {
            name,
            description,
            image
        } = this.props
        return (
            <Grid.Column className="feature">
                <Fade cascade>
                    <div>
                        <Image src={image} alt="feature item img"/>
                        <div className="feature-content">
                            <p className="bold">{name}</p>
                            <p>{description}</p>
                        </div>
                    </div>
                </Fade>
                    
            </Grid.Column>
        )
    }
}

class SignupSection extends React.Component<LandingPageProps> {
    public render() {
        return (
            <Container fluid className="signup-section section-padding" 
                textAlign="center" style={{backgroundColor:"#f9f9fb", padding: '5vh 1rem', marginLeft:"0"}}
                id="signup-section"
                >
                <Fade>
                    <p className="header-2 bold" style={{wordBreak:"normal"}}>Setting up your account is free and takes less than 2 minutes</p>
                    <div>
                        <p className="italic">“Tables’ social sharing tools shaved hours off the time it used to take us to recruit. I would recommend it to anyone!”
                        </p>
                        <p>- Hannah Olson, Co-founder of Chronically Capable</p>
                    </div>
                    <a className="ui button app-button" style={{marginTop:"20px"}} href="https://app.tables.work">
                        SIGN UP
                    </a>
                </Fade>
            </Container>
        )
    }
}

class ContactForm extends React.Component<LandingPageProps> {
    state = {
        name:"",
        email:"",
        message:"",
        success:false,
        error:false,
        loading:false
    }

    updateField = (field:string) => (e:any) => {
        switch (field) {
            case "name":
                this.setState({name:e.currentTarget.value})
                break
            case "email":
                this.setState({email:e.currentTarget.value})
                break
            case "message":
                this.setState({message:e.currentTarget.value})
                break
        }
    }

    toggleLoading = () => {
        this.setState({loading:!this.state.loading})
    }

    resetState = () => {
        this.setState({
            name:"",
            email:"",
            message:""
        })
    }

    handleSubmit = (client:any) => async () => {

        this.toggleLoading()

        try {
            let result = await client.mutate({
                mutation:addContactFromClient2Table,
                variables: {
                    email:this.state.email,
                    fullName:this.state.name,
                    message:this.state.message
                }
            })

            this.setState({success:true})
            this.toggleLoading()
            this.resetState()
        } catch (err) {
            this.setState({error:true})
            this.toggleLoading()
            this.resetState()
        }
        
    }

    public render() {
        return (
            <Container className="contact-section">
                 <p className="header-2 bold">Interested in More Information?</p>
                <div className="contact-form-container">

                    <ApolloConsumer>
                        {
                            client => {
                                return (
                                    <Form 
                                        onSubmit={this.handleSubmit(client)}
                                        success={this.state.success}
                                        error={this.state.error}
                                        loading={this.state.loading}
                                    >
                                        <Form.Input fluid 
                                            label="FULL NAME"
                                            required
                                            placeholder="John Doe"
                                            onChange={this.updateField("name")}
                                            type="text"
                                            name="name"
                                            value={this.state.name}
                                            
                                        />
                                        <Form.Input fluid
                                            label="EMAIL"
                                            required
                                            placeholder="johndoe@email.com"
                                            onChange={this.updateField("email")}
                                            type="email"
                                            name="_replyto"
                                            value={this.state.email}
                                        />
                                        <Form.TextArea
                                            label="MESSAGE"
                                            required
                                            placeholder="Please let us know what you think!"
                                            onChange={this.updateField("message")}
                                            type="text"
                                            name="message"
                                            value={this.state.message}
                                        />

                                        <div className="submit-container">
                                            <Button type="submit" className="app-button" content="SEND MESSAGE"/>
                                        </div>

                                        <Message success style={{textAlign:"center"}}>
                                            Thanks! We will get back to you as soon as possible!
                                        </Message>

                                        <Message error className="center-content">
                                            Something went wrong, try reloading the page or just send us an email at help@tables.work
                                        </Message>
                                        
                                    </Form>
                                )
                            }
                        }
                    </ApolloConsumer>
                </div>
            </Container>
        )
    }
}

class Footer extends React.Component<LandingPageProps> {
    public render() {
        return (
            <div className="section-padding">
                <Grid stackable container className="footer-section">
                    <Grid.Row columns="equal">
                        <Grid.Column verticalAlign="middle">
                            <div className="tables-logo">
                                <Image src={tables_logo_dark_words} alt="tables logo dark with words"/>
                            </div>
                            
                        </Grid.Column>
                        
                        <Grid.Column verticalAlign="middle" textAlign="center">
                            <div>
                                
                                <Link to="about" 
                                        className="nav-button"
                                        smooth={true}
                                        duration={500}
                                    >
                                    About
                                </Link>
                            </div>
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle" textAlign="center">
                            <div>
                                <Link to="contact" 
                                            className="nav-button"
                                            smooth={true}
                                            duration={500}
                                        >
                                        Contact
                                </Link>
                            </div>
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle" textAlign="center">
                            <a href="https://app.termly.io/document/terms-of-use-for-website/13103719-0f5d-40db-ae3d-8ff61debbac2" className="normal-text">Terms of Use</a>
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle" textAlign="center">
                            <a href="https://app.termly.io/document/privacy-policy/23b4f8fd-d23f-48ee-9d04-3d2b2686aa42" className="normal-text">Privacy Policy</a>
                        </Grid.Column>
                    </Grid.Row>
                    {/* <Divider vertical/> */}
                </Grid>
            </div>
        )
    }
}



export default LandingPage
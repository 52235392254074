// library imports
import * as React from 'react'
import hexToRGBA from "hex-to-rgba"

// local imports
import { WithPageState } from '..';


interface BrandedLinkProps extends WithPageState {
    href?:string,               // Anchor
    onClick:any,                // Onclick handler
    style?:React.CSSProperties  // Extra styles
    content?:string             // The content of the link
}

class BrandedLink extends React.Component<BrandedLinkProps> {

    state = {
        color:"",
        faded_color:"",
        hovered:false
    }

    toggleHovered = () => {
        this.setState({hovered:!this.state.hovered})
    }

    componentDidMount () {

        let {
            PageState,
        } = this.props

        let color = hexToRGBA(PageState.recruiter.brand.color)
        let faded_color = hexToRGBA(PageState.recruiter.brand.color, 0.5)

        this.setState({
            color:color,
            faded_color:faded_color,
        })

    }

    public render() {
        let {
            PageState,
            href,
            onClick,
            content,
            children,
            style
        } = this.props

        let {
            color,
            faded_color
        } = this.state

        let link_style = {
            color: this.state.hovered ? faded_color : color,
            cursor:"pointer",
            fontWeight:"bold",
            ...style
        } as React.CSSProperties

        return (
            <a href={href}
                onClick={onClick}
                style={link_style}
                onMouseEnter={this.toggleHovered}
                onMouseLeave={this.toggleHovered}
            >
                {content}
                {children}
            </a>
        )
    }
}

export default BrandedLink
// library imports
import * as React from 'react'
import {debounce} from "lodash"
import {Input, Header, Pagination} from "semantic-ui-react"
import queryString from 'query-string';

// local imports
import {ViewTablesProps} from ".."
import {sortByKey} from "../../../utils"
import SeatCarousel from "../../SeatCarousel"
import {LandingPosition, TablesAnimation} from "../../../components"
import "./style.scss"

// graphql
import {Table, Seat} from "../../../GraphQL/types"
import { searchSeatsByRecruiter } from '../../../GraphQL/queries';
import {actions} from '../../../GraphQL/local_writes/actions'

interface SeatCardListProps extends ViewTablesProps {
    seats:Seat[],
    showSeatCarousel:Function
}

class SeatList extends React.Component<ViewTablesProps> {

    state = {
        active_seat:"",
        show_seat_carousel:false,
        seats:[],
        search_query:this.props.PageState.searchSeatInput.landing_search_seat_query,
        per_page:5,
        current_page:this.props.PageState.searchSeatInput.skip,
        loading:false,
        error:false,
        error_msg:"",
        total_pages:0
    }

  async  componentDidMount() {
        
        console.log("seatList",this.props.PageState.searchInput.landing_search_query);

        console.log(this.props);

        let location  =  this.props.location;

        console.log("location",location)
        const searchLocation = queryString.parse(location.search);
        console.log("Location Search",searchLocation);

        console.log(searchLocation.id);
        


       if(searchLocation.search_query!=null && searchLocation.search_query!=""){

        let data = {
            search_value:this.props.PageState.searchInput.landing_search_query,
            skip:this.state.current_page
            
        }
        this.setState({
            search_query:searchLocation.search_query
        });
        this.updateCacheValue(actions.SET_LANDING_SEARCH_SEAT_QUERY,data);
        
        if(this.props.PageState.searchInput.landing_search_query!=""){

            let data = {
                search_value:searchLocation.search_query,
                skip:this.state.current_page
                
            }
            this.setState({
                search_query:searchLocation.search_query
            });
            this.updateCacheValue(actions.SET_LANDING_SEARCH_SEAT_QUERY,data);

            let new_url = `${window.location.origin+window.location.pathname}?search_query=${this.props.PageState.searchInput.landing_search_query}&&view=positions`
            history.pushState({}, "", new_url)
           await this.fetchSeats(this.props.PageState.searchInput.landing_search_query,this.state.current_page);
           if(searchLocation.id){
            this.setState({
                show_seat_carousel:true,
                active_seat:searchLocation.id
            });
    
           }else{
            this.setState({
                show_seat_carousel:false,
                active_seat:""
            })
    
           } 
        }else{
            let new_url = `${window.location.origin+window.location.pathname}?search_query=${searchLocation.search_query}&&view=positions`
            history.pushState({}, "", new_url)
            await this.fetchSeats(searchLocation.search_query as any,this.state.current_page);
            if(searchLocation.id){
                this.setState({
                    show_seat_carousel:true,
                    active_seat:searchLocation.id
                });
        
               }else{
                this.setState({
                    show_seat_carousel:false,
                    active_seat:""
                })
        
               } 
        }
        
        
       }else{

        if(this.props.PageState.searchInput.landing_search_query!=""){
            let data = {
                search_value:this.props.PageState.searchInput.landing_search_query,
                skip:this.state.current_page
                
            }

            this.setState({
                search_query:this.props.PageState.searchInput.landing_search_query
            });
            this.updateCacheValue(actions.SET_LANDING_SEARCH_SEAT_QUERY,data);
            let new_url = `${window.location.origin+window.location.pathname}?search_query=${this.props.PageState.searchInput.landing_search_query}&&view=positions`
            history.pushState({}, "", new_url)
           await this.fetchSeats(this.props.PageState.searchInput.landing_search_query,this.state.current_page);
           if(searchLocation.id){
            this.setState({
                show_seat_carousel:true,
                active_seat:searchLocation.id
            });
    
           }else{
            this.setState({
                show_seat_carousel:false,
                active_seat:""
            })
    
           } 
        }else{

            let new_url = `${window.location.origin+window.location.pathname}?search_query=${""}&&view=positions`
            history.pushState({}, "", new_url)
            await this.fetchSeats()
            if(searchLocation.id){
                this.setState({
                    show_seat_carousel:true,
                    active_seat:searchLocation.id
                });
        
               }else{
                this.setState({
                    show_seat_carousel:false,
                    active_seat:""
                })
        
               } 
        }

    }

    }

    toggleLoading = () => {
        this.setState({loading:!this.state.loading})
    }

    showSeatCarousel = (seat_id:string) => () => {
        this.setState({
            show_seat_carousel:true,
            active_seat:seat_id
        })
    }

    hideSeatCarousel = () => {

        this.setState({
            show_seat_carousel:false
        })
    }

    handleSearchChange = (e:React.SyntheticEvent<HTMLInputElement>) => {
        let search_value = e.currentTarget.value
        this.setState({
            search_query:search_value,
            current_page:0
        })
        this.debounceFetchSeats(search_value, 0)
        let data = {
            search_value:search_value,
            skip:0   
        }
        this.updateCacheValue(actions.SET_LANDING_SEARCH_SEAT_QUERY,data);
        let new_url = `${window.location.origin+window.location.pathname}?search_query=${search_value}&&view=positions`
        history.pushState({}, "", new_url)
    }

    updateCacheValue = async (actions:actions,value:any)=>{

        this.props.PageState.CacheWriter.write_searchInput(actions,value);

       
        

    }

    handlePageChange = (event:React.SyntheticEvent, data:any) => {
        let new_page = data.activePage
        this.setState({current_page:new_page})
        this.fetchSeats(undefined, new_page)
        let _data = {
            search_value:this.state.search_query,
            skip:data.activePage
        }
        this.updateCacheValue(actions.SET_LANDING_SEARCH_SEAT_QUERY,_data);
    }

    debounceFetchSeats = debounce((search_query?:string, page?:number) => {
        this.fetchSeats(search_query)
    }, 300)

    fetchSeats = async (search_query?:string, page?:number) => {

        this.toggleLoading()

        let vars = {
            recruiter_id:this.props.PageState.recruiter.id,
            searchQuery:search_query ? search_query : this.state.search_query,
            perPage:this.state.per_page,
            skip:page ? page : this.state.current_page
        }

        console.log("fetching seats with vars:",vars)
        let response = await this.props.client.query({
            query:searchSeatsByRecruiter,
            variables: vars
        }) as any


        let seats = response.data.searchSeatsByRecruiter.items
        let pageInfo = response.data.searchSeatsByRecruiter.pageInfo

        this.setState({
            seats:seats,
            total_pages:pageInfo.itemCount / this.state.per_page
        })

        this.toggleLoading()

        console.log("response:",response)
    }

    public render() {

        let {
            PageState
        } = this.props
        let {
            seats
        } = this.state

        console.log("active_Seat",this.state.active_seat);
   
    

        console.log(this.props);
        return (
            <div className="table-positions">
                
                {
                    this.state.show_seat_carousel &&
                    <SeatCarousel seats={seats}
                        toggleClosed={this.hideSeatCarousel}
                        initial_seat={this.state.active_seat}
                        {...this.props}
                    />
                }

                <div className="search-input-container">
                    
                    {/* <Search /> */}
                    <Input fluid
                        className="search-input"
                        icon="search"
                        onChange={this.handleSearchChange}
                        placeholder={"Search Companies, positions..."}
                        value={this.state.search_query}
                    />

                </div>

                <div className="seat-cards">
                    {
                        this.state.loading &&
                        <LoadingContent/>
                    }

                    {
                        this.state.error &&
                        <ErrorContent/>
                    }

                    {
                        
                        (!this.state.loading && !this.state.error) && seats.length > 0 &&
                        <SeatCardList {...this.props}
                            {...this.state}
                            showSeatCarousel={this.showSeatCarousel}
                            seats={seats}
                        />
                    }

                    {
                        seats.length === 0 && !this.state.loading && !this.state.error &&
                        <NoResults/>
                    }
                </div>

                <div>
                    {
                        this.state.total_pages > 1 &&
                        <div className="pagination-container">
                            <Pagination boundaryRange={0}
                                defaultActivePage={this.state.current_page+1}
                                ellipsisItem={null}
                                firstItem={null}
                                lastItem={null}
                                siblingRange={1}
                                totalPages={this.state.total_pages}
                                onPageChange={this.handlePageChange}
                            />
                        </div>
                    }
                </div>
                
                
            </div>
        )
    }
}

class ErrorContent extends React.Component {
    public render() {
        return (
            <div className="error-content">
                <Header as="h3" content="Seems like a network error occurred, please try again..." style={{color:"#f36a6a"}}/>
            </div>
        )
    }
}

class LoadingContent extends React.Component {
    public render() {
        return (
            <div className="tables-loader">
                <TablesAnimation/>
            </div>
        )
    }
}

class NoResults extends React.Component {

    public render() {
        return (
            <div className="no-tables-result">
                <Header as="h3" content="Sorry! We didn't find any positions that match your search"/>
            </div>
        )
    }

}

class SeatCardList extends React.Component<SeatCardListProps> {
    
    public render() {
        let {
            seats
        } = this.props

        return (
            <div className="seat-cards">
                {
                    seats.map((seat,index) => {
                        return <LandingPosition key={seat.id}
                                    seat={seat}
                                    {...this.props}
                                    toggleShowSeatCarousel={this.props.showSeatCarousel(seat.id)}
                                    />
                    })
                }
            </div>
        )
    }
}

export default SeatList
// Library imports
import * as React from 'react'
import { Query } from "react-apollo"
import WebFont from 'webfontloader'
import { Switch, Route, match, Redirect} from "react-router-dom"
import {Helmet} from "react-helmet"
// @ts-ignore
import Favicon from 'react-favicon'
// @ts-ignore
import branch from "branch-sdk"
import ReactGA from "react-ga"
// @ts-ignore
import { FullStoryAPI } from 'react-fullstory'
// @ts-ignore
import parser from "tld-extract"
import ApolloClient from 'apollo-client'
import { NormalizedCache } from 'apollo-cache-inmemory';
// @ts-ignore
import CookieConsent from "react-cookie-consent";
// Local imports
import {Nav, FullPageLoader, AuthModal, Footer, FullPageError} from "../../components"
import {WithRouterProps} from "../../interfaces"
import ViewTables from "../ViewTables"
import Table from "../Table"
import Seat from '../Seat'
import Apply from '../Apply'
import {isEmptyObject, fetchSubDomainRouteUrl, formatPath} from "../../utils"

// GraphQL
import {recruiterQuery, localQuery, domainRecruiterQuery} from "../../GraphQL/queries"
import CacheWriter, {actions} from '../../GraphQL/local_writes';
import { Recruiter, PageState } from '../../GraphQL/types';


interface RecruiterPageProps extends WithRouterProps {

}

export interface WithRecruiterProps extends RecruiterPageProps {
    recruiter:Recruiter,
    refetch:any,
    font:any,
    client:ApolloClient<NormalizedCache>,
}

export interface WithPageStateProps extends WithRecruiterProps {
    PageState:PageState
}

class RecruiterPage extends React.Component<RecruiterPageProps> {

    state = {
        redirectTableId:"", // for redirecting after authentication (check AuthModal)
    }

    getRecruiterRouteUrl = () => {
        let {
            match,
            is_subdomain
        } = this.props
        
        if (is_subdomain) {
            return fetchSubDomainRouteUrl()
        } else {
            let params = this.props.match.params as any
            return params.recruiter_name
        }
    }

    public componentDidMount () {
        let user = JSON.parse(localStorage.getItem("user") as string)
        let token = localStorage.getItem("token")
        this.setState({
            user:user,
            token:token
        })

        // Branch.io
        branch.init("key_live_ceSkVvxLHkcs8RAILJLjcbljBrloySLc",{}, this.handleBranchLinking)
    }

    handleBranchLinking = (err:any, data:any) => {
        console.log("branch init!",err,data)
    }

    public render() {

        const {
            match
        } = this.props

        let recruiter_name = this.getRecruiterRouteUrl()

        // Queries depend on whether it is a custom subdomain or not
        let query = this.props.is_subdomain ? domainRecruiterQuery : recruiterQuery
        let query_name = this.props.is_subdomain ? "findRecruitersByRegex" : "findRecruitersByEntity"

        return (
            <Query
                query={query}
                // pollInterval={500}
                variables={{
                    recruiter_name:recruiter_name
                }}
                notifyOnNetworkStatusChange
                fetchPolicy="network-only"
            >
                {({ loading, error, data, refetch, client }) => {
                    if (loading) return <FullPageLoader/>
                    if (error) {
                        console.log("error!",error)
                        return <FullPageError {...this.props}/>
                    }
                    console.log("recruiterQuery:",data, query_name)
                    let result = data[query_name]
                    
                    // If no recruiters returned, redirect to 404
                    if (!result || result.length === 0) {
                        return <Redirect to="/404"/>
                    }

                    let recruiter = result[0]

                    let font = data.font
                    
                    return <PageStateQuery client={client} recruiter={recruiter} font={font} refetch={refetch} {...this.props}/>
                }}
            </Query>
        )
    }
}

class PageStateQuery extends React.Component<WithRecruiterProps> {

    state = {
        auth_modal_open:false,
    }

    CacheWriter:CacheWriter | null = null

    toggleAuthModalOpen = (apply?:boolean) => {
        if (apply) {
            this.setState({redirectToApply:apply})
        }
        
        this.setState({auth_modal_open:!this.state.auth_modal_open})
    }

    loadFont = (font_jsonstr:string) => {
        let font = JSON.parse(font_jsonstr)

        WebFont.load({
            google: {
                families: [`${font.family}:100,200,300,400,500,600,700,800,900`]
            }
        })
        if (this.CacheWriter) {
            this.CacheWriter.write_cache(actions.SET_FONT, font.family)
            // this.props.refetch()
        }
        
        // this.setState({web_font_loaded:true})
    }

    public render() {
        let {
            recruiter,
            font
        } = this.props

        return (
            <Query query={localQuery}>

                {
                    ({error, loading, data,client}) => {
                        console.log(data);
                        if (loading) return null
                        if (error) return <FullPageError {...this.props}/>
                        
                        if (isEmptyObject(data)) {
                            
                            console.log("Error pulling local data, resetting...")
                            localStorage.clear()
                            client.clearStore()
                            window.location.reload()
                        }
                        
                        // Initialize local CacheWriter
                        this.CacheWriter = new CacheWriter(client)

                        // Set Analytics users
                        if (data.user.id !== "") {
                            ReactGA.set({userId:data.user.id})
                            
                            FullStoryAPI('identify', data.user.id, data.user)
                        }
                        
                        // Check whether the user is the admin of this recruiter
                        let is_admin = recruiter.admins.find(user => {
                            return user.id === data.user.id
                        }) !== undefined

                        // Initialize pagestate
                        let PageState = {
                            recruiter:recruiter,
                            user:data.user,
                            token:data.token,
                            toggleAuthModal:this.toggleAuthModalOpen,
                            CacheWriter:this.CacheWriter,
                            refetchRecruiter:this.props.refetch,
                            is_admin:is_admin,
                            searchInput:data.searchInput,
                            searchSeatInput:data.searchSeatInput,
                            seat_id:data.seat_id
                        }

                        // Check whether we have loaded the recruiter's font
                        if (!font && recruiter.brand && recruiter.brand.font.value) {
                            console.log("loading font, rerendering...")
                            this.loadFont(recruiter.brand.font.value)
                        }

                        return (
                            <div className="page">

                                <HelmetComponent recruiter={recruiter}/>
                            
                                <Nav {...this.props} PageState={PageState} client={client}/>

                                <Switch>

                                    <Route path={formatPath(this.props.is_subdomain, true, true, true)} render={(props) => <Apply {...this.props} {...props} PageState={PageState}/>}/>
                                    <Route path={formatPath(this.props.is_subdomain, true)} render={(props) => <Table {...this.props} {...props} PageState={PageState}/>} />
                                    <Route path={formatPath(this.props.is_subdomain, true,true)} render={(props) => <Seat {...this.props} {...props} PageState={PageState}/>} />
                                    <Route path={formatPath(this.props.is_subdomain)} render={(props) => <ViewTables {...this.props} {...props} PageState={PageState}/>}/>

                                </Switch>
                                <CookieConsent
                                  buttonStyle={{ fontWeight: "bold",display:"flex",backgroundColor:this.props.recruiter.brand.color,color:"white"}}
                                  style={{ background: "#2B373B", display:"flex",justifyContent:"flex-end",flexDirection:"row-reverse"}}
                                  contentStyle = {{
                                    display: "flex",
                                    flex: "initial"
                                
                                  } } 
               >  
              This website uses cookies to enhance the user experience.{" "}
    
</CookieConsent>
                                <Footer/>

                            </div>
                        )
                    }
                }
            </Query>
        )
        
    }
}

interface HelmetProps {
    recruiter:Recruiter
}

class HelmetComponent extends React.Component<HelmetProps> {
    public render() {

        let {
            recruiter
        } = this.props
        return (
            <div>
                <Helmet>
                    <title>{recruiter.name}</title>
                    <meta name="descripton" content={recruiter.brand.header}/>
                </Helmet>
                <Favicon url={recruiter.brand.favicon}/>
            </div>
        )
    }
}

export default RecruiterPage
import gql from "graphql-tag"
import * as fragments from "../fragments"

export const updateUserPassword = gql`
    query updateUserPassword (
        $email:String,
        $password:String
    ) {
        updateUserPassword (
            email:$email,
            password:$password
        ) {
            user {
                id
            }
        }
    }
`

export const sendSecurityCode = gql`
    query sendSecurityCode (
        $email:String!
    ) {
        securityCode(
            email:$email
        )
    }
`

export const verifySecurityCode = gql`
    query verifySecurityCode(
        $email:String!,
        $security_code:String!
        ) 
        {
            verifySecurityCode (
                email:$email,
                security_code:$security_code
            )
        }
    
`

export const recruiterQuery = gql`
    ${fragments.FullRecruiter}
    query recruiterQuery(
        $recruiter_name:String!
        ) 
        {
        findRecruitersByEntity(input:"route_url", value:$recruiter_name) {
            ...FullRecruiter
        }

        # font @client
    }
`

export const domainRecruiterQuery = gql`
    ${fragments.FullRecruiter}
    query domainRecruiterQuery (
        $recruiter_name:String!
    )
    {
        findRecruitersByRegex(value:$recruiter_name) {
            ...FullRecruiter
        }

        font @client
    }
`

export const localQuery = gql`
    ${fragments.FullUserLocal}
    query localQuery {
        user @client {
            ...FullUserLocal
        }
        searchInput @client{
            landing_search_query,
            skip
        }
        searchSeatInput @client{
            landing_search_seat_query,
            skip
        }
        token @client,
        seat_id @client
    }
`

export const tableQuery = gql`
    ${fragments.FullTable}
    query tableQuery (
        $table_id:String!
    )
    {
        findPublishedTablesByEntity(input:"_id", value:$table_id) {
            ...FullTable
        }

        findDraftTablesByEntity(input:"_id", value:$table_id) {
            ...FullTable
        }
    }
`

export const seatQuery = gql`
    ${fragments.FullTable}
    query seatQuery (
        $seat_id:String!,
        $table_id:String!
    )
    {
        findPublishedSeatsByEntity(input:"_id", value:$seat_id) {
            ...FullSeat
        },
        findDraftTablesByEntity(input:"_id", value:$table_id) {
            ...FullTable
        },
        findPublishedTablesByEntity(input:"_id", value:$table_id) {
            ...FullTable
        }
    }
`

export const applicationQuery = gql`
    ${fragments.FullAttachment}
    # ${fragments.FullTable}
    query applicationQuery($table_id:String!) {
        application @client {
            attachments {
                ...FullAttachment
            },
            cover_letter_input,
            selected_table,
            selected_seat,
            stage,
            disability,
            finished

        }

    }
`

export const authenticate = gql`
    ${fragments.FullUser}
    query authenticate ($email:String!, $password:String!) {
        authenticate (email:$email, password:$password) {
            token,
            user {
                ...FullUser
                isRecruiter(email:$email)
            }
        }
    }
    
`

export const getSchoolList = gql`
    query getSchoolList($query:String!) {
        getSchoolList(query:$query) {
            name
        }
    }
`

export const getLogoClearBit = gql`
    query getLogoClearBit($query:String!) {
        getLogoClearBit(query:$query) {
            key,
            value,
            text,
            image
        }
    }
`

export const getPositionList = gql`
    query getPositionList($query:String!) {
        getPositionList(query:$query)
    }
`

export const getSkillList = gql`
    query getSkillList($query:String!) {
        getSkillList(query:$query)
    }
`

export const searchTablesByRecruiter = gql`

    ${fragments.FullTable}
    ${fragments.pageInfo}

    query searchTablesByRecruiter(
        $recruiter_id:String!,
        $searchQuery:String!,
        $perPage:Float,
        $skip:Float
    ) {
        searchTablesByRecruiter(
            recruiter_id:$recruiter_id,
            searchQuery:$searchQuery,
            perPage:$perPage,
            skip:$skip
        ) {
            count,
            items {
                ...FullTable
            },
            pageInfo {
                ...FullPageInfo
            }
        }
    }
`

export const searchSeatsByRecruiter = gql`
    ${fragments.FullSeat}
    ${fragments.pageInfo}
    query searchSeatsByRecruiter (
        $recruiter_id: String!
        $searchQuery: String!
        $perPage: Float
        $skip: Float
    ) {
        searchSeatsByRecruiter (
            recruiter_id:$recruiter_id,
            searchQuery:$searchQuery,
            perPage:$perPage,
            skip:$skip
        ) {
            count,
            items {
                ...FullSeat
            },
            pageInfo {
                ...FullPageInfo
            }
        }
    }
`

export const searchSeatsByTable = gql`
    
    ${fragments.FullSeat}
    ${fragments.pageInfo}
    query searchSeatsByTable(
        $table_id: String!
        $searchQuery: String!
        $perPage: Float
        $skip: Float
    ) {
        searchSeatsByTable (
            table_id:$table_id,
            searchQuery:$searchQuery,
            perPage:$perPage,
            skip:$skip
        ) {
            count,
            items {
                ...FullSeat
            },
            pageInfo {
                ...FullPageInfo
            }
        }
    }
`

export const searchRecruiterData = gql`
    
    query searchRecruiterData (
        $recruiter_id: String!
        $searchQuery:String!
        $perPage: Float
        $skip: Float
    ) {
        
        searchSeatsByRecruiter (
            recruiter_id:$recruiter_id,
            searchQuery:$searchQuery,
            perPage:$perPage,
            skip:$skip            
        ) {
            items {
                id
            }
        }
        
        searchTablesByRecruiter (
            recruiter_id:$recruiter_id,
            searchQuery:$searchQuery,
            perPage:$perPage,
            skip:$skip
        ) {
            items {
                id
            }
        }
        
    }
`
import {_MediaType, _UserType, Table, Seat} from "../GraphQL/types/ts_types"
import {SemanticWIDTHS} from "semantic-ui-react"
// @ts-ignore
import parser from "tld-extract"
// import {Table} from "../"

export const isMobile = () => {
    return window.innerWidth < 992
}


export const fetchSubDomainRouteUrl = ():string => {
    let href = window.location.href; 
    try {
        let tld_results = parser(href)
        return tld_results.domain
    } catch (e) {
        return ""
    }
}

/**
 * 
 * @description - Returns a formatted url based on the parameters
 * 
 * @param {boolean} is_subdomain - whether we are on a custom subdomain or not
 * @param {string} recruiter_routeurl - the routeurl of the recruiter
 * @param {string | undefined} table_id  - specify table id
 * @param {string | undefined } seat_id  - specify seat id
 * 
 * @returns {string} - the formatted url
 */
export const formatUrl = (is_subdomain:boolean, recruiter_routeurl:string, table_id?:string, seat_id?:string, apply?:boolean, apply_stage?:string ):string => {
    
    let url = ""

    // if we are on tables.work, add the recruiter routeurl
    if (!is_subdomain) {
        url = `/${recruiter_routeurl}`
    }

    // Add table_id
    if (table_id) {
        url += `/tables/${table_id}`
    }

    // Add seat_id
    if (seat_id) {
        url += `/seats/${seat_id}`
    }

    // Add apply
    if (apply) {
        url += `/apply`
    }

    // Add apply stage
    if (apply_stage) {
        url += `/${apply_stage}`
    }

    return url
}

/**
 * 
 * @description - returns a path for react router v4 that is dynamic depending on params
 * 
 * @param {boolean} is_subdomain - whether we are on a custom domain or not 
 * @param {boolean} table - whether to add the table id
 * @param {boolean} seat - whether to add the seat id
 * @param {boolean} apply - whether to add apply
 * @param {boolean} apply_stage - whether to apply the apply stage
 * 
 * @returns {string} path - the relative path for the url
 */
export const formatPath = (is_subdomain:boolean, table?:boolean, seat?:boolean, apply?:boolean, apply_stage?:string):string => {
    let path = ""

    if (!is_subdomain) {
        path = "/:recruiter_name"
    }

    if (table) {
        path += "/tables/:table_id"
    }

    if (seat) {
        path += "/seats/:seat_id"
    }

    if (apply) {
        path += "/apply"
    }

    if (apply_stage) {
        path += `/${apply_stage}`
    }
    console.log(path);
    return path
}


/**
 * Maps all the fields returned from linkedin to our user schema
 * 
 * @param linkedInData - data returned from Linkedin
 * 
 * @returns {loose_object_type} variables
 * 
 */
export const mapLinkedInFields = (linkedInData:any) => {
    console.log("linkedin data:",linkedInData)
    let variables:any = {}
    
    variables.personal_address = linkedInData.location.name
    variables.country = linkedInData.location.country.code
    variables.about = linkedInData.summary
    variables.email = linkedInData.emailAddress
    variables.fname = linkedInData.firstName
    variables.profile_pic = linkedInData.pictureUrl
    // variables.experience = mapExperience(linkedInData.positions)

    // variables.experience = linkedInData.position
    variables.oauth_data = {}
    variables.oauth_data.type = "linkedin"
    variables.oauth_data.url = linkedInData.publicProfileUrl
    variables.oauth_data.access_token = linkedInData.oauthToken
    variables.type = ["WORKER"]
    
    return variables

}

const mapExperience = (positions:any) => {
    let experiences:any = []
    positions.values.map((position:any) => {
        
        let start_month = position.startDate.month
        let start_year = position.startDate.year
        let experience:any = {
            company:position.company.name,
            startdate: new Date(start_year, start_month),
            current:position.isCurrent,
            role:position.title,
            summery:position.summary
        }

        if (!position.isCurrent) {
            experience.enddate = new Date(position.endDate.month, position.endDate.year)
        }
        experiences.push(experience)
    })
    return experiences
}

export const getMediaType = (mimetype:string) => {
    if (/image\/*/.test(mimetype)) {
        return _MediaType.IMAGE
    }

    if (/video\/*/.test(mimetype)) {
        return _MediaType.VIDEO
    }

    return null
}

export const isEmptyObject = (obj:any) => {
    return Object.keys(obj).length === 0
}


/*
Rows organized as a maximum of three columns, 
so there's either 1 row with less than 3 columns, 
or multiple rows with exactly 3 columns
*/
export const splitIntoRows = (arr:any[], row_size:number):any[] => {
    if (arr.length === 0) {
        arr = [[]]
        return arr
    }
    let rows = arr.map( function(e,i){
        return i%row_size===0 ? arr.slice(i,i+row_size) : null;
    }).filter(function(e){
        return e !== null
    });
    return rows
}

/*
Type-friendly function for returning number of columns for the first row
*/
export const getNumColumns = (row:Table[]):SemanticWIDTHS => {
    if (!row) {
        return 1
    }
    switch (row.length) {
        case 0:
            return 1
        case 1:
            return 1
        case 2:
            return 2
        default:
            return 3
    }
}


export function stripHtmlToText(html:string)
{
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    var res = tmp.textContent || tmp.innerText || '';
    res.replace('\u200B', ''); // zero width space
    res = res.trim();
    return res;
}


export const sortByKey = (obj_list:any[], key:string, norm_func?:Function) => {
    return obj_list.sort((a,b) => {
        let a_item = a[key]
        let b_item = b[key]
        if (norm_func) {
            a_item = norm_func(a_item)
            b_item = norm_func(b_item)
        }
        if (a_item < b_item) {
            return -1
        } else if (a_item > b_item) {
            return 1
        } else {
            return 0
        }
    })
}

export const userAppliedToSeat = (user_id:string, seat:Seat) => {
    for (let application of seat.table_id.applicants) {
        if (user_id === application.user_id.id) {return true}
    }
    return false
}
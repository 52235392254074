import * as React from 'react'
import "./style.scss"
import img_404 from "../../media/img/404-image.png"
import {Image, Container, Header} from "semantic-ui-react"
import {NavLink} from "react-router-dom"
import { Helmet } from 'react-helmet'
import {tables_color} from "../../styles"

interface Page404 {

}


class Page404 extends React.Component {
    public render() {
        return (
            <div className="container-404">
                <Helmet>
                    <title>Tables | 404</title>
                    <meta name="description" content="Looks like you've hit a 404 error. We're working quickly to fix it." />
                    <meta name="theme-color" content={tables_color} />
                </Helmet>
                <Container className="content-404" textAlign="center">

                    <div className="img-container">
                        <Image src={img_404}
                            alt="404 error image"
                        />
                    </div>
                    <Header as="h1">
                        Well, this is embarrassing...
                    </Header>
                    <p className="header-2 bold">
                        Looks like you've hit a 404 error. We're working quickly to fix it.
                        
                    </p>
                    <div style={{marginTop:"40px"}}>
                        <NavLink to="/" className="link-text">
                            Return to Tables.work
                        </NavLink>
                    </div>

                </Container>
            </div>
        )
    }
}

export default Page404
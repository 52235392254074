// Library imports
import * as React from 'react'
import {Card, Divider, Form, DropdownProps, Label, Icon, DropdownItemProps} from "semantic-ui-react"
import {debounce} from "lodash"
import uuid from "uuid/v4"
import {ApolloConsumer} from "react-apollo"

// Local imports
import { ApplySubFormProps } from '.';
import {FieldLabel} from "../../components"
import ApolloClient from 'apollo-client';
import { NormalizedCache } from 'apollo-cache-inmemory';

// GraphQL
import { getSkillList } from '../../GraphQL/queries';
import { actions } from '../../GraphQL/local_writes';
import {User} from "../../GraphQL/types"


interface SkillsInputProps extends ApplySubFormProps {

}

class SkillsInput extends React.Component<SkillsInputProps> {

    mapSelections = (obj:boolean):any[] => {
        if (!this.props.PageState.user) {
            return []
        }
        let skills = this.props.PageState.user.esco_skills
        
        return skills.map((skill,index) => {
            if (obj) {
                return {
                    key:uuid(),
                    text:skill,
                    value:skill,
                    source:"default"
                }
            } else {
                return skill
            }
            

        })
        
    }

    state = {
        languages:this.props.PageState.user ? this.props.PageState.user.languages.join(",") : "",
        certificates:this.props.PageState.user ? this.props.PageState.user.certificates.join(",") : "",
        skills_suggestions:this.mapSelections(true),
        skills_selections:this.mapSelections(false),
        search_query:""
    }

    componentDidMount () {

    }

    handleChange = (field:string) => (e:React.SyntheticEvent<HTMLInputElement>) => {
        
        let user_update_obj = {} as User
        let str_value = e.currentTarget.value
        let values = str_value.split(",")
        switch (field) {
            case "languages":
                user_update_obj.languages = values
                break
            case "certificates":
                user_update_obj.certificates = values
                break
        }
        this.setState({[field]:str_value})
        
        this.debounceUpdateUser(field, user_update_obj)
    }

    handleSkillSelect = (e:React.SyntheticEvent, data:DropdownProps) => {

        let skills = data.value as any
        let new_options = this.state.skills_suggestions

        let is_existing_skill = this.state.skills_suggestions.find(item => {
            return item.text === data.value
        })

        if (!is_existing_skill) {
            let new_skill = skills[skills.length-1]
            
            new_options.push({
                key:new_skill,
                text:new_skill,
                value:new_skill
            })

        }

        this.setState({
            skills_selections:data.value,
            skills_suggestions:new_options
        })

        let user_update_obj = {
            esco_skills:data.value
        }
        this.debounceUpdateUser("esco_skills",user_update_obj)
        this.setState({
            search_query:""
        })
    }

    debounceUpdateUser = debounce((field:string, data:any) => {
        let user = {
            ...this.props.PageState.user,
            ...data
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, user)
    }, 500)

    handleSearchChange = (client:ApolloClient<NormalizedCache>) => (e:React.SyntheticEvent, data:DropdownProps) => {
        
        if (data.searchQuery) {
            this.debounceHandleSearchChange(client, data.searchQuery)
        }
        
        this.setState({search_query: data.searchQuery})
    }

    debounceHandleSearchChange = debounce((client:ApolloClient<NormalizedCache>, query:string) => {
        this.fetchSkillsSuggestions(client, query)
    }, 500)

    fetchSkillsSuggestions = async (client:ApolloClient<NormalizedCache>, query:string) => {
        
        let result = await client.query({
            query:getSkillList,
            variables: {query:query}
        }) as any
        
        let new_suggestions = result.data.getSkillList.map((item:string) => {
            return {
                key:uuid(),
                value:item,
                text:item,
                source:"fetch"
            }
        })

        let suggestions = this.state.skills_suggestions.filter(suggestion => {
            return this.state.skills_selections.includes(suggestion.text)
        }).concat(new_suggestions)
        this.setState({skills_suggestions:suggestions})
    }

    

    public render() {
        return (
            <Card fluid className="skills-input">
                <div className="form-card">
                    <p className="bold">{"LANGUAGES, CERTIFICATIONS & SKILLS"}</p>
                    <Divider/>
                    
                    <Form.Group widths="equal">
                        <Form.Input label={<FieldLabel content="LANGUAGES" {...this.props}/>}
                            fluid
                            placeholder="Spanish, Mandarin, German..."
                            onChange={this.handleChange("languages")}
                            value={this.state.languages}
                        />

                        <Form.Input label={<FieldLabel content="CERTIFICATIONS" {...this.props}/>}
                            fluid
                            placeholder=""
                            onChange={this.handleChange("certificates")}
                            value={this.state.certificates}
                        />
                    </Form.Group>

                    <ApolloConsumer>
                        {
                            (client) => {
                                return (
                                    <Form.Dropdown fluid
                                        placeholder="Illustrator, Microsoft Word, etc."
                                        label={<FieldLabel content="SKILLS" {...this.props}/>}
                                        onSearchChange={this.handleSearchChange(client)}
                                        search
                                        multiple
                                        clearable
                                        selection
                                        allowAdditions
                                        additionPosition="bottom"
                                        options={this.state.skills_suggestions}
                                        onChange={this.handleSkillSelect}
                                        value={this.state.skills_selections}
                                        searchQuery={this.state.search_query}
                                        text={this.state.search_query}
                                    />
                                )
                            }
                        }
                    </ApolloConsumer>

                </div>
            </Card>
        )
        
    }
}

export default SkillsInput
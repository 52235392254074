import * as React from 'react';
import {Modal, Form, Message} from "semantic-ui-react";
// @ts-ignore
import {Fade} from "react-reveal";
import { Mutation, MutationFn} from "react-apollo";
import ReactGA from "react-ga";
import ApolloClient from 'apollo-client';
import {ApolloConsumer} from "react-apollo"
import {singleUpload} from "../../GraphQL/mutations"
// Local imports
import { WithRouterProps } from '../../interfaces';
import {FieldLabel, BrandedButton, BrandedLink, FullPageLoader} from "../../components"
import { NormalizedCache } from 'apollo-cache-inmemory';

// GraphQL
import { PageState, AUTH_FLOW_STAGES, FORGOTPASS_FLOW_STAGES, _UserType } from '../../GraphQL/types';
import {addUser} from "../../GraphQL/mutations"
import { ApolloError } from 'apollo-client';
import { actions } from '../../GraphQL/local_writes';
import AnalyticsItems from '../../analytics';





export interface SignUpModalProps extends WithRouterProps {
    PageState:PageState,                    // PageState
    client:ApolloClient<NormalizedCache>,   // Apollo Client
    open:boolean,                           // Whether the modal is open
    apply:boolean,                          // Whether the modal should push to /apply after authenticating
    table_id?:string,                       // If the modal was opened from a table, table_id
    seat_id?:string,                        // If the modal was opened from a seat, seat_id AND ^ table_id
    toggleOpen:() => void,                  // Function to close/open the modal
    flow_stage?:AUTH_FLOW_STAGES            // Which flow stage the Auth Modal should open to
}


class SignUpModel extends React.Component<SignUpModalProps> {


    handleUploadResume = (mutationFn:MutationFn) => async (e:React.SyntheticEvent<HTMLInputElement>) => {
        let files = e.currentTarget.files
        if (files && files.length > 0) {
            await mutationFn({
                variables:{
                    file:files[0]
                }
            })
        }
        
    }
    // handle Resume Upload Complete
    handleResumeUploadComplete = (result:any) => {
        
        let resume = result.singleUpload
        console.log(resume);
        resume.type = "resume"
       // this.props.setUpload("resume")(resume)
    }

    handleResumeUploadError = (error:ApolloError) => {
        console.log(error);
        
    }

    public render() {
        
          // Fetch props
          let {
            open
        } = this.props;


        return (
            <Fade>
                <div>
                <Modal className="auth-modal"
                    open={open}
                    onClose={this.props.toggleOpen}
                    closeIcon
                >
                <Modal.Header>Join the Movement</Modal.Header>
                 {/* Main Content */}
                 <Modal.Content>
                        <ApolloConsumer>
                            {
                                (client) => {
                                    return (
                                        <Form

                                        >
                                        <Form.Group unstackable widths={2}>
                                          <Form.Field required>
                                                <FieldLabel content="Full Name*" {...this.props}/>
                                                <input placeholder="Full Name" 
                                                    type="text"
                                                    />
                                            </Form.Field>  

                                            <Form.Field required>
                                                <FieldLabel content="Phone Number*" {...this.props}/>
                                                <input placeholder="(xxx-xxx-xxxx)" 
                                                    type="text"
                                                    />
                                            </Form.Field>

                                            </Form.Group>


                                            <Form.Field required>
                                                <FieldLabel content="EMAIL*" {...this.props}/>
                                                <input placeholder="john@doe.com" 
                                                    type="email"
                                                    />
                                            </Form.Field>
                                            
                                          

                                            <Form.Group grouped>
                                            <label>I am open to these kind of opportunities.</label>
                                                <Form.Field label='Full Time' control='input' type='checkbox' />
                                                <Form.Field label='Part Time' control='input' type='checkbox' />
                                             </Form.Group>
                                             {/* <div class="fas fa-cloud-upload-alt"></div> */}
                                        
                                             <Form.Field required>
                                             <FieldLabel content="Resume (1MB Max)" {...this.props}/>
                         
                                {/* <a className="ui button">Upload .pdf or .docx file</a> */}

                                <label htmlFor="resumeupload">
                                <a className="ui button"> <i className="ui upload icon"></i>Upload .pdf or .docx file</a>
                                <Mutation mutation={singleUpload}
                                    onCompleted={this.handleResumeUploadComplete}
                                    onError={this.handleResumeUploadError}
                                >
                                    {
                                        (singleUpload,{loading, error}) => {
                                        
                                            if (loading) return <FullPageLoader/>
                                            return (
                                                <input name="resume" 
                                                    hidden
                                                    type="file" 
                                                    id="resumeupload" 
                                                    onChange={this.handleUploadResume(singleUpload)}
                                                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    />
                                            )
                                        }
                                    }
                                </Mutation>
                                </label>
                        
                            </Form.Field>
                     
        

                                            <div className="center-content">
                                                <BrandedButton content="Submit" 
                                                    PageState={this.props.PageState}
                                                    type="submit"
                                                />
                                            </div>
                                        </Form>
                                    )
                            }}
                        </ApolloConsumer>
                    </Modal.Content>

                </Modal>

                </div>
                
            </Fade>

        )
    
    
    
    
    }




}

export default SignUpModel;
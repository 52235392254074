import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepConnector from '@material-ui/core/StepConnector';


import { ApplySubFormProps, error_message_types } from '.';


function getSteps() {
  return ['Personal Info', 'Skill Sets', 'Finished'];
}





function getStepContent(stepIndex:any) {
  switch (stepIndex) {
    case 0:
      return 'Personal Info';
    case 1:
      return 'Skill Sets';
    case 2:
      return 'Finished';
    default:
      return 'Unknown stepIndex';
  }
}



interface ProgressBarProps extends ApplySubFormProps {
  classes?: any
}

class ProgressBar extends React.Component<ProgressBarProps> {
 
   state = {
    activeStep: this.props.application.stage
  };

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };



  
  render() {
    const steps = getSteps();
    const { activeStep } = this.state;
    const { classes } = this.props;


    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine
        }}
      />
    );

    return (
  
        <Stepper activeStep={this.props.application.stage} alternativeLabel className="MuiStepper-alternativeLabel" connector={connector}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
     
    );
  }
}




const styles = (theme:any) => ({
  root: {
    width: "90%"
  },
  button: {
    marginRight: theme.spacing.unit
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  icon: {
    color: "pink !important"
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[100],
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
  },
});

export default withStyles(styles)(ProgressBar);

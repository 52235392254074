// Route specification
import * as React from 'react'
import {Switch, Route, withRouter, Redirect} from "react-router-dom"
import {LandingPage, BrowsePage, RecruiterPage, Page404} from "./pages"
// @ts-ignore
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

interface RouteProps {
    is_subdomain:boolean
}

class RouteLowercase extends React.Component<RouteProps> {



    public render() {

        if (/[A-Z]/.test(window.location.pathname)) {
            return <Redirect to={`${window.location.pathname.toLowerCase()}`}/>
        }

        return (
            <Routes {...this.props}/>
        )
    }
}

class Routes extends React.Component<RouteProps> {
    public render() {

        // let is_subdomain = this.props.is_subdomain
        let {
            is_subdomain
        } = this.props

        console.log("is subdomain:",is_subdomain)

        switch (is_subdomain) {
            case true:
                return (
                    <Switch>
                
                        <Route exact path="/browse" render={(props) => <BrowsePage {...props} {...this.props}/>}/>
                        <Route exact path="/404" component={Page404}/>
                        <Route path="/" render={(props) => <RecruiterPage {...props} {...this.props}/>}/>
                    
                    </Switch>
                )
            case false:
                return (
                    <Switch>
                
                        <Route exact path="/browse" component={withRouter(BrowsePage)}/>
                        <Route exact path="/404" component={Page404}/>
                        <Route path="/:recruiter_name" render={(props) => <RecruiterPage {...props} {...this.props}/>}/>
                        <Route path="/" render={(props) => <LandingPage {...props} {...this.props}/>}/>
                        
                    </Switch>
                )
            default:
                return null
        }
            
    }
}

export default RouteLowercase
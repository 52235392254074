import gql from "graphql-tag"

export const FullFile = gql`
    fragment FullFile on File {
        filename,
        mimetype,
        path,
        y_axis
    }
`

export const FullAttachment = gql`
    fragment FullAttachment on File {
        filename,
        mimetype,
        path,
        type
    }
`

export const pageInfo = gql`
    fragment FullPageInfo on PaginationInfo {
        perPage,
        pageCount,
        itemCount,
        hasNextPage,
        hasPreviousPage,
    }
`

export const FullClearBit = gql`
    fragment FullClearBit on logoClearBit {
        key,
        value,
        text,
        image
    }
`

export const FullEducation = gql`
    fragment FullEducation on education {
        university,
        degree,
        degree_status,
        major,
        country
    }
`

export const FullExperience = gql`
    
    fragment FullExperience on experience {
        company {
            key,
            value,
            text,
            image
        }
        start_date,
        end_date,
        role,
        current,
        location
    }
`


export const FullUser = gql`
    ${FullFile}
    ${FullEducation}
    ${FullExperience}
    fragment FullUser on User {
        id,
        fname,
        lname,
        url,
        email,
        country,
        email_ok,
        phone,
        type,
        website,
        personal_address,
        esco_skills,
        languages,
        certificates,
        profile_pic {
            ...FullFile
        }
        education {
            ...FullEducation
        }
        experience {
            ...FullExperience
        }
    }
`

export const FullUserLocal = gql`
    ${FullFile}
    ${FullEducation}
    ${FullExperience}
    fragment FullUserLocal on User {
        id,
        fname,
        lname,
        url,
        email,
        country,
        email_ok,
        phone,
        type,
        website,
        personal_address,
        esco_skills,
        languages,
        certificates,
        profile_pic @client {
            path,
            filename,
            mimetype,
            y_axis
        }
        education @client {
            ...FullEducation
        }
        experience @client {
            ...FullExperience
        }
    }
`

export const FullApplication = gql`
    fragment FullApplication on Application {
        id,
        submission_date,
        table_id {
            id
        },
        user_id {
            id
        },
        seat_id {
            id
        },
        recruiter_id {
            id
        },
        why,
        attachments {
            filename,
            mimetype,
            path,
            type
        },
        archived,
        url,
        contacted
    }
    
`

export const FullGoogleFont = gql`
    fragment FullGoogleFont on googleFont {
        key,
        value,
        text
    }
`

export const FullBrand = gql`
    ${FullGoogleFont},
    fragment FullBrand on Brand {
        color,
        font {
            ...FullGoogleFont
        }
        header,
        logo,
        sub_header
    }
`

export const FullSeat = gql`
    
    fragment FullSeat on Seat {
        id,
        title,
        description,
        end_date,
        bounty,
        admin_id {
            id
        },
        profile_pic {
            filename,
            mimetype,
            path,
            y_axis
        },
        table_id {
            id,
            name
            applicants {
                id
                user_id {
                    id
                }
            }
        },
        required_skills,
        commitment,
        url
    }
`

export const FullTable = gql`
    
    fragment FullTable on Table {
        id,
        url,
        name,
        admins {
            id
        },
        profile_pic {
            ...FullFile
        },
        published,
        highlight,
        description,
        video {
            ...FullFile
        },
        images {
            ...FullFile
        },
        end_application_date,
        start_date,
        end_date,
        create_date,
        completed,
        eth_addr,
        loves,
        type,
        attachments {
            ...FullFile
        },
        recruiter_id {
            id,
            name,
            route_url
        },
        views,
        applicants {
            id,
            user_id {
                id
            }
        },
        archived,
        draft_seats {
            id
        },
        publish_seats {
            ...FullSeat
        }
        live
    }
    ${FullFile}
    ${FullSeat}
`

export const FullRecruiter = gql`
    fragment FullRecruiter on Recruiter {
        id,
        url,
        name,
        admins {
            id
        },
        route_url,
        home_url,
        applicants {
            id,
            user_id {
                id
            }
        },
        brand {
            color,
            font {
                key,
                value,
                text
            },
            header,
            logo,
            favicon,
            sub_header
        }

    }
`


import * as React from 'react'
import { WithPageState, FullPageLoader } from '../';
import { Seat } from '../../GraphQL/types';
import {BrandedText, RTFDisplay} from ".."
import Truncate from 'react-truncate'
import { NavLink, match } from 'react-router-dom';
import "./style.scss"
import { History } from 'history';
import { Mutation, MutationFn } from 'react-apollo';
import { addTableClick } from '../../GraphQL/mutations';
import moment from 'moment';
import { formatUrl } from '../../utils';
import { WithRouterProps } from '../../interfaces';

interface SidebarPositionProps extends WithPageState, WithRouterProps {
    match:match,
    history:History,
    seat:Seat
}

class SidebarPosition extends React.Component<SidebarPositionProps> {

    handleClick = (addTableClick:MutationFn) => () => {
        let params = this.props.match.params as any

        let recruiter_name = params.recruiter_name
        let table_id = params.table_id
        let seat_id = this.props.seat.id
        let recruiter_id = this.props.PageState.recruiter.id
        let user_id = this.props.PageState.user && this.props.PageState.user.id !== "" ? this.props.PageState.user.id : undefined
        
        let variables = {
            user_id:user_id,
            table_id:table_id,
            recruiter_id:recruiter_id,
            date_time:moment().toISOString()
        }
        console.log("logginclick with vars:",variables)
        addTableClick({
            variables:variables
        })

    }
    
    onCompleted = (data:any) => {
        console.log("logged table click!",data)
        let params = this.props.match.params as any

        let recruiter_name = params.recruiter_name
        let table_id = params.table_id
        let seat_id = this.props.seat.id
        let new_url = formatUrl(this.props.is_subdomain, recruiter_name, table_id, seat_id)
        this.props.history.push(new_url)
    }

    onError = (error:any) => {
        let params = this.props.match.params as any

        let recruiter_name = params.recruiter_name
        let table_id = params.table_id
    }

    public render() {
        let {
            seat,
            match,
            history,
            PageState
        } = this.props

        let params = match.params as any

        let recruiter_name = params.recruiter_name
        let table_id = params.table_id
        let seat_id = this.props.seat.id
        let recruiter_id = this.props.PageState.recruiter.id
        let user_id = this.props.PageState.user && this.props.PageState.user.id !== "" ? this.props.PageState.user.id : undefined
        

        return (
            <Mutation mutation={addTableClick}
                onCompleted={this.onCompleted}
                onError={this.onError}
            >
                {
                    (addTableClick, {loading}) => {
                        if (loading) return <FullPageLoader/>
                        return (
                            <div className="side-seat">
                                <a onClick={this.handleClick(addTableClick)} style={{cursor:"pointer"}}>
                                    <BrandedText PageState={PageState} content={seat.title} colored/>
                                </a>
                                <Truncate lines={3}>
                                    <RTFDisplay data={seat.description}/>
                                </Truncate>
                            </div>
                        )
                    }
                }
            </Mutation>
        )
    }
}


export default SidebarPosition
import gql from "graphql-tag"
import * as fragments from "../fragments"


export const addContactFromClient2Table = gql`
    mutation addContactFromClient2Table(
        $email:String!,
        $message:String!,
        $fullName:String!
    ) {
        addContactFromClient2Table (
            email:$email,
            message:$message,
            fullName:$fullName
        )
    }
`

export const addGitHubUser = gql`
    ${fragments.FullUser}
    mutation addGitHubUser($access_token:String!, $type:[String]!, $url:String) {
        addGitHubUser(access_token:$access_token, type:$type, url:$url) {
            user {
                ...FullUser
            },
            token
        }
    }
`

export const addLinkedInUser = gql`
    ${fragments.FullUser}
    mutation addLinkedInUser($fname:String, $lname:String, $url:String, $email:String!, $country:String, $eth_adr:String,
        $email_ok:Boolean, $password:String, $phone:String, $register_date:Date_Time, $last_login:Date_Time, $type:[String!], $profile_pic:String,
        $personal_address:String, $about:String, $esco_skills: [String], $experience:userPosition, $oauth_data:Oauth!
    ) {
        addLinkedInUser(fname:$fname, lname:$lname, url:$url, email:$email, country:$country, eth_adr:$eth_adr, email_ok:$email_ok,
            password:$password, phone:$phone, register_date:$register_date, last_login:$last_login, type:$type, profile_pic:$profile_pic,
            personal_address:$personal_address, about:$about, esco_skills:$esco_skills, experience:$experience, oauth_data:$oauth_data
        ) {
            token,
            user {
                ...FullUser,
                isRecruiter(email:$email)
            }
        }
    }
`

export const addUser = gql`
    ${fragments.FullUser}
    mutation addUser (
        $fname:String, 
        $lname:String, 
        $email:String!, 
        $password:String!,
        $type:[String]!
        ) {
        addUser (
            fname:$fname, 
            lname:$lname, 
            email:$email, 
            password:$password
            type:$type
            )
            
            {
                token,
                user {
                    ...FullUser
                }
            }
        }
`

export const singleUpload = gql`
    mutation singleUpload($file:Upload!) {
        singleUpload(file:$file) {
            filename,
            path,
            mimetype
        }
    }
`


export const applyToTables = gql`
    ${fragments.FullUser}
    mutation applyToTables(
        $submission_date:String!,
        $user_id:String!,
        $recruiter_id:String!,
        $seats_id:[seatIdInput!],
        $why:String!,
        $attachments:[fileInput],
        $archived:Boolean!,
        $url:String!,
        $userObject:UserObject!
    )
    {
        applyToTables(
            submission_date:$submission_date,
            recruiter_id:$recruiter_id,
            user_id:$user_id,
            seats_id:$seats_id,
            why:$why,
            attachments:$attachments,
            archived:$archived,
            url:$url
        )
        {
            id,
            why
        }

        updateUserObject(
            _id:$user_id,
            userObject:$userObject
        ) {
            ...FullUser
        }


    }
`


export const updateUser = gql`
    ${fragments.FullUser}
    mutation updateUserObject(
        $userObject:UserObject!
        $user_id:String!
    )
    {
        updateUserObject(
            _id:$user_id,
            userObject:$userObject
        ) {
            ...FullUser
        }


    }
`



export const addTableClick = gql`
    mutation addTableClick (
        $user_id:String,
        $table_id:String!,
        $recruiter_id:String!,
        $date_time:String!
    ) {
        addTableClick (
            user_id:$user_id,
            table_id:$table_id,
            recruiter_id:$recruiter_id,
            date_time:$date_time
        )
    }
`

export const addTableView = gql`
    mutation addTableView (
        $user_id:String,
        $table_id:String!,
        $recruiter_id:String!,
        $date_time:String!
    ) {
        addTableView (
            user_id:$user_id,
            table_id:$table_id,
            recruiter_id:$recruiter_id,
            date_time:$date_time
        )
    }
`

export const addToFollow = gql`
    mutation addToFollow (
        $name:String!,
        $email:String!,
        $recruiter_id:String!
    ) {
        addToFollow (
            name:$name,
            email:$email,
            recruiter_id:$recruiter_id
        ) {
            id
        }
    }
`
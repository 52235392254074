// library imports
import * as React from 'react'
import { Header } from 'semantic-ui-react';

// local imports
import { WithPageState } from '..';

interface BrandedHeaderProps extends WithPageState {
    as?:string,                 // h1,h2,h3, etc...
    content?:string,            // Header content
    spaced?:boolean,            // Whether to add spacing
    style?:React.CSSProperties, // Extra styles
    colored?:boolean,           // Whether to add brand color
    font?:boolean               // Whether to add brand font
}


class BrandedHeader extends React.Component<BrandedHeaderProps> {
    public render() {

        let {
            style,
            colored,
            font
        } = this.props

        let header_style = {} as React.CSSProperties

        let font_obj = JSON.parse(this.props.PageState.recruiter.brand.font.value as string)

        if (font) {
            header_style.fontFamily = font_obj.family
        }

        if (this.props.spaced !== false) {
            header_style.margin = '0'
        }

        header_style = {
            ...header_style,
            ...style
        }

        if (colored) {
            header_style.color = this.props.PageState.recruiter.brand.color
        }
        

        return (
            <Header
                as={this.props.as ? this.props.as : "h1"} 
                content={this.props.content} 
                style={header_style}>
                {this.props.children}
            </Header>
        )
    }
}

export default BrandedHeader
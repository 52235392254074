// Library imports
import * as React from 'react'


// Local imports
import {ApplyPageProps} from "."
import {GenericAnimation, BrandedHeader, BrandedButton} from "../../components"
import ConfettiJSON from "../../media/lottie/confetti.json"
import SuccessJSON from "../../media/lottie/success_animation.json"
import { Header } from 'semantic-ui-react';
import { formatUrl } from '../../utils';

interface SuccessProps extends ApplyPageProps {

}


class SuccessPage extends React.Component<SuccessProps> {

    state = {
        confetti_completed:false
    }

    onConfettiComplete = () => {
        this.setState({confetti_completed:true})
    }

    onSuccessComplete = () => {
        let params = this.props.match.params as any
        let recruiter_name = params.recruiter_name
        setTimeout(() => {
            let new_url = formatUrl(this.props.is_subdomain, recruiter_name)
            this.props.history.push(new_url)  
        }, 2000)
        
    }

    public render() {

        let confetti_lottieprops = {
            animationData:ConfettiJSON,
            loop:false,
            autoplay:true
        }

        let success_lottieprops = {
            animationData:SuccessJSON,
            loop:false,
            autoplay:true
        }

        return (
            <div className="success-page">
                

                <div className="success-animation">
                    <GenericAnimation 
                                lottieProps={success_lottieprops}
                                containerProps={{
                                    "className":"checkmark-animation"
                                }}
                                onComplete={this.onSuccessComplete}
                    />
                    {/* {
                        !this.state.confetti_completed ?
                        <GenericAnimation 
                            lottieProps={confetti_lottieprops}
                            containerProps={{
                                "className":"confetti-animation"
                            }}
                            onComplete={this.onConfettiComplete}
                        />
                        :
                        <GenericAnimation 
                            lottieProps={success_lottieprops}
                            containerProps={{
                                "className":"checkmark-animation"
                            }}
                            onComplete={this.onSuccessComplete}
                        />

                    } */}
                </div>
                <div className="success-content center-content">
                    <BrandedHeader content="Your application is submitted! We can’t wait to meet you." PageState={this.props.PageState}/>
                    
                </div>
                {/* <div>
                    <BrandedButton PageState={this.props.PageState}>
                        <Header as="h3" style={{color:"white"}} content="RETURN TO HOMEPAGE"/>
                    </BrandedButton>
                </div> */}
                
                
            </div>
        )
    }
}

export default SuccessPage


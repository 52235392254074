import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import {debounce} from "lodash"
import {Button, Icon, ButtonProps} from "semantic-ui-react"
import "./style.scss"

interface LocationSearchInputProps {
    setSelection:(selection:string) => void,
    selection:string
}

const searchOptions = {
    types: ['address']
}

class LocationSearchInput extends React.Component<LocationSearchInputProps> {
    state = {
        address:"",
        selection:this.props.selection
        // selections:[]
    }

    handleChange = (address:string) => {
        this.setState({
                address:address,
                selection:null
            });
        this.debounceUpdate(address)
        // this.setState({selection:address})
    }

    debounceUpdate = debounce((address:string) => {
        this.props.setSelection(address)
    }, 500)


    handleSelect = (address:string) => {
        // geocodeByAddress(address)
        //     .then((results:any) => getLatLng(results[0]))
        //     .then((latLng:any) => console.log('Success', latLng))
        //     .catch((error:any) => console.error('Error', error));

        
        this.setState({
            selection:address
        })
        this.props.setSelection(address)
    }

    render() {
        return (
            <div className="google-autocomplete">
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    searchOptions={searchOptions}
                    >
                    {
                        ({ getInputProps, suggestions, getSuggestionItemProps, loading }:any) => (
                        <div>
                            <input
                                {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                                })}
                                value={this.state.selection ? this.state.selection : this.state.address}
                                />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {
                                    
                                    suggestions.map((suggestion:any) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';

                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' }

                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                                >
                                                <p className="google-suggestion">
                                                    {suggestion.description}
                                                </p>
                                            </div>
                                        );
                                        })
                                }
                            </div>

                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
        );
    }
}

export default LocationSearchInput
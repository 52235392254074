// library imports
import * as React from 'react'
import { Container, Checkbox, Grid, Form, Modal, Image, Header, Divider, Message} from 'semantic-ui-react';
import { Redirect } from 'react-router';
import { ApolloConsumer, Mutation, MutationFn } from 'react-apollo';
import ApolloClient from 'apollo-client'
import { NormalizedCache } from 'apollo-cache-inmemory';
import queryString from 'query-string';

// local imports
import {BrandedHeader, BrandedDivider, BrandedText, LandingPosition, BrandedButton, SuccessAnimation} from "../../components"
import "./style.scss"
import SeatCarousel from '../SeatCarousel';
import TableCardList from "./TableCardList"
import SeatList from "./SeatList"
import NoTables from "./NoTables";

// graphql
import {sortByKey, formatUrl} from "../../utils"
import {addToFollow} from "../../GraphQL/mutations"
import { WithPageStateProps } from '../Recruiter';
import { searchSeatsByRecruiter, searchRecruiterData } from '../../GraphQL/queries';


export interface ViewTablesProps extends WithPageStateProps {
}

// Component for displaying the tablecards or positions
class ViewTables extends React.Component<ViewTablesProps> {
    
    state = {
        show_positions:true,
        show_positions_toggle:false,
        show_no_tables:false,   
    }
    componentDidMount(){
        let location  =  this.props.location;
        
        const searchLocation = queryString.parse(location.search);
        console.log("Location Search",searchLocation);
        if(searchLocation.view == "companies" ){
            //positions
            this.setState({
                show_positions:false
            })
        }else{
            this.setState({
                show_positions:true
            })
        }
        this.checkNoSeats()

    }


    checkNoSeats = async ()=>{

        // Initialize the Payload
        let vars = {
            recruiter_id:this.props.PageState.recruiter.id,
            searchQuery:"",
            perPage:1,
            skip:1
        }

        // Fetch Number of Seats
        let result  =  await this.props.client.query({
            query:searchRecruiterData,
            variables:vars
        }) as any;

        // Fetch Data From Response
        let seats   =  result.data.searchSeatsByRecruiter.items;
        console.log(seats);
        let tables  = result.data.searchTablesByRecruiter.items;

        // Update State
        this.setState({
            show_positions_toggle:seats.length > 0,
            show_no_tables:tables.length == 0
        })
    }

    toggleShowPositions = () => {
        this.setState({
            show_positions:!this.state.show_positions
        
        })
       

    }

   
    
    public render() {

        let {
            PageState
        } = this.props

        // let tables = PageState.recruiter.tables ?
        //                 PageState.recruiter.tables.filter(table => {
        //                     return !table.archived
        //                 }) : []
        
        // let shared_tables = PageState.recruiter.shared_tables ? 
        //                 PageState.recruiter.shared_tables.filter(table => {
        //                     return !table.archived
        //                 }) : []
        
        

        // tables = tables.concat(shared_tables)

        // // If there's only one table, redirect to the table
        // if (tables.length === 1) {
        //     let only_table = tables[0]
            
        //     return <Redirect to={formatUrl(this.props.is_subdomain, PageState.recruiter.route_url, only_table.id)}/>

        // }
        
        // // If there are no seats, don't show the toggle open positions toggle
        // let table_seats = tables.map(table => {
        //     return table.publish_seats
        // }) as any

        // let no_seats = [].concat(...table_seats).length === 0

        // let no_tables = tables.length === 0
        
        return (
            <div className="page-content view-tables">
                
                <Container>
                    <div className="page-header">
                        {
                            this.state.show_positions_toggle &&
                            <div className="seats-toggle">
                                <Checkbox toggle onChange={this.toggleShowPositions} defaultChecked={this.state.show_positions}/>
                                <div className="toggle-text">
                                    <BrandedText content="View All Open Positions" {...this.props} font={false}/>
                                </div>
                            </div>
                        }
                        

                        <div className="page-header-text">

                            <BrandedHeader as="h1" content={PageState.recruiter.brand.header} style={{margin:"0"}} {...this.props} font/>

                            <div className="divider">
                                <BrandedDivider {...this.props}/>
                            </div>

                            <BrandedText content={PageState.recruiter.brand.sub_header} {...this.props} font={false}/>
                        </div>
                        
                    </div>

                    {
                        this.state.show_no_tables && 
                        <NoTables {...this.props}/>
                    }
                     {
                       this.state.show_positions &&
                        <SeatList {...this.props}/>
                    }
                    

                    {
                        !this.state.show_positions &&
                        <TableCardList {...this.props}/>
                    }

                   
                </Container>
            </div>
        )
    }
}






export default ViewTables
/**
 * Typescript type definitions for objects
*/

// Top level object
export type Recruiter = {
    id:string,
    url:string,
    name:string,
    admins:User[],
    route_url:string,
    home_url:string,
    tables:Table[],
    draft_tables:Table[],
    shared_tables:Table[],
    brand:Brand
}


export type Table = {
    id:string,
    url:string,
    name:string,
    admins: User[],
    profile_pic:fileInput,
    create_date:Date_Time,
    published: boolean,
    highlight:string,
    description:string,
    video: fileInput[],
    images: fileInput[],
    end_application_date: Date_Time,
    start_date:Date_Time,
    end_date:Date_Time,
    completed:boolean,
    eth_addr:string,
    budget_usd:number,
    loves: number,
    type:string,
    attachments:fileInput[],
    recruiter_id:Recruiter,
    views:number,
    applicants:Application[],
    archived:boolean,
    draft_seats:Seat[],
    publish_seats:Seat[],
    live:boolean
}

export type Seat = {
    id:string,
    title:string,
    description:string,
    end_date:Date_Time,
    bounty:string,
    table_id:Table,
    admin_id:User,
    profile_pic:fileInput,
    required_skills: [string],
    commitment: _COMMITMENT,
    url:string
}

export type User = {
    id:string,
    fname:string,
    lname:string,
    url:string,
    email:string,
    country:string,
    eth_adr:string,
    email_ok:boolean,
    phone:string,
    website:string[],
    personal_address:string,
    isRecruiter:boolean,
    emailVerified:boolean,
    education: education[],
    experience: experience[],
    esco_skills: string[],
    languages: string[],
    certificates: string[],
    profile_pic:File,
    type:_UserType,
}

export type logoClearBit = {
    key:string,
    value:string,
    text:string,
    image:string,
    __typename:string
}

export type education = {
    university: string,
    degree: string,
    degree_status: string,
    major: string,
    country: string,
    __typename:string
}

export type experience = {
    company: logoClearBit,
    start_date: string,
    end_date: string,
    role: string,
    current: boolean,
    location: string,
    __typename:string
}

export type local_application = {
    attachments:File[],
    cover_letter_input:string,
    selected_table:string,
    selected_seat:string,
    stage:number
    disability:string
    finished:boolean
    
}

export type Application = {
    id: string,
    submission_date: string,
    table_id: string,
    user_id: User,
    seat_id: Seat
    recruiter_id: Recruiter
    why: string
    attachments: [fileInput]
    archived: boolean
    url: string
    contacted: boolean
}

export type Brand =  {
    color: string
    font: googleFont
    header: string
    logo: string
    favicon:string
    sub_header: string
}

export type googleFont =  {
    key: string,
    value: string,
    text: string
}

export type fileInput = {
    filename:string,
    mimetype:string,
    path:string,
    y_axis:string
}

export enum _UserType {
    WORKER = "WORKER",
    ADMIN = "ADMIN",
    SYSADMIN = "SYSADMIN",
    TEST = "TEST"
}

export enum _MediaType {
    IMAGE = "IMAGE",
    VIDEO = "VIDEO"
}

export enum _COMMITMENT {
    FULL_TIME = "FULL_TIME",
    REMOTE = "REMOTE",
    HALF_TIME = "HALF_TIME",
    CONTRACT = "CONTRACT",
}

export type Date_Time = string


// Local Types / Enums

export enum AUTH_FLOW_STAGES {
    LOGIN = "LOGIN",
    SIGNIN = "SIGNIN",
    SIGNUP = "SIGNUP",
    FORGOTPASSWORD = "FORGOTPASSWORD",
    SIGNOUT = "SIGNOUT"
}

export enum FORGOTPASS_FLOW_STAGES {
    EMAIL = "EMAIL",
    CODE = "CODE",
    RESET = "RESET",
    SUCCESS = "SUCCESS"
}
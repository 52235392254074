import * as React from 'react'
import Lottie from 'react-lottie';
// import * as animationData from '../../media/lottie/tables_loading.json'

interface LottieControlProps {
	light?:boolean
}

class LottieControl extends React.Component<LottieControlProps> {

  render() {

	let {
		light
	} = this.props


	let light_json = require('../../media/lottie/tables_loading_white.json')
	let dark_json = require('../../media/lottie/tables_loading.json')
	let animation_data = light ? light_json : dark_json

    const defaultOptions = {
			loop: true,
			autoplay: true, 
			animationData: animation_data,
			rendererSettings: {
			// preserveAspectRatio: true
			}
    }

    return <div className="tables-animation">
				<Lottie options={defaultOptions}
                        // height={400}
                        // width={400}
                    />
            </div>
  }
}

export default LottieControl
import * as React from 'react'
import {Button} from "semantic-ui-react"
import {WithPageState} from "../"
import hexToRGBA from 'hex-to-rgba'
import tinycolor from "tinycolor2"

interface BrandedButtonProps extends WithPageState {
    content?:string,            // The content to show
    style?:React.CSSProperties, // Extra styles
    className?:string,          // Dom class
    onClick?:any,               // Click handler
    type?:string,               // Type of the button
    href?:string,               // If there is a link to go to
    font?:boolean               // whether to add font styles
}

class BrandedButton extends React.Component<BrandedButtonProps> {

    state = {
        fontFamily:"",
        bg_color:"",
        faded_bg_color:"",
        color:"",
        hovered:false
    }

    setHover = (hover:boolean) => () => {
        this.setState({hovered:hover})
    }

    // Fetch variables
    componentDidMount () {

        let {
            PageState,
        } = this.props

        let font = JSON.parse(PageState.recruiter.brand.font.value as string)

        let bg_color = hexToRGBA(PageState.recruiter.brand.color)
        let faded_bg_color = hexToRGBA(PageState.recruiter.brand.color, 0.5)

        let brightness = tinycolor(faded_bg_color).getBrightness()
        
        this.setState({
            fontFamily:font.family,
            bg_color:bg_color,
            faded_bg_color:faded_bg_color,
            color: brightness > 220 ? "" : "white",
            hovered:false
        })

    }

    public render() {

        let {
            PageState,
            content,
            className,
            children,
            style,
            type,
            font
        } = this.props

        
        let {
            bg_color,
            faded_bg_color,
            color,
            fontFamily
        } = this.state

        let button_style = {
            
            backgroundColor: this.state.hovered ? faded_bg_color : bg_color,
            color:color,
            ...style
        } as React.CSSProperties

        if (font) {
            button_style.fontFamily = fontFamily
        }


        return (
            <Button
                content={content}
                className={className}
                style={button_style}
                onMouseEnter={this.setHover(true)}
                onMouseLeave={this.setHover(false)}
                onClick={this.props.onClick}
                type={type}
                href={this.props.href}
            >
                {children}
            </Button>
        )
    }
}

export default BrandedButton
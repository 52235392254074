// Library imports
import * as React from 'react'
import Lottie from "react-lottie"

// Local imports
import CheckMark from "../../media/lottie/check_mark_success.json"

interface SuccessAnimationProps {
    
}

class SuccessAnimation extends React.Component {
    public render() {
        
        const defaultOptions = {
            loop: false,
			autoplay: true, 
			animationData: CheckMark,
			rendererSettings: {
			    // preserveAspectRatio: true
			}
        }
        
        return (
            <Lottie options={defaultOptions}/>
        )
    }
}

export default SuccessAnimation
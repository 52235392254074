/**
 * This module is no longer included in the application, github oauth only allows 1 redirect url, which means we 
 * can't use it on both clients, and linkedin removed their full profile api capabilities, which means we can't use user data
 * from them
 */
// Library imports
import * as React from 'react'
import {MutationFn, Mutation} from "react-apollo"
import {Modal, Image} from "semantic-ui-react"
// @ts-ignore
import GitHubLogin from "react-github-login"
// @ts-ignore
import LinkedinSDK from "react-linkedin-sdk"
// @ts-ignore
import { LinkedIn } from 'react-linkedin-login-oauth2';
import uuid from "uuid/v4"

// Local imports
import {FullPageLoader, BrandedButton, LinkedinAuth} from "../../components"
import {AuthContentProps} from "."
import {mapLinkedInFields} from "../../utils"
import GithubLogo from "../../media/img/GitHub.png"
import LinkedInLogo from "../../media/img/LinkedIn.png"

// GraphQL
import {_UserType, AUTH_FLOW_STAGES} from "../../GraphQL/types"
import {actions} from "../../GraphQL/local_writes"
import {addGitHubUser, addLinkedInUser} from "../../GraphQL/mutations"

// const linkedin_fields = `:(id,first-name,last-name,location,industry,summary,specialties,positions,num-connections,email-address,picture-url,picture-urls,public-profile-url)`

// placeholder until we use .env
const clientID = "78zcex0dws0moy"
const clientSecret = "25aIPNxtApyWQT2Z"

const getFields = () => {
    const linkedinFields = [
        "r_emailaddress",
        "r_basicprofile",
        
    ]
    console.log("linkedinFields:",linkedinFields.join("%20"))
    return linkedinFields.join("%20")
}

// Show Github / Linkedin / Email options
class AuthLoginButtons extends React.Component<AuthContentProps> {

    state = {
        loading:false,
        error:false,
        error_msg:""
    }

    handleLinkedIn = (mutationFn: MutationFn) => async (response:any) => {
        console.log("linkedin result:",response)

        // let variables = mapLinkedInFields(response)

        // console.log("Authenticating linkedinUser with variables",variables)
        // mutationFn({
        //     variables: {
        //         ...variables,
        //         type:_UserType.WORKER
        //     }
        // })
    }

    handleLinkedInResult = async (response:any) => {

        this.setState({loading:false})

        console.log("linkedin Register response:",response)
        let token = response.addLinkedInUser.token
        let user = response.addLinkedInUser.user
        
        await this.props.PageState.CacheWriter.write_cache(actions.SET_TOKEN, token)
        await this.props.PageState.CacheWriter.write_cache(actions.SET_USER, user)

        this.props.handleRedirect()
        
    }

    
    handleLinkedinError = (error:any) => {
        this.setState({loading:false})
        this.setState({error:"There was an error with LinkedIn Oauth"})
        console.log("linkedin error response:",error)
    }

    handleGithub = (type:string, mutationfn:MutationFn) => (data?:any) => {
        console.log("handling github",type)
    }

    public render() {
        let bg_color = this.props.PageState.recruiter.brand.color

        console.log("this.props",this.props)
        let header_content = "Sign In".toUpperCase()

        return (
            <div>
                {/* <Modal.Header style={{backgroundColor:bg_color}} >
                    <p className="bold" style={{color:"white"}}>
                        {header_content}
                    </p>
                </Modal.Header> */}

                <Modal.Content>

                    <div className="auth-buttons">

                        <Mutation mutation={addGitHubUser}>

                            {
                                (addGitHubUser, {loading}) => {
                                    return (
                                        <GitHubLogin clientId="c834b0c41ad45140537d"
                                            onSuccess={this.handleGithub("success", addGitHubUser)}
                                            onFailure={this.handleGithub("failure", addGitHubUser)}
                                            redirectUri=""
                                            className="github-login"
                                        >
                                            <Image src={GithubLogo}/>
                                        </GitHubLogin>
                                    )
                                }
                            }
                        </Mutation>

                        {/* <LinkedinAuth onComplete={this.handleLinkedIn}
                            onError={this.handleLinkedinError}
                            className="linkedin-login"
                        /> */}

                        {/* <Mutation mutation={addLinkedInUser}
                            onCompleted={this.handleLinkedInResult}
                            onError={this.handleLinkedinError}
                        >

                            {
                                (addLinkedInUser, {loading, client}) => {
                                    if (loading) return <FullPageLoader/>
                                    return (
                                        // <LinkedinSDK
                                        //     clientId="78zcex0dws0moy"
                                        //     callBack={this.handleLinkedIn(addLinkedInUser)}
                                        //     fields={linkedin_fields}
                                        //     className={'linkedin-login'}
                                        //     loginButtonText={<Image src={LinkedInLogo}/>}
                                        //     logoutButtonText={<Image src={LinkedInLogo}/>}
                                        //     buttonType={'button'}
                                        //     getOAuthToken
                                        // >
                                            
                                        //     <Image src={LinkedInLogo}/>
                                        // </LinkedinSDK>
                                        <LinkedIn className="linkedin-login"
                                            // clientId="81lx5we2omq9xh"
                                            clientId={clientID}
                                            onFailure={this.handleLinkedinError}
                                            onSuccess={this.handleLinkedIn(addLinkedInUser)}
                                            redirectUri="https://localhost:3000/linkedin"
                                            // scope={getFields()}
                                            scope="r_basicprofile"
                                            state={uuid()}
                                        >
                                            <Image src={LinkedInLogo} alt="Log in with Linked In"/>
                                        </LinkedIn>
                                        
                                    )
                                }
                            }

                        </Mutation> */}

                        <BrandedButton content="EMAIL"
                            style={{padding: '15px 60px'}}
                            onClick={this.props.setFlow(AUTH_FLOW_STAGES.SIGNIN)}
                            {...this.props}
                        />


                    </div>

                </Modal.Content>

                
            </div>
        )
    }
}

export default AuthLoginButtons
// Library imports
import * as React from 'react'
import { Query } from 'react-apollo';
import { Container, Breadcrumb, Grid, Image, Divider , Header, Icon, Card, SemanticICONS} from 'semantic-ui-react';
import moment from 'moment'
// @ts-ignore
import {Fade} from "react-reveal"
import { NavLink, Switch } from 'react-router-dom';
import {ApolloClient} from "apollo-client"
import { NormalizedCache } from 'apollo-cache-inmemory';
import hexToRgba from "hex-to-rgba"
import uuid from "uuid/v4"
import {Route} from "react-router-dom"
import Helmet from "react-helmet"
import {orderBy} from "lodash"

// Local imports
import { WithRouterProps } from '../../interfaces';
import { FullPageLoader, BrandedHeader, BrandedDivider, BrandedButton, BrandedText, SidebarPosition, AuthModal, FullPageError, RTFDisplay, BGImage } from '../../components';
import "./style.scss"
import {splitIntoRows, getNumColumns, stripHtmlToText, sortByKey, formatUrl, formatPath} from "../../utils"
import SeatCarousel from '../SeatCarousel';

// GraphQL
import { tableQuery } from '../../GraphQL/queries';
import {addTableView} from "../../GraphQL/mutations"
import {Table, PageState, User, fileInput, Brand, Seat} from "../../GraphQL/types"
import { actions } from '../../GraphQL/local_writes';
import { isCompositeType } from 'graphql';


interface TableQueryProps extends WithRouterProps {
    PageState:PageState
}

interface TableProps extends TableQueryProps {
    table:Table,
    has_applied:boolean,
    client:ApolloClient<NormalizedCache>
}

class TableQuery extends React.Component<TableQueryProps> {

    public render() {
        let {

        } = this.props

        let location_params = this.props.match.params as any

        let table_id = location_params.table_id

        return (

            <Query query={tableQuery}
                variables={{
                    table_id:table_id
                }}
                fetchPolicy="network-only"
                notifyOnNetworkStatusChange={false}
            >
                {
                    ({data, loading, error, client}) => {
                        
                        if (loading) return <FullPageLoader/>
                        if (error) return <FullPageError {...this.props}/>
                        // return <FullPageLoader/>
                        let publish_tables = data.findPublishedTablesByEntity
                        let draft_tables = data.findDraftTablesByEntity
                        let table = null
                        if (publish_tables.length > 0) {
                            table = publish_tables[0]
                        } else if (draft_tables.length > 0) {
                            table = draft_tables[0]
                        } else {
                            return <FullPageError {...this.props}/>
                        }
                        
                        let has_applied = table.applicants.find((application:any) => {
                            let user = this.props.PageState.user as any
                            return application.user_id.id === user.id
                        })

                        return (
                            <TablePage table={table} has_applied={has_applied} client={client} {...this.props}/> 
                            
                        )
                    }
                }
            </Query>
        )
    }
}

class TablePage extends React.Component<TableProps> {

    state = {
        auth_modal_open:false
    }

    handleApplyClick = () => {

        // Local references
        let user = this.props.PageState.user as User
        let location_params = this.props.match.params as any
        let table_id = location_params.table_id
        let table = this.props.table
        let is_external_table = false

        // Find whether the apply click should just open the seat carousel or actually push to apply page
        table.publish_seats.map(seat => {
            if (seat.url !== "") {
                is_external_table = true
            }    
        })

        // If the seats have external urls, then just open the carousel
        if (is_external_table) {
            
            this.toggleShowCarousel(true)()

        } else {
            // If there's only one seat, set the seat id for them
            if (table.publish_seats.length === 1) {
                this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION_SEAT, table.publish_seats[0].id)
            }

            if (user.id !== "") {
                this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION_TABLE, table_id)
                let new_url = formatUrl(this.props.is_subdomain, this.props.PageState.recruiter.route_url, table_id, undefined, true)
                this.props.history.push(new_url)
            } else {
                this.toggleAuthModalOpen()
            }
        }
    }

    toggleAuthModalOpen = () => {
        this.setState({auth_modal_open:!this.state.auth_modal_open})
    }

    componentDidMount() {

        this.logTableView()
        
    }

    logTableView = async () => {

        let result = await this.props.client.mutate({
            mutation:addTableView,
            variables:{
                user_id: this.props.PageState.user ? this.props.PageState.user.id : undefined,
                table_id: this.props.table.id,
                recruiter_id: this.props.PageState.recruiter.id,
                date_time: moment().toISOString()
            }
        })

    }

    toggleShowCarousel = (seat_open:boolean,chosen_seat?:string)=> () => {

        let seat_id =  ""
        // url params
        let url_params = this.props.match.params as any
        let recruiter_name = url_params.recruiter_name
        let table_id = url_params.table_id   
        let new_url = ""

        if(seat_open){
            if(this.props.table.publish_seats.length > 0){
                 seat_id =  chosen_seat?chosen_seat:this.props.table.publish_seats[0].id;
                 new_url = formatUrl(this.props.is_subdomain, recruiter_name, table_id,seat_id)
            
            }else{
                return;
            }
        }else{
            new_url = formatUrl(this.props.is_subdomain, recruiter_name, table_id)
        }

        console.log(new_url);

        this.props.history.push(new_url)
       
    }

    scaleOffset = (offset:any) => {
        const max_imagesize:number = 720

        offset = parseInt(offset)
        if (window.innerWidth < max_imagesize) {
            // offset += max_imagesize - window.innerWidth
            offset = 0
        }


        return offset
    }

    public render() {
        let {
            table,
            match,
            PageState,
            has_applied
        } = this.props

        let params = match.params as any
        let recruiter_name = params.recruiter_name

        // Fallback content
        let divider_content = "ABOUT"

        if (table.start_date && table.start_date !== "") {
            divider_content = "TIMELINE: " + moment(table.start_date).format("MMM. D YYYY")
            if (table.end_date && table.end_date !== "") {
                divider_content += moment(table.end_date).format(" - MMM. D YYYY")
            }
        }
        
        let table_seats = sortByKey(table.publish_seats, "title", (a:any)=>a.toLowerCase())

        let seat_rows = splitIntoRows(table_seats, 3)

        let seats = table.publish_seats

        if (PageState.is_admin) {
            seats = seats.concat(table.draft_seats)
        }

        let current_href = `${window.location.origin+window.location.pathname}?search_query=${""}&&view=companies`

        //let show_breadcrumbs = this.props.PageState.recruiter.tables.length > 1
        let y_offset = table.profile_pic ? table.profile_pic.y_axis : 0

        return (
            <div className="page-content table-page">
                <HelmetComponent {...this.props}/>

                {/* Seat carousel component */}
                <Switch>
                    <Route exact path={current_href} render={(props)=>(
                        <SeatCarousel toggleClosed={this.toggleShowCarousel(false)} 
                            {...this.props} 
                            {...props} 
                            seats={seats} 
                        />
                    )}/>
                </Switch>

                {/* <AuthModal PageState={PageState}
                    toggleOpen={this.toggleAuthModalOpen} 
                    open={this.state.auth_modal_open} 
                    table_id={table.id}
                    apply
                    {...this.props}
                    /> */}
                <Container>

                    <Fade>
                        <div className="top-content">
                            <div className="page-breadcrumbs">
                                
                                    <Breadcrumb>
                                        <Breadcrumb.Section><NavLink to={formatUrl(this.props.is_subdomain, recruiter_name)}>Companies</NavLink></Breadcrumb.Section>
                                        <Breadcrumb.Divider />
                                        <Breadcrumb.Section style={{fontWeight:"bold"}}>Company Profile</Breadcrumb.Section>
                                    </Breadcrumb>
                                
                            </div>
                            


                            {
                                has_applied ? 
                                <div className="applied">
                                    <BrandedHeader as="h3" content="APPLIED" colored {...this.props} font={false}/>
                                    <Icon name="check" style={{color:PageState.recruiter.brand.color}}/>
                                </div> :
                                <BrandedButton {...this.props} font={false} content="QUICK APPLY" onClick={this.handleApplyClick}/>
                            }


                        </div>
                    </Fade>

                    <Fade>

                        <div className="main-content">

                            {/* <BGImage src={table.profile_pic.path} 
                                size={330}
                                style={{transform: `translate(0px, ${this.scaleOffset(y_offset)}px)`}}
                            /> */}
                            <div className="table-img-overlay">
                                <Image src={table.profile_pic.path} 
                                    fluid 
                                    style={{transform: `translate(0px, ${this.scaleOffset(y_offset)}px)`}}
                                    alt="Table profile image"
                                />
                            </div>

                            <div className="title-card-container">
                                <Card fluid
                                    className="title-card"
                                >
                                    <BrandedHeader as="h1" content={table.name} {...this.props} font={true}/>

                                </Card>
                            </div>
                        </div>
                    </Fade>

                    <div className="table-content">

                        {/* Description section */}
                        {
                            table.description.length > 0 &&
                            <Fade>
                                <div className="divider-content">
                                    <Header as="h5" content={divider_content} style={{marginBottom:"10px"}}/>
                                    <Divider style={{margin:"0"}}/>
                                </div>

                                <div className="table-description">
                                    <RTFDisplay data={table.description}/>
                                </div>
                            </Fade>
                        }

                        <Fade left cascade>
                            <div className="attachments">
                                {
                                    table.attachments.map((file) => {
                                        return <AttachmentItem file={file} brand={PageState.recruiter.brand} key={uuid()}/>
                                    })
                                }
                            </div>
                        </Fade>

                        {/* Seat card section */}
                        <Fade>
                            <div className="divider content">
                                <Header as="h5" content={"AVAILABLE POSITIONS"} style={{marginBottom:"10px"}}/>
                                <Divider style={{margin:"0"}}/>
                            </div>
                        </Fade>
                        
                        <div className="seat-cards">

                            <Grid stackable container>
                                {
                                    seat_rows.map((row, index) => {
                                        let num_columns = getNumColumns(row)

                                        return (
                                            <Grid.Row columns={num_columns} key={index}>
                                                {
                                                    row.map((seat:Seat, index:number) => {
                                                        return (
                                                            <Grid.Column key={index}>

                                                                <Fade>
                                                                    <SeatCard toggleShowCarousal={this.toggleShowCarousel} seat={seat} {...this.props}/>
                                                                </Fade>
                                                                
                                                            </Grid.Column>
                                                        )
                                                    })
                                                }
                                            </Grid.Row>
                                        )
                                    })
                                }
                            </Grid>

                        </div>

                        

                    </div>

                    

                </Container>

            </div>
        )
    }
}

class HelmetComponent extends React.Component<TableProps> {
    public render() {
        let {
            table
        } = this.props
        let str_description = stripHtmlToText(table.description).slice(0,300)

        return (
            <Helmet>

                <meta name="description" content={str_description}/>
                <meta property="og:title" content={table.name}/>
                <meta property="og:description" content={str_description}/>
                <meta property="og:image" content={table.profile_pic.path}/>
                <meta property="og:url" content={window.location.href}/>

            </Helmet>
        )
    }
}

interface AttachmentItemProps {
    file:fileInput,
    brand:Brand
}

class AttachmentItem extends React.Component<AttachmentItemProps> {

    state = {
        hovered:false
    }

    setHovered = (hovered:boolean) => () => {
        this.setState({hovered:hovered})
    }

    public render() {

        let {
            file,
            brand
        } = this.props
        
        let file_type = file.mimetype

        let icon = "file alternate outline" as SemanticICONS
        
        if (file_type.includes("pdf")) {
            icon = "file pdf outline"
        }

        if (file_type.includes("image")) {
            icon = "file image outline"
        }

        if (file_type.includes("ppt")) {
            icon = "file powerpoint outline"
        }

        if (file_type.includes("officedocument.word")) {
            icon = "file word outline"
        }

        if (file_type.includes("officedocument.spreadsheet")) {
            icon = "file excel outline"
        }

        let item_style = {} as React.CSSProperties

        if (this.state.hovered) {
            item_style.boxShadow = `0px 0px 1px 1px ${hexToRgba(brand.color, 0.5)}`
        }

        return (
            <a className="attachment-item"
                onMouseEnter={this.setHovered(true)}
                onMouseLeave={this.setHovered(false)}
                style={item_style}
                href={file.path}
                target="_blank"
            >
                <Icon name={icon} size="big" style={{
                    color:brand.color
                }}
                />
                <div className="attachment-filename"
                    style={{
                        color:brand.color
                    }}
                >
                    {file.filename}
                </div>
            </a>
        )
    }
}

interface SeatCardProps extends TableProps {
    seat:Seat,
    toggleShowCarousal:(seat_open:boolean,seat_chosen?:string)=>()=>void
}

class SeatCard extends React.Component<SeatCardProps> {

    state = {
        hovered:false
    }

    setHovered = (hovered:boolean) => () => {
        this.setState({hovered:hovered})
    }

    handleCardClick = () => {
        console.log("Handle Card Click")
        this.props.toggleShowCarousal(true,this.props.seat.id)();
    }

    public render() {
        let brand_color = this.props.PageState.recruiter.brand.color
        let {
            seat
        } = this.props

        let card_style = {} as React.CSSProperties

        if (this.state.hovered) {
            card_style.boxShadow = `0px 0px 1px 1px ${hexToRgba(brand_color, 0.5)}`
        }

        return (
            <Card className="seat-card"
                onClick={this.handleCardClick}
                onMouseEnter={this.setHovered(true)}
                onMouseLeave={this.setHovered(false)}
                fluid
                style={card_style}
            >
                <Header as="h5" className="bold" style={{color:brand_color}}>
                    {seat.title}
                </Header>
            </Card>
        )
    }
}



export default TableQuery
// Libary imports
import * as React from "react"
import {Image} from "semantic-ui-react"

// Local imports
import LinkedInLogo from "../../media/img/LinkedIn.png"
//Not being Used!
interface LinkedInProps extends React.ComponentProps<any> {
    onComplete:Function,
    onError:Function,

}

// placeholder until we use .env
const clientID = "78zcex0dws0moy"
const clientSecret = "25aIPNxtApyWQT2Z"

class LinkedIn extends React.Component<LinkedInProps> {

    state = {
        authenticated:false
    }

    handleLinkedInClicked = () => {
        console.log("linkedinclicked")
    }

    public render() {

        let {
            authenticated
        } = this.state

        return (
            <div className={this.props.className}
                style={this.props.style}
            >
                <a onClick={this.handleLinkedInClicked}>
                    {
                        
                        !authenticated &&
                        <Image src={LinkedInLogo} alt="Log in with Linked In"/>
                    }
                </a>

            </div>
        )
    }   
}

export default LinkedIn
import * as React from 'react'
import "./style.scss"
import ReactQuill, {Quill} from 'react-quill';
import {debounce} from "lodash"
const Font = Quill.import('formats/font');

import "katex/dist/katex.css";
import "highlight.js/styles/atom-one-light.css";
// @ts-ignore
import ImageResize from 'quill-image-resize-module-react';
import { Delta, Sources } from 'quill';
import { NormalizedCache } from 'apollo-cache-inmemory';
import {ApolloClient} from "apollo-client"
import { singleUpload } from '../../GraphQL/mutations';
import { FullPageLoader } from '..';
// @ts-ignore
// import VideoResize from 'quill-video-resize-module';
 
Font.whitelist = ['mirza', 'roboto'] ;

// Quill.register('modules/imageResize', ImageResize);
Quill.register(Font, true);


interface EditorProps {
    client:ApolloClient<NormalizedCache>,
    value:string,
    handleChange:(value:string) => void
}

class QuillEditor extends React.Component<EditorProps> {

    quillRef:any

    state = {
        // text: this.props.value
        text:this.props.value,
        loading:false
    }

    toggleLoading = () => {
        this.setState({loading:!this.state.loading})
    }

    insertEmbedWrapper = (link:string) => {
        console.log("quillRef:",this.quillRef)
        let range = this.quillRef.getEditor().getSelection();
        this.quillRef.getEditor().insertEmbed(range.index, 'image', link);
    }

    imageHandler = (image:any, callback:any) => {

        let loadingFn = this.toggleLoading
        let insertEmbed = this.insertEmbedWrapper
        
        let client = this.props.client
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async function() {
            if (input.files) {
                loadingFn()
                const file = input.files[0];
                let result = await client.mutate({
                    mutation:singleUpload,
                    variables:{file:file}
                }) as any
                let link = result.data.singleUpload.path

                insertEmbed(link)
                loadingFn()
            }
        
        }.bind(this); // react thing

    }

    modules = {
        formula:true,
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, false] }],
                // [{ 'font': [ 'times-new-roman', 'arial', 'mirza'] }],
                ['bold', 'italic', 'underline','strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean']
            ],
            handlers: {
                'image':this.imageHandler
            }
        },
    }
    
    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    handleChange = (content:string, delta:Delta, source:Sources, editor:any) => {

        this.setState({ text: content })
        this.handleStateChange(content)
    }

    handleStateChange = debounce(async (data:any) => {
        this.props.handleChange(data)
    }, 500)

    public render() {
        return (
            <div className="rtf-editor">
                {
                    this.state.loading &&
                    <FullPageLoader/>   
                }
                <ReactQuill value={this.state.text}
                  onChange={this.handleChange}
                  theme="snow"
                  modules={this.modules}
                  formats={this.formats}
                  ref={(el) => this.quillRef = el}
                />
            </div>
        )
    }
}

export default QuillEditor
// Library imports
import * as React from 'react'
import moment from 'moment'
import {Card, Divider, Form, DropdownProps, DropdownItemProps, CheckboxProps} from "semantic-ui-react"
import {SingleDatePicker} from "react-dates"
import {debounce, startCase} from "lodash"
import uuid from "uuid/v4"

// Local imports
import {ApplyProps, ApplySubFormProps, error_message_types} from "."
import {FieldLabel, BrandedLink, MonthOptions, YearOptions} from "../../components"
import { experience, logoClearBit, APPLICATION_ERRORS } from '../../GraphQL/types';
import ApolloClient from 'apollo-client';
import { NormalizedCache } from 'apollo-cache-inmemory';
import { ApolloConsumer } from 'react-apollo';

// GraphQL
import {actions} from "../../GraphQL/local_writes"
import { getLogoClearBit, getPositionList } from '../../GraphQL/queries';

interface WorkCardProps extends ApplySubFormProps {

}

class WorkCard extends React.Component<WorkCardProps> {

    public render() {

        let {
            user,
            addWork,
            removeWork
        } = this.props

        return (
            <ApolloConsumer>
                {
                    (client) => {
                        return (
                            <Card fluid className="work-input">
                                <div className="form-card">
                                    <p className="bold">WORK HISTORY</p>
                                    <Divider/>
                                    {
                                        user.experience.map((experience, index) => {
                                            return <ExperienceItem experience={experience} 
                                                        removeWork={removeWork}
                                                        index={index} 
                                                        client={client}
                                                        key={index}
                                                        {...this.props}
                                                        />
                                        })
                                    }


                                    <BrandedLink {...this.props} content="ADD ANOTHER JOB" onClick={addWork}/>
                                </div>
                            </Card>
                        )
                    }
                }
            </ApolloConsumer>
        )
    }
}

interface ExperienceItemProps extends WorkCardProps {
    index:number,
    experience:experience,
    client:ApolloClient<NormalizedCache>
}

class ExperienceItem extends React.Component<ExperienceItemProps> {

    // initialize company dropdown and add option
    initCompany = (obj:boolean):any => {
        let experience = this.props.experience

        // Set options in dropdown
        if (obj) {
            return [{
                key:experience.company.key,
                text:experience.company.text,
                value:experience.company.value
            }]

        } else {      
            // set selected option (string)       
            return experience.company.text
        }
    }

    initRole = (obj:boolean):any => {
        let experience = this.props.experience
        // Setting dropdown options
        if (obj) {
            return [{
                key:uuid(),
                text:experience.role,
                value:experience.role,
            }] as DropdownProps

        } else {
            // Set selected option
            return experience.role
        }
    }

    state = {

        experience:this.props.experience,

        error:false,
        error_msg:"",

        companyOptions:this.initCompany(true),
        companySelection:this.initCompany(false),
        companySearch:this.initCompany(false),
        companyLoading:false,

        positionOptions:this.initRole(true),
        positionSelection:this.initRole(false),
        positionSearch: this.initRole(false),
        positionLoading:false,

        start_month:moment(this.props.experience.start_date).month,
        start_year:moment(this.props.experience.start_date).year,
        end_month:moment(this.props.experience.end_date).month,
        end_year:moment(this.props.experience.end_date).year
    }


    // Handle search company query update
    handleCompanySearch = (e:React.SyntheticEvent<HTMLElement>, data:DropdownProps) => {
        let search_query = data.searchQuery
        
        this.setState({companySearch:search_query})
        if (search_query) {
            this.debounceQueryCompanies(search_query)
        }

    }

    // Fetch company suggestions, debounced at 500ms
    debounceQueryCompanies = debounce(async (query:string) => {
        this.setState({companyLoading:true})
        try {
            let result = await this.props.client.query({
                query:getLogoClearBit,
                variables:{
                    query:query
                }
            }) as any
    
            let new_options = result.data.getLogoClearBit
    
            // Only update dropdown if items returned
            if (new_options) {
                // Update new options in dropdown
                let options = this.state.companyOptions.filter((option:any) => {
                    return option.text === null
                }).concat(new_options)
    
                this.setState({
                    companyLoading:false,
                    companyOptions:options
                })
            }
        } catch (err) {
            this.setState({
                error:true,
                error_msg:"Something went wrong, try reloading...",
                companyLoading:false,
            })
        }
        

    }, 500)

    // Handle select OR add company
    handleCompanySelect = (e:React.SyntheticEvent, data:DropdownProps) => {
        

        // Error handling
        if (!data.options) {
            return
        }

        // fetch company_obj from options
        let company = data.options.find((item:any) => {
            return item.value === data.value
        }) as any

        // If its a user-inputted company, its handled in addCompany
        if (!company) {

            let user_company = {
                name:data.value,
                domain:"",
                logo:""
            }
            company = {
                key:uuid(),
                text:data.value,
                value:JSON.stringify(user_company),
            }
        }

        this.setState({
            companySelection:company.text,
            companySearch:company.text
        })
        this.setWork("company", company)

    }

    // Handle searching positions
    handlePositionSearch = (e:React.SyntheticEvent, data:DropdownProps) => {
        let search_query = data.searchQuery
        this.setState({positionSearch:search_query})
        if (search_query) {
            this.debounceQueryPositions(search_query)
        }
    }

    // Fetch position suggestions
    debounceQueryPositions = debounce(async (query:string) => {
        this.setState({positionLoading:true})
        

        try {
            let result = await this.props.client.query({
                query:getPositionList,
                variables: {
                    query:query
                }
            }) as any

            let new_positions = result.data.getPositionList.map((position:string) => {
                let titlecase = startCase(position)
                return {
                    key:uuid(),
                    value:titlecase,
                    text:titlecase
                }
            })

            this.setState({
                positionOptions:new_positions,
                positionLoading:false,
            })

        } catch (err) {
            this.setState({
                error:true,
                error_msg:"Something went wrong, try reloading...",
                positionLoading:false,
            })
        }
    }, 500)

    // handle selecting a position
    handlePositionSelect = (e:React.SyntheticEvent, data:DropdownProps) => {
        
        this.setState({
            positionSelection:data.value,
            positionSearch:data.value
        })
        this.setWork("position", data.value)
    }

    handleSetDate = (is_start:boolean, is_month:boolean) => (e:React.SyntheticEvent, data:DropdownProps) => {
        
        // Error handling
        if (!data.value) {return}

        let value = data.value as any
        let work = this.props.experience
        let start_moment = work.start_date != "" ? moment(work.start_date) : moment()
        let end_moment = work.end_date != "" ? moment(work.end_date) : moment()

        // If its the start date
        if (is_start) {
            if (is_month) {
                start_moment.set("month",value)
            } else {
                start_moment.set("year", value)
            }
            let start_moment_str = start_moment.toISOString()
            this.setWork("start_date", start_moment_str)
            this.setState({
                start_month:start_moment.month,
                start_year:start_moment.year
            })
        } else {
            // Its the end_date
            if (is_month) {
                
                end_moment.set("month",value)

            } else {
                end_moment.set("year", value)
            }
            let end_moment_str = end_moment.toISOString()
            this.setWork("end_date", end_moment_str)
            this.setState({
                end_month:end_moment.month,
                end_year:end_moment.year
            })
        }
    }

    handleSetLocation = (e:React.SyntheticEvent, data:DropdownProps) => {
        this.setWork("location", data.value)
    }

    handleSetCurrent = (e:React.SyntheticEvent, data:CheckboxProps) => {
        this.setWork("current",data.checked)
    }

    // Update a work object
    setWork = (field:string, value:any) => {

        let work = this.state.experience
        switch (field) {
            case "company":
                value.__typename = "logoClearBit"
                work.company = value           
                break
            case "position":
                work.role = value
                break
            case "start_date":
                work.start_date = value
                break
            case "end_date":
                work.end_date = value
                break
            case "current":
                work.current = value
                break
            case "location":
                work.location = value
                break
        }

        // works.splice(index,1,work)
        this.setState({experience:work})
        this.debounceSetWork(work)
        
    }

    debounceSetWork = debounce((experience:experience) => {
        let index = this.props.index
        let works = this.props.user.experience
        works.splice(index, 1, experience)
        let new_user = {
            ...this.props.user,
            experience:works
        }
        this.props.PageState.CacheWriter.write_cache(actions.SET_USER, new_user)

    }, 1000)

    public render() {
        let {
            index,
            experience,
            error_messages,
            removeWork,
            client
        } = this.props

        let start_moment = experience.start_date != "" ? moment(experience.start_date) : moment()
        let end_moment = experience.end_date != "" ? moment(experience.end_date) : moment()

        let company_error = false
        let role_error = false
        let startdate_error = false
        let enddate_error = false
        let country_error = false

        let work_errors = error_messages.filter(error => {
            return error.type === error_message_types.EXPERIENCE && error.index === index
        })

        work_errors.map(error => {
            switch (error.code) {
                case APPLICATION_ERRORS.NO_COMPANY:
                    company_error = true
                    break
                case APPLICATION_ERRORS.NO_ROLE:
                    role_error = true
                    break
                case APPLICATION_ERRORS.NO_STARTDATE:
                    startdate_error = true
                    break
                case APPLICATION_ERRORS.NO_ENDDATE:
                    enddate_error = true
                    break
                case APPLICATION_ERRORS.NO_COUNTRY:
                    country_error = true
            }
        })

        

        return (
            <div className="list-input-item">
                <Form.Group widths="equal">
                    <Form.Dropdown label={<FieldLabel content="EMPLOYER NAME" {...this.props}/>}
                        fluid
                        search
                        selection
                        allowAdditions
                        onSearchChange={this.handleCompanySearch}
                        onChange={this.handleCompanySelect}
                        options={this.state.companyOptions}
                        text={this.state.companySelection}
                        value={this.state.companySelection}
                        // onAddItem={this.handleAddCompany}
                        selectOnBlur={false}
                        searchQuery={this.state.companySearch}
                        additionPosition="bottom"
                        icon="search"
                        loading={this.state.companyLoading}
                        error={company_error}
                        
                    />

                    <Form.Dropdown label={<FieldLabel content="JOB TITLE" {...this.props}/>}
                        fluid
                        search
                        selection
                        allowAdditions
                        onSearchChange={this.handlePositionSearch}
                        onChange={this.handlePositionSelect}
                        options={this.state.positionOptions}
                        text={this.state.positionSelection}
                        value={this.state.positionSelection}
                        selectOnBlur={false}
                        searchQuery={this.state.positionSearch}
                        additionPosition="bottom"
                        icon="search"
                        loading={this.state.positionLoading}
                        error={role_error}
                        
                    />
                </Form.Group>

                {/* Dates */}
                <Form.Group widths="equal">
                    <div className="field date-picker">
                        <FieldLabel content="START MONTH" {...this.props}/>
                        <Form.Dropdown fluid
                            options={MonthOptions}
                            search
                            selection
                            placeholder="January"
                            onChange={this.handleSetDate(true, true)}
                            value={start_moment.month()}
                            text={MonthOptions[start_moment.month()].text}
                            error={startdate_error}
                        />
                        

                    </div>
                    <div className="field date-picker">
                        <FieldLabel content="START YEAR" {...this.props}/>
                        <Form.Dropdown fluid
                                options={YearOptions}
                                search
                                selection
                                placeholder="2019"
                                value={start_moment.year()}
                                text={start_moment.year().toString()}
                                onChange={this.handleSetDate(true, false)}
                                error={startdate_error}
                            />
                    </div>

                    {/*  Don't show end date if current job */}
                    {
                        !experience.current && 
                        <div className="field date-picker">
                            <FieldLabel content="END MONTH" {...this.props}/>
                            <Form.Dropdown fluid
                                options={MonthOptions}
                                search
                                selection
                                placeholder="January"
                                value={end_moment.month()}
                                text={MonthOptions[end_moment.month()].text}
                                onChange={this.handleSetDate(false, true)}
                                error={enddate_error}
                            />
                        </div>
                    }

                    {
                        !experience.current &&
                        <div className="field date-picker">
                            <FieldLabel content="END YEAR" {...this.props}/>
                            <Form.Dropdown fluid
                                options={YearOptions}
                                search
                                selection
                                placeholder="2019"
                                value={end_moment.year()}
                                text={end_moment.year().toString()}
                                onChange={this.handleSetDate(false, false)}
                                error={enddate_error}
                            />
                        </div>    
                    }
                    
                    
                    
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Dropdown label={<FieldLabel content="COUNTRY" {...this.props}/>}
                        fluid
                        selection
                        search
                        clearable
                        options={this.props.CountryOptions}
                        onChange={this.handleSetLocation}
                        text={experience.location}
                        autoComplete="off"
                        selectOnBlur={false}
                        error={country_error}
                    />
                    <div className="field" style={{display:"flex", justifyContent:"space-between", flexDirection:"column"}}>
                        <FieldLabel content="IS THIS YOUR CURRENT JOB?" {...this.props}/>
                        <Form.Group inline>
                            <Form.Checkbox label="Yes"
                                defaultChecked={experience.current}
                                onChange={this.handleSetCurrent}
                                error={enddate_error}
                            />
                        </Form.Group>
                    </div>
                </Form.Group>

                <BrandedLink {...this.props} content="REMOVE THIS JOB" onClick={removeWork(index)}/>
            </div>
        )
    }
}

export default WorkCard
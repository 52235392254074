// Library imports
import * as React from 'react'
import Lottie from "react-lottie"

// Local imports


interface GenericAnimationProps {
    containerProps: {
        [key:string]:any,
    },
    lottieProps: {
        animationData:any,
        loop:boolean,
        autoplay:boolean,
    },
    onComplete:() => void
}

class GenericAnimation extends React.Component<GenericAnimationProps> {


    public render() {
        
        let options = {
            ...this.props.lottieProps,
        }

        let eventListeners = [
            {
                eventName:"complete" as any,
                callback:this.props.onComplete
            }
        ]

        return (
            <div {...this.props.containerProps}>
                <Lottie options={options} eventListeners={eventListeners}/>
            </div>
        )
    }
}

export default GenericAnimation
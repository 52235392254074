import * as React from 'react'
import { WithRouterProps } from '../../interfaces';
import { Query, ApolloConsumer } from 'react-apollo';
import { seatQuery } from '../../GraphQL/queries';
import { PageState, Seat, Table } from '../../GraphQL/types';
import { FullPageLoader, AuthModal, BrandedHeader, BrandedDivider, BrandedButton, RTFDisplay, SidebarPosition, FullPageError } from '../../components';
import {Container, Grid, Breadcrumb, Header,Icon, Divider} from "semantic-ui-react"
import { NavLink } from 'react-router-dom';
// @ts-ignore
import {Fade} from "react-reveal"
import "./style.scss"
import { actions } from '../../GraphQL/local_writes';
import { NormalizedCache } from 'apollo-cache-inmemory';
import {ApolloClient} from "apollo-client"
import { formatUrl } from '../../utils';

interface SeatProps extends WithRouterProps {
    PageState:PageState,
}

interface SeatPageProps extends SeatProps {
    table:Table,
    seat:Seat,
    has_applied:boolean,
    client:ApolloClient<NormalizedCache>
}

class SeatQuery extends React.Component<SeatProps> {
    public render() {
        let params      = this.props.match.params as any;
        let seat_id     = params.seat_id;
        let table_id    = params.table_id;
        return (
            <Query query={seatQuery}
                variables={{
                    seat_id:seat_id,
                    table_id:table_id
                }}
                fetchPolicy="network-only"
            >
                {
                    ({loading, error, data, client}) => {
                        if (loading) return <FullPageLoader/>
                        if (error) return <FullPageError {...this.props}/> 
                        let seats = data.findPublishedSeatsByEntity
                        let published_tables = data.findPublishedTablesByEntity
                        let draft_tables = data.findDraftTablesByEntity
                        let table = null
                        if (seats.length < 1) {
                            return <FullPageError {...this.props}/>
                        }
                        if (published_tables.length < 1 && draft_tables.length < 1) {
                            return <FullPageError {...this.props}/>
                        }
                        if (published_tables.length > 0) {
                            table = published_tables[0]
                        }
                        if (draft_tables.length > 0) {
                            table = draft_tables[0]
                        }
                        let seat = data.findPublishedSeatsByEntity[0]
                        let has_applied = table.applicants.find((application:any) => {
                            let user = this.props.PageState.user as any
                            return application.user_id.id === user.id
                        })
                        return (
                            <SeatPage table={table} seat={seat} has_applied={has_applied} client={client} {...this.props}/>
                        )
                    }
                }
            </Query>
        )
    }
}

class SeatPage extends React.Component<SeatPageProps> {
    state = {
        auth_modal_open:false
    }
    handleApplyClick = async () => {
        let location_params = this.props.match.params as any
        let table_id = location_params.table_id
        let user = this.props.PageState.user as any
        let authenticated = user.id !== ""
        if (authenticated) {
            await this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION_TABLE, table_id)
            await this.props.PageState.CacheWriter.write_cache(actions.SET_APPLICATION_SEAT, this.props.seat.id)
            let new_url = formatUrl(this.props.is_subdomain, this.props.PageState.recruiter.route_url, table_id, undefined, true)
            this.props.history.push(new_url)
        } else {
            this.toggleAuthModalOpen()
        }
    }
    toggleAuthModalOpen = () => {
        this.setState({auth_modal_open:!this.state.auth_modal_open})
    }
    public render() {
        let {
            seat,
            table,
            match,
            PageState,
            has_applied
        } = this.props
        let params = match.params as any
        let recruiter_name = params.recruiter_name
        let table_id = params.table_id
        return (
            <div className="page-content seat-page">
                <Fade>
                    <Container>
                        <div className="breadcrumb">
                            <Breadcrumb>
                                <Breadcrumb.Section><NavLink to={formatUrl(this.props.is_subdomain, recruiter_name)}>Company</NavLink></Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section><NavLink to={formatUrl(this.props.is_subdomain, recruiter_name, table_id)}>Company Profile</NavLink></Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section style={{fontWeight:"bold"}}>Position</Breadcrumb.Section>
                            </Breadcrumb>
                        </div>
                        <div className="page-header">
                            <div className="page-header-text">
                                <BrandedHeader as="h1" {...this.props} content={table.name}/>
                                <div className="divider">
                                    <BrandedDivider {...this.props}/>
                                </div>
                                {
                                    !has_applied ? 
                                    <BrandedButton {...this.props} 
                                        content="APPLY"
                                        onClick={this.handleApplyClick}
                                    />
                                    :
                                    <div className="applied">
                                        <Header as="h3" content="APPLIED" className="success-text"/>
                                        <Icon name="check circle" style={{color:"#46b29e"}}/>
                                    </div>
                                }
                            </div>
                        </div>
                        <SeatContent {...this.props}/>
                    </Container>
                </Fade>
            </div>
        )
    }
}

class SeatContent extends React.Component<SeatPageProps> {
    public render() {
        let {
            PageState,
            seat,
            table
        } = this.props

        let other_seats = table.publish_seats.filter(other_seat => {
            return other_seat.id !== seat.id
        })

        return (
            <div className="seat-content">
                <Grid stackable>
                    <Grid.Row columns={2}>

                        <Grid.Column width={11}>
                        <div className="seat-about">

                            <div className="content-header">
                                <p className="bold">{seat.title}</p>
                                <Divider/>
                            </div>

                            <div className="seat-data">
                                <div className="seat-desc">
                                    <RTFDisplay data={seat.description}/>
                                </div>
                            </div>

                        </div>
                        </Grid.Column>

                        <Grid.Column width={5}>
                            <div className="table-positions">
                                <div className="content-header">
                                    <p className="bold">POSITIONS AVAILABLE</p>
                                    <Divider/>
                                </div>

                                <div className="side-seats">
                                    {
                                        other_seats.map(other_seat => {

                                            return (
                                                <SidebarPosition {...this.props} key={seat.id} seat={other_seat}/>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default SeatQuery
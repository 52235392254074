import * as React from 'react'
import {Card, Image, SemanticWIDTHS, Header} from "semantic-ui-react"
import { ViewTablesProps} from "../index"
import { Table, PageState } from '../../../GraphQL/types';
import "./style.scss"
import BrandedText from '../../../components/Branded/Text';
import { BrandedHeader, RTFDisplay, BrandedButton } from '../../../components';
import hexToRgba from "hex-to-rgba"
import Truncate from 'react-truncate'
import { NavLink } from 'react-router-dom';
// @ts-ignore
import {Fade} from "react-reveal"
import { formatUrl } from '../../../utils';

// Params
interface TableCardProps extends ViewTablesProps {
    PageState:PageState
    table:Table,
    num_columns:SemanticWIDTHS
}

// Component for a single card representing a table in the tables view
class TableCard extends React.Component<TableCardProps> {

    state = {
        hovered:false
    }

    setHovered = (hovered:boolean) => () => {
        this.setState({hovered:hovered})
    }

    scaleOffset = (offset:any) => {
        const max_imagesize:number = 720

        offset = parseInt(offset)
        if (window.innerWidth < max_imagesize) {
            offset += max_imagesize - window.innerWidth
        }
        return offset
    }

    handleTableClick = () => {

        let is_shared_table = this.props.table.recruiter_id.id !== this.props.PageState.recruiter.id
        
        let table_id = this.props.table.id
        
        if (is_shared_table) {
            let new_url = formatUrl(this.props.is_subdomain, this.props.table.recruiter_id.route_url, table_id)
            this.props.history.push(new_url)
        } else {
            let new_url = formatUrl(this.props.is_subdomain, this.props.PageState.recruiter.route_url, table_id)
            this.props.history.push(new_url)
        }
    }

    public render() {

        let {
            table,
            num_columns,
            PageState,
            match
        } = this.props
    
        let maxHeight = "";

        switch (num_columns) {
            case 1:
                maxHeight = "470px"
                break
            case 2:
                maxHeight = "370px"
                break
            default:
                maxHeight = "248px"
        }

        let tablecard_img_style = {
            maxHeight:maxHeight,
            height:maxHeight, 
        } as React.CSSProperties

        let is_shared_table = table.recruiter_id.id !== PageState.recruiter.id

        let bg_color = hexToRgba(PageState.recruiter.brand.color, 0.8)
        let recruiter_color = hexToRgba(PageState.recruiter.brand.color, 1)

        let y_offset = table.profile_pic ? table.profile_pic.y_axis : 0

        let card_style = this.state.hovered ? { boxShadow:`0px 0px 0px 2px ${recruiter_color}`} : {
            boxShadow:"1px 1px 50px 0px rgba(0, 0, 0, 0.05)"
        }

        return (
            <Fade>
                <Card className="tablecard" 
                    onClick={this.handleTableClick} 
                    fluid 
                    style={card_style}
                    onMouseEnter={this.setHovered(true)}
                    onMouseLeave={this.setHovered(false)}
                >
                    <div className="tablecard-img" style={tablecard_img_style}>

                        <div style={{
                            backgroundImage:`url(${table.profile_pic.path})`
                        }}
                            className="img"
                        />

                        <div className="tablecard-img-overlay" style={{backgroundColor:bg_color}}>
                            <Header as="h3" style={{color:"white"}}>View Positions</Header>
                        </div>

                    </div>

                    <div className="tablecard-content">

                        <div className="highlight-content">
                            <BrandedText colored 
                                content={is_shared_table ? table.recruiter_id.name : table.highlight} 
                                {...this.props} 
                                spaced 
                                font={false}
                                className="highlight-text"
                            />
                        </div>

                        <BrandedHeader as="h3" content={table.name} font {...this.props} style={{marginBottom:"0px", marginTop:'0'}}/>
                        <div className="tablecard-description">
                            <Truncate lines={2}>
                                
                                    <RTFDisplay data={table.description}/>
                                
                            </Truncate>
                        </div>
                    </div>
                    
                </Card>
            </Fade>
        )
    }
}

export default TableCard